import React from "react";
import { Fragment } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Dialog, Transition } from "@headlessui/react";
import "react-image-gallery/styles/css/image-gallery.css";
import moment from "moment";

const ReadNotificationHistoryModal = ({ isOpen, setIsOpen, data }) => {
  // Close Modal
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-50"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-50"
              >
                <Dialog.Panel className="scal w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-5 align-middle shadow-xl transition-all">
                  <div className="flex items-center justify-between">
                    <Dialog.Title as="h3" className="font-poppins text-lg font-semibold leading-7 text-secondary-color">
                      {data?.title}
                    </Dialog.Title>
                    <h2 className="text-sm font-medium text-primary-color-200"> {moment.unix(data?.created_date_unix).format(" MMM D, YYYY")}</h2>
                  </div>
                  <h2 className="text-urlTextColor text-left underline">{data?.url}</h2>

                  <div className="mt-4 mb-8 max-h-[300px] space-y-4 overflow-y-auto text-left">
                    <p>{data?.message}</p>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ReadNotificationHistoryModal;
