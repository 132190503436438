import Button from "./Button";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useLoggedContext } from "../context/provider";
import { TagsInput } from "react-tag-input-component";
import { images } from "../assets/styles/Images";
const ShareEmailModal = ({ isOpen, setIsOpen }) => {
  // Context
  const { btnLoading, setBtnLoading, openSuccessModal } = useLoggedContext();

  // Use State
  const [selected, setSelected] = useState([]);
  const [errors, setErrors] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  // Close Modal
  function closeModal() {
    setIsOpen(false);
    setErrors({});
    setBtnLoading(false);
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-75"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-75"
              >
                <Dialog.Panel className="w-full max-w-2xl overflow-hidden rounded-2xl bg-white p-12 align-middle shadow-xl transition-all">
                  <div className="flex items-center justify-between">
                    <Dialog.Title as="h3" className="font-poppins text-lg font-semibold leading-7 text-secondary-color">
                      Enter Email
                    </Dialog.Title>
                    <XMarkIcon onClick={closeModal} className="h-8 w-8 cursor-pointer text-primary-color-200" />
                  </div>

                  <form onSubmit={handleSubmit} className="mt-8 ">
                    <TagsInput value={selected} onChange={setSelected} name="email" placeHolder="Email, Press Enter To Add Tag" />

                      <div className="flex items-center justify-between mt-5">
                    <div className="flex space-x-3 items-center rounded-lg bg-gray-100  p-3">
                        <div>
                          <img src={images.PDFIcon} alt="pdf-icon" />
                        </div>
                        <div>
                          <p className="text-xs font-medium">Audit Report (30 Jan, 2023 - 20 Feb, 2023).pdf</p>
                          <p className="text-[8px] text-gray-500">32.09KB</p>
                        </div>
                      </div>
                      <div>
                     
              <span className="text-xs font-medium text-accent underline 3xl:text-base cursor-pointer">View</span>
      
                      </div>
                    </div>

                    <div className="flex items-center w-full space-x-7 mt-10">
                      <Button loading={btnLoading} title="Send" className="w-full" />
                      <h3 onClick={closeModal} className="h-12 items-center flex justify-center px-8 cursor-pointer text-lg font-medium text-secondary w-full border-2 border-secondary rounded-full">
                        Cancel
                      </h3>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ShareEmailModal;
