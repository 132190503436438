import React from "react";

const SubscriptionWidget = ({ planName, users, price, perUser, planType, checked, discount }) => {
  // Function to determine styles based on plan name and checked state
  const getPlanStyles = (name, isChecked) => {
    switch (name.toLowerCase()) {
      case "basic":
        return {
          backgroundColor: isChecked ? "border-[#F5B866] border shadow-basic-subscription bg-[#FEF9F2]" : "bg-[#FEF9F2]",
          textColor: isChecked ? "text-[#F5B866]" : "text-secondary",
        };
      case "premium":
        return {
          backgroundColor: isChecked ? "border-[#53E89E] border shadow-premium-subscription bg-[#EAFCF3]" : "bg-[#EAFCF3]",
          textColor: isChecked ? "text-[#53E89E]" : "text-secondary",
        };
      case "enterprise":
        return {
          backgroundColor: isChecked ? "border-[#F38E74] border shadow-enterprise-subscription bg-[#FFEFEB]" : "bg-[#FFEFEB]",
          textColor: isChecked ? "text-[#F38E74]" : "text-secondary",
        };
      default:
        return {
          backgroundColor: isChecked ? "border-[#264254] border shadow-ultra-subscription bg-[#EAEDEE]" : "bg-[#EAEDEE]",
          textColor: isChecked ? "text-[#264254]" : "text-secondary",
        };
    }
  };

  // Get styles based on plan name and checked state
  const { backgroundColor: bg, textColor: textCol } = getPlanStyles(planName, checked);

  return (
    <div className={`group cursor-pointer space-y-6 rounded-xl px-2 h-64 pb-5 pt-2 text-center transition-all duration-300 ${bg}`}>
      <div className="flex flex-col items-center space-y-1 py-4">
        <h2 className={`text-2xl font-bold ${textCol} lg:text-2xl xl:text-lg 2xl:text-xl`}>{planName}</h2>
        <div>
          <h3 className="text-base font-medium text-secondary-color lg:text-base xl:text-xs 2xl:text-base">{users} users</h3>
          <h4 className="text-xs text-primary-color-100 2xl:text-sm">From £{perUser} per user per setting</h4>
        </div>
      </div>
      <div>
        <h3 className="text-base font-medium text-secondary lg:text-base xl:text-xs 2xl:text-base">{planType}</h3>
        <h2 className="text-2xl font-bold text-secondary lg:text-xl 2xl:text-2xl 3xl:text-3xl">
          <span>
            £{price}
            {planName === "Basic" || planName === "Free Trial" ? "" : "*"}
          </span>
        </h2>
        {discount && (
          <h3 className="pt-1 text-sm font-medium text-primary-color-100">
            You saved <span className="font-bold text-secondary">£{discount}</span>
          </h3>
        )}
      </div>
    </div>
  );
};

export default SubscriptionWidget;
