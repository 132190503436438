import React from "react";
import moment from "moment";
import { useState } from "react";
import { Fragment } from "react";
import ApiServices from "../api/services";
import { employeeMediaUrl, mediaUrl } from "../utils/config";
import { images } from "../assets/styles/Images";
import ConfirmationModal from "./ConfirmationModal";
import AddEvaluatedModal from "./AddEvaluatedModal";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Dialog, Transition } from "@headlessui/react";
import { useLoggedContext } from "../context/provider";

const EvaluateReadMoreModal = ({ isOpenReadMore, setIsOpenReadMore, data, onSuccess }) => {
  // Context
  const { setBtnLoading, openSuccessModal } = useLoggedContext();

  // useStates
  const [removeEvaluationId, setRemoveEvaluationId] = useState("");
  const [openRemoveEvaluationModal, setOpenRemoveEvaluationModal] = useState(false);
  const [openEvaluatedModal, setOpenEvaluatedModal] = useState({ open: false, data: null, editMode: false });

  // Delete contact
  const deleteEvaluateData = () => {
    setBtnLoading(true);
    ApiServices.recentEvaluations
      .deleteEvaluateData(removeEvaluationId)
      .then((res) => {
        const { data, message } = res;

        if (data.code === 200) {
          setBtnLoading(false);
          onSuccess();
          setOpenRemoveEvaluationModal(false);
          openSuccessModal({
            title: "Success!",
            message: "Record has been Deleted",
            onPress: (close) => {
              close();
              onSuccess();
            },
          });
        }
      })
      .catch((err) => {
         
      });
  };

  // Close Modal
  function closeModal() {
    setIsOpenReadMore(false);
  }

  return (
    <>
      <Transition appear show={isOpenReadMore} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-50"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-50"
              >
                <Dialog.Panel className="w-full max-w-4xl p-6 space-y-8 overflow-hidden align-middle transition-all transform bg-white shadow-xl scal rounded-2xl">
                  <div className="flex items-center justify-between">
                    <Dialog.Title as="h3" className="text-lg font-semibold leading-7 text-secondary-color">
                      Evaluated Data
                    </Dialog.Title>
                    <XMarkIcon onClick={closeModal} className="w-8 h-8 cursor-pointer text-secondary" />
                  </div>

                  {/* Header */}
                  <div className="flex items-center pb-2 border-b gap-x-32 border-primary-color-300">
                    <div className="flex-grow ">
                      <div className="grid grid-cols-3 gap-4">
                        <div className="space-y-1 text-cente">
                          <p className="text-xs text-primary-color-100">Created at</p>
                          <h3 className="text-sm font-medium text-secondary-color">{moment.unix(data?.created_at_unix).format("D MMM YYYY")}</h3>
                        </div>

                        <div className="space-y-1 text-cente">
                          <p className="text-xs text-primary-color-100">Last updated at</p>
                          <h3 className="text-sm font-medium text-secondary-color">{moment.unix(data?.updated_at_unix).format("D MMM YYYY")}</h3>
                        </div>

                        <div className="space-y-1 text-cente">
                          <p className="text-xs text-primary-color-100">Created by</p>
                          <div className="flex items-center gap-x-1">
                            <div className="w-5 h-5 rounded-full shrink-0">
                              <img
                                className="object-cover w-full h-full rounded-full"
                                src={
                                  data?.user?.nursery?.length > 0 && data?.user?.nursery[0]?.logo !== null
                                    ? mediaUrl + data?.user?.nursery[0]?.logo
                                    : data?.user?.evaluation_employee[0]?.profile_image_url !== null
                                      ? employeeMediaUrl + data?.user?.evaluation_employee[0]?.profile_image_url
                                      : images.placeholder
                                }
                                alt="profile"
                              />
                            </div>
                            <h3 className="text-sm font-medium text-secondary-color">
                              {data?.user?.nursery?.length > 0 ? data?.user?.nursery[0]?.name : data?.user?.evaluation_employee[0]?.name}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-end ">
                      <div className="flex gap-x-3">
                        <span
                          className="text-xs font-normal underline cursor-pointer text-secondary underline-offset-4 3xl:text-sm"
                          onClick={() => {
                            setOpenEvaluatedModal({ open: true, data: data, editMode: true });
                          }}
                        >
                          Edit
                        </span>

                        <span
                          onClick={() => {
                            setOpenRemoveEvaluationModal(true);
                            setRemoveEvaluationId(data?.id);
                          }}
                          className="text-xs font-normal text-red-500 underline cursor-pointer undreline underline-offset-4 3xl:text-sm"
                        >
                          Delete
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* End Header */}

                  {/* Content */}
                  <div className="flex items-center">
                    <h3 className="w-1/2 text-lg font-semibold leading-7 text-secondary-color">Evaluation</h3>
                    <h3 className="w-1/2 text-lg font-semibold leading-7 text-secondary-color">Actions</h3>
                  </div>
                  <div className="flex">
                    <div className="h-[300px] w-1/2 overflow-y-auto">
                      <ul className="pl-5 space-y-3 text-sm list-disc text-secondary-color">
                        <li>
                          <div dangerouslySetInnerHTML={{ __html: data?.evaluation }} />
                        </li>
                      </ul>
                    </div>

                    <div className="h-[300px] w-1/2 overflow-y-auto">
                      <ul className="pl-5 space-y-3 text-sm list-disc text-secondary-color">
                        <li>
                          <div dangerouslySetInnerHTML={{ __html: data?.action }} />
                        </li>
                      </ul>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <AddEvaluatedModal
        editMode={openEvaluatedModal?.editMode}
        data={openEvaluatedModal?.data}
        isOpen={openEvaluatedModal.open}
        setIsOpenReadMore={setIsOpenReadMore}
        onSuccess={onSuccess}
        setIsOpen={(isOpen) => setOpenEvaluatedModal({ ...openEvaluatedModal, open: isOpen })}
      />

      <ConfirmationModal
        isOpen={openRemoveEvaluationModal}
        label="Remove this Evaluation"
        handleSubmit={deleteEvaluateData}
        setIsOpen={setOpenRemoveEvaluationModal}
        setIsOpenReadMore={setIsOpenReadMore}
      />
    </>
  );
};

export default EvaluateReadMoreModal;
