import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { images } from "../assets/styles/Images";
import UpdateProfileModal from "./UpdateProfileModal";
import ChangePassword from "./ChangePassword";
import { employeeMediaUrl, mediaUrl } from "../utils/config";
import { useLoggedContext } from "../context/provider";
import { KeyIcon, UserIcon, IdentificationIcon, DocumentTextIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import ConfirmationModal from "./ConfirmationModal";

export default function Example() {
  const { user, logOut } = useLoggedContext();

  const [logoutAlert, setLogoutAlert] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);

  // Handle Logout
  const handleLogout = () => {
    setLogoutAlert(false);
    logOut();
  };

  return (
    <>
      <div className="text-right ">
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button className="inline-flex w-full items-center justify-center">
              <div className="h-12 w-12 rounded-full ">
                <img
                  className="h-full w-full rounded-full border border-secondary object-contain"
                  src={
                    user?.user?.employee?.length > 0 &&
                    user?.user?.employee[0]?.profile_image_url !== null &&
                    user?.user?.employee[0]?.profile_image_url !== ""
                      ? `${employeeMediaUrl}${user?.user?.employee[0]?.profile_image_url}`
                      : images.placeholder
                  }
                />
              </div>
              <ChevronDownIcon className="h-6 w-6 text-primary-color-400" />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 mt-2 w-60 origin-top-right rounded-md bg-white p-4 shadow-lg ring-1 ring-black/5 focus:outline-none">
              <div className="flex flex-col items-center gap-y-2">
                <div className="h-12 w-12 rounded-full">
                  <img
                    className="h-full w-full rounded-full border border-secondary object-contain"
                    src={
                      user?.user?.employee?.length > 0 &&
                      user?.user?.employee[0]?.profile_image_url !== null &&
                      user?.user?.employee[0]?.profile_image_url !== ""
                        ? `${employeeMediaUrl}${user?.user?.employee[0]?.profile_image_url}`
                        : images.placeholder
                    }
                    alt="placeholder"
                  />
                </div>
                <div className="space-y-1 text-center">
                  <h4 className="text-sm text-secondary-color">{user?.user?.employee?.length > 0 ? user?.user?.employee[0]?.name : "N/A"}</h4>
                  <p className="text-xs text-primary-color-100">{user?.user?.email}</p>
                </div>
              </div>

              <div className="mt-4 border-b border-primary-color-300">
                <div>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => setShowProfileModal(true)}
                        className={`${active ? "bg-secondary text-white" : "text-primary-color-100"} group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      >
                        {active ? <UserIcon className="mr-2 h-4 w-4" aria-hidden="true" /> : <UserIcon className="mr-2 h-4 w-4" aria-hidden="true" />}
                        Update Profile
                      </button>
                    )}
                  </Menu.Item>
                </div>

                <div>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${active ? "bg-secondary text-white" : "text-primary-color-100"} group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        onClick={() => setShowPasswordModal(true)}
                      >
                        {active ? <KeyIcon className="mr-2 h-4 w-4" aria-hidden="true" /> : <KeyIcon className="mr-2 h-4 w-4" aria-hidden="true" />}
                        Change Password
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </div>

              <div className="mt-3 border-b border-primary-color-300">
                <div>
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="https://edufeel.co.uk/"
                        target="_blank"
                        className={`${active ? "bg-secondary text-white" : "text-primary-color-100"} group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      >
                        {active ? (
                          <img src={images.Logo_Icon_White} className="mr-2 h-4 w-4" aria-hidden="true" />
                        ) : (
                          <img src={images.Logo_Icon} className="mr-2 h-4 w-4" aria-hidden="true" />
                        )}
                        About Edufeel
                      </a>
                    )}
                  </Menu.Item>
                </div>

                <div>
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="https://edufeel.co.uk/privacy-policy/"
                        target="_blank"
                        className={`${active ? "bg-secondary text-white" : "text-primary-color-100"} group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      >
                        {active ? (
                          <DocumentTextIcon className="mr-2 h-4 w-4" aria-hidden="true" />
                        ) : (
                          <DocumentTextIcon className="mr-2 h-4 w-4" aria-hidden="true" />
                        )}
                        Privacy Policy
                      </a>
                    )}
                  </Menu.Item>
                </div>

                <div>
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="https://edufeel.co.uk/contact"
                        target="_blank"
                        className={`${active ? "bg-secondary text-white" : "text-primary-color-100"} group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      >
                        {active ? (
                          <IdentificationIcon className="mr-2 h-4 w-4" aria-hidden="true" />
                        ) : (
                          <IdentificationIcon className="mr-2 h-4 w-4" aria-hidden="true" />
                        )}
                        Contact Us
                      </a>
                    )}
                  </Menu.Item>
                </div>
              </div>

              <div className="px-2">
                <button className="mt-4 flex gap-x-2 text-sm font-medium text-red-500" onClick={() => setLogoutAlert(true)}>
                  <img src={images.logout} alt="logout icon" className="h-4 w-4" />
                  Sign Out
                </button>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>

      <UpdateProfileModal isOpen={showProfileModal} setIsOpen={setShowProfileModal} />

      <ChangePassword isOpen={showPasswordModal} setIsOpen={setShowPasswordModal} />

      {/* Logout Modal */}
      <ConfirmationModal label="Sign Out" isOpen={logoutAlert} setIsOpen={setLogoutAlert} handleSubmit={handleLogout} />
    </>
  );
}
