import Button from "./Button";
import { useEffect } from "react";
import InputField from "./InputField";
import ApiServices from "../api/services";
import { Fragment, useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Dialog, Transition } from "@headlessui/react";
import { useLoggedContext } from "../context/provider";

const AddContactModal = ({ isOpen, setIsOpen, submit, data, editMode, onSuccess }) => {
  // Context
  const { btnLoading, setBtnLoading, openSuccessModal } = useLoggedContext();

  // Use State
  const [formData, setFormData] = useState({ contactName: "", email: "", website: "", phoneNumber: "" });
  const [message, setMessage] = useState("");

  // Destructure Values From formData
  const { contactName, email, website, phoneNumber } = formData;

  // Input Values Handler
  const handleChange = (event) => {
    const { name, value } = event.target;
    const trimmedValue = value.trimStart();

    setFormData({ ...formData, [name]: trimmedValue });
  };

  // Regular expressions for validation
  const phoneRegex = /^\d+$/
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; 
const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

  // Submit Function
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!contactName && !email && !website && !phoneNumber) {
      setMessage("At least one field is required");
      return;
    } else if (email && !emailRegex.test(email)) {
      setMessage("Invalid email address");
      return;
    }
    else if (website && !urlRegex.test(website)) {
      setMessage("Invalid website URL");
      return;
    }
   else if (phoneNumber && !phoneRegex.test(phoneNumber)) {
      setMessage("Invalid phone number. Only digits are allowed");
      return;
    }
    setBtnLoading(true);
    const formData = new FormData();
    formData.append("name", contactName);
    formData.append("email", email);
    formData.append("website", website);
    formData.append("phone_number", phoneNumber);

    ApiServices.contactBook
      .addContact(formData)
      .then((res) => {
        const { data, message } = res;

        if (data.code === 200) {
          setBtnLoading(false);
          onSuccess();
          closeModal();
          openSuccessModal({
            title: "Success!",
            message: "New Contact has been Added",
            onPress: (close) => {
              close();
              onSuccess();
            },
          });
        }
      })
      .catch((err) => {
         
      });
  };

  // handle Update Contact
  const handleUpdateContact = async (event) => {
    event.preventDefault();
    if (!contactName && !email && !website && !phoneNumber) {
      setMessage("At least one field is required");
      return;
    }
    setBtnLoading(true);
    const formData = new FormData();
    formData.append("id", data?.id);
    formData.append("name", contactName);
    formData.append("email", email);
    formData.append("website", website);
    formData.append("phone_number", phoneNumber);

    ApiServices.contactBook
      .updateContact(formData)
      .then((res) => {
        const { data, message } = res;

        if (data.code === 200) {
          setBtnLoading(false);
          onSuccess();
          closeModal();
          openSuccessModal({
            title: "Success!",
            message: "Contact has been Updated",
            onPress: (close) => {
              close();
              onSuccess();
            },
          });
        }
      })
      .catch((err) => {
         
      });
  };

  // Close Modal
  function closeModal() {
    setIsOpen(false);
    setBtnLoading(false);
    setMessage("");
    setFormData({ contactName: "", email: "", website: "", phoneNumber: "" });
  }

  // useEffect
  useEffect(() => {
    if (editMode) {
      setFormData({
        contactName: data?.name,
        email: data?.email,
        website: data?.website,
        phoneNumber: data?.phone_number,
      });
    } else {
      setFormData({ contactName: "", email: "", website: "", phoneNumber: "" });
    }
  }, [editMode, isOpen]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-75"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-75"
              >
                <Dialog.Panel className="w-full max-w-xl p-8 overflow-hidden align-middle transition-all bg-white shadow-xl rounded-2xl">
                  <div className="flex items-center justify-between">
                    <Dialog.Title as="h3" className="text-lg font-semibold leading-7 font-poppins text-secondary-color">
                      {editMode ? "Update Contact" : "Add New Contact"}
                    </Dialog.Title>
                    <XMarkIcon onClick={closeModal} className="w-8 h-8 cursor-pointer text-primary-color-200" />
                  </div>

                  <form onSubmit={editMode ? handleUpdateContact : handleSubmit} className="mt-8 space-y-6">
                    <InputField
                      type="text"
                      name="contactName"
                      label="Contact Name"
                      value={contactName}
                      onChange={handleChange}
                      placeholder="Enter contact name"
                    />

                    <InputField
                      type="email"
                      name="email"
                      label="Email Address"
                      value={email}
                      onChange={handleChange}
                      placeholder="Enter email address"
                    />

                    <InputField type="url" name="website" label="Website" value={website} onChange={handleChange} placeholder="Enter website" />

                    <InputField
                      type="text"
                      name="phoneNumber"
                      label="Phone Number"
                      value={phoneNumber}
                      onChange={handleChange}
                      placeholder="Enter phone number"
                    />

                    {/* Message */}
                    <p className="text-sm font-medium text-center text-red-500">{message}</p>
                    {/* End Message */}

                    <div className="flex items-center justify-end gap-x-6">
                      <h3 onClick={closeModal} className="text-lg font-medium cursor-pointer text-secondary">
                        Cancel
                      </h3>
                      <Button loading={btnLoading} title={editMode ? "Update Contact" : "Add Contact"} />
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default AddContactModal;
