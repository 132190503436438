// production
// export const baseUrl = "https://api.edufeel.co.uk/";
// export const mediaUrl = "https://api.edufeel.co.uk/images/nurseries/";

// Dev
// export const baseUrl = "http://192.168.3.8:8000/";
// export const baseUrl = "https://api.edufeel.dextrouslab.com/";
// export const mediaUrl = "https://api.edufeel.dextrouslab.com/images/nurseries/";
// export const employeeMediaUrl = "https://api.edufeel.dextrouslab.com/images/employee/";
// export const ticketMediaUrl = "https://api.edufeel.dextrouslab.com/ticket";
// export const ticketResponseMediaUrl = "https://api.edufeel.dextrouslab.com/images/ticket_response";

// export const baseUrl = "https://api.edufeel.dextrouslab.com/";
// export const mediaUrl = "https://api.edufeel.dextrouslab.com/images/nurseries/";
// export const ticketMediaUrl = "https://api.edufeel.dextrouslab.com/images/ticket";
// export const ticketResponseMediaUrl = "https://api.edufeel.dextrouslab.com/images/ticket_response";
// export const userMediaUrl = "https://api.edufeel.dextrouslab.com/images/employee/";

// Prod
export const baseUrl = "https://api.edufeel.co.uk/";
export const mediaUrl = "https://api.edufeel.co.uk/images/nurseries/";
export const employeeMediaUrl = "https://api.edufeel.co.uk/images/employee/";
export const ticketMediaUrl = "https://api.edufeel.co.uk/images/ticket";
export const ticketResponseMediaUrl = "https://api.edufeel.co.uk/images/ticket_response";

export const STRIPE_KEY = "pk_test_51OjTBDCiu81PojATCx5EAEmts5KbZqBoSJ4N3h71XDm91c9BX5jENScq13Y3wyHLYUE27mwracq98FdezNHPZLEE00tYEkau5T";
// export const STRIPE_KEY = "pk_live_51OjTBDCiu81PojATPordUgHzRkUQMsQY6nscmVnLDKEiyADt2lyZMi1AYaTwDtJxUEvKDVUuDJQQ49ssFL246cWB00YAjiHGX4";
