import { axios } from "../axios";
import { POST, GET , DELETE } from "../endpoints";


  export const addCampus = async (data) => {
    try {
      let response = await axios.post(POST.ADD_CAMPUS,data);
      return response;
    } catch (err) {
      throw err;
    }
  };

  export const updateCampus = async (id, data) => {
    try {
      let response = await axios.post(`${POST.ADD_CAMPUS}/${id}`, data);
      return response;
    } catch (err) {
      throw err;
    }
  };

  export const getCampuses = async (data) => {
    try {
      let response = await axios.get(GET.GET_CAMPUSES_LIST, {
        params: data,
      });
      return response;
    } catch (err) {
      throw err;
    }
  };
  

  export const removeDepartmentCampus = async (data) => {
    try {
      let response = await axios.post(POST.REMOVE_DEPARTMENT_CAMPUS,data);
      return response;
    } catch (err) {
      throw err;
    }
  };

  export const removeCampus = async (id) => {
    try {
      let response = await axios.delete(`${DELETE.DELETE_CAMPUS}/${id}`);
      return response;
    } catch (err) {
      throw err;
    }
  };


  export const addDepartmentCampus = async (data) => {
    try {
      let response = await axios.post(POST.ADD_DEPARTMENT_CAMPUS,data);
      return response;
    } catch (err) {
      throw err;
    }
  };
