import { CalendarDaysIcon } from "@heroicons/react/20/solid";
import moment from "moment";
import React, { useState, useEffect } from "react";
import Datepicker from "react-tailwindcss-datepicker";

const RangePicker = ({ test, setRangePicker, width }) => {
  // Initializing dates
  const today = moment().startOf("day");
  const todayStartDate = moment.utc().startOf("day");
  const todayEndDate = moment.utc().endOf("day");

  // State to hold the displayed date range
  const [dateToShow, setDateToShow] = useState({
    startDate: today.toDate(),
    endDate: today.toDate(),
  });

  const handleValueChange = (newValue) => {
    const startDate = newValue.startDate ? moment.utc(newValue.startDate).startOf("day") : todayStartDate;
    const endDate = newValue.endDate ? moment.utc(newValue.endDate).endOf("day") : startDate;
    const startDateShow = newValue.startDate ? moment(newValue.startDate).startOf("day") : today;
    const endDateShow = newValue.endDate ? moment(newValue.endDate).endOf("day") : startDateShow;

    const startDateUTCUnix = startDate.unix();
    const endDateUTCUnix = endDate.unix();

    setRangePicker({ startDate: startDateUTCUnix, endDate: endDateUTCUnix });

    setDateToShow({ startDate: startDateShow.toDate(), endDate: endDateShow.toDate() });
  };

  // Initial setup
  useEffect(() => {
    setRangePicker({
      startDate: todayStartDate.unix(),
      endDate: todayEndDate.unix(),
    });
  }, []);

  return (
    <div className={`relative ${width || "w-40"}`}>
      <Datepicker
        value={dateToShow}
        onChange={handleValueChange}
        showShortcuts
        showFooter
        readOnly
        popoverDirection="down"
        toggleClassName="hidden"
        inputClassName="w-full pr-8 border-gray-400 rounded-full focus:ring-0 text-sm placeholder:text-gray-400"
        placeholder="Select Dates"
        displayFormat="ddd, MMM DD, YYYY"
      />
      <span className="absolute right-3 top-2 z-0">
        <CalendarDaysIcon className="h-5 w-5 text-primary-color-300" />
      </span>
    </div>
  );
};

export default RangePicker;
