import moment from "moment";
import Lottie from "react-lottie";
import { Tooltip } from "react-tooltip";
import Button from "../components/Button";
import ApiServices from "../api/services";
import ReactPaginate from "react-paginate";
import Heading from "../components/Heading";
import Skeleton from "react-loading-skeleton";
import { useMediaQuery } from "react-responsive";
import React, { useEffect, useState } from "react";
import { useLoggedContext } from "../context/provider";
import animationData from "../assets/lotties/empty-folder";
import { useSortableData } from "../hooks/useSortableData";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import AddContactModal from "../components/AddContactModal";
import ConfirmationModal from "../components/ConfirmationModal";
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, ChevronUpIcon, MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import RangePicker from "../components/RangePicker";
import ReadMoreModal from "../components/ReadMoreModal";
import EllipsisText from "../components/EllipsisText";
import AddEvaluatedModal from "../components/AddEvaluatedModal";
import EvaluateReadMoreModal from "../components/EvaluateReadMoreModal";
import { employeeMediaUrl, mediaUrl } from "../utils/config";
import { images } from "../assets/styles/Images";
// Table Head
const TABLE_HEAD = ["Created at", "Last updated at", "Created by", "Evaluated Data", "Actions", "Action"];

const RecentEvaluations = () => {
  // Context
  const { loading, setLoading, openSuccessModal, setBtnLoading, employeesListing } = useLoggedContext();

  // useStates
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [evaluatedData, setEvaluatedData] = useState([]);

  const [searchText, setSearchText] = useState("");
  const [removeEvaluationId, setRemoveEvaluationId] = useState("");
  const [openRemoveEvaluationModal, setOpenRemoveEvaluationModal] = useState(false);
  const [openReadMoreMessageModal, setOpenReadMoreMessageModal] = useState({ open: false, data: null });
  const [openEvaluatedModal, setOpenEvaluatedModal] = useState({ open: false, data: null, editMode: false });

  const [forcePage, setForcePage] = useState(0);

  const [isSearchAction, setIsSearchAction] = useState(false);

  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  // <------- Table Sorting ----------->

  // Active Table Sorting
  const { items, requestSort, sortConfig } = useSortableData(evaluatedData);

  // Media Queries
  const isLaptop = useMediaQuery({ minWidth: 1024 });
  const isTablet = useMediaQuery({ maxWidth: 1024 });
  const isLaptopMedium = useMediaQuery({ minWidth: 1536 });
  const isLaptopLarge = useMediaQuery({ minWidth: 1599 });
  const isLargeScreenLaptop = useMediaQuery({ minWidth: 1700 });
  const itemsPerPage = isLargeScreenLaptop ? 10 : isLaptopMedium ? 10 : isLaptop ? 7 : 10;

  const shortDescription = isLargeScreenLaptop ? 60 : isLaptopLarge ? 50 : isLaptopMedium ? 40 : isLaptop ? 30 : isTablet ? 30 : 60;

  // Get contact book list
  const getRecentEvaluations = async (emptySearch) => {
    setLoading(true);
    let payload = {
      search: emptySearch ? "" : searchText,
      page: currentPage,
      records_no: itemsPerPage,
      start_date: dateRange.startDate ? dateRange.startDate : null,
      end_date: dateRange.endDate ? dateRange.endDate : null,
    };

    const res = await ApiServices.recentEvaluations
      .getRecentEvaluations(payload)
      .then((res) => {
        const { data, message } = res;

        if (data?.code === 200) {
          setLoading(false);
          setEvaluatedData(data?.data?.data);
          setCurrentPage(data?.data?.current_page);
          setTotalPages(Math.ceil(data?.data?.total / data?.data?.per_page));
        }
      })
      .catch((err) => {
         
      });
  };

  // Delete contact
  const deleteEvaluateData = () => {
    setBtnLoading(true);
    ApiServices.recentEvaluations
      .deleteEvaluateData(removeEvaluationId)
      .then((res) => {
        const { data, message } = res;

        if (data.code === 200) {
          setBtnLoading(false);
          getRecentEvaluations();
          setOpenRemoveEvaluationModal(false);
          openSuccessModal({
            title: "Success!",
            message: "Record has been Deleted",
            onPress: (close) => {
              close();
              getRecentEvaluations();
            },
          });
        }
      })
      .catch((err) => {
         
      });
  };

  // Handle Page Change
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
    setForcePage(selected);
  };

  // Handle Search
  const handleSearch = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (searchText.trim() !== "") {
        await getRecentEvaluations();
        setCurrentPage(1);
      }
    }
  };

  // Use Effects

  useEffect(() => {
    getRecentEvaluations();
  }, [currentPage, dateRange]);

  // Lottie Option
  const emptyFolderAnimation = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <div className="card h-[82vh] 3xl:h-[84vh]">
        <div className="mb-6">
          <div className="sticky top-0 z-[2] flex w-full flex-col items-start justify-between gap-x-6 bg-white md:flex-row md:items-center">
            <div className="flex gap-x-3">
              <Heading
                heading="Evaluated Data"
                icon
                handleRefresh={() => {
                  getRecentEvaluations();
                }}
              />
            </div>
            {/* Search */}

            <div className="flex items-center justify-center gap-x-2 md:mt-0 2xl:gap-x-4">
              {/* <Button
                title="Add new Contact"
                btnSmall
                onClick={() => {
                  setOpenEvaluatedModal({ open: true, data: null, editMode: false });
                }}
              /> */}

              <RangePicker setRangePicker={setDateRange} width="2xl:w-80 xl:w-76" />

              <div className="relative flex items-center ">
                <div className="pointer-events-none absolute inset-y-0 left-0 z-10 flex items-center pl-4">
                  <MagnifyingGlassIcon className="h-5 w-5 text-primary-color-100" />
                </div>

                <form onSubmit={handleSearch}>
                  <input
                    type="search"
                    id="search"
                    name="search"
                    placeholder="Search..."
                    autocomplete="off"
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                      if (e.target.value.trim() === "") {
                        getRecentEvaluations(true);
                      }
                    }}
                    onKeyDown={handleSearch}
                    className="focus:ring-primary-color block w-full rounded-full border border-grayColor200 px-4 py-2 pl-11 text-sm text-primary-color-100 focus:border-primary-color-100"
                  />
                </form>
              </div>
              {/* select */}
            </div>
          </div>
        </div>

        <div className="-mx-6 mb-8 overflow-x-auto">
          <table className="w-full text-left">
            <thead>
              <tr>
                {TABLE_HEAD.map((head) => (
                  <th
                    key={head}
                    className="border-b border-gray-100 bg-white p-4 first:pl-6"
                    onClick={() => {
                      let sortKey;
                      if (head === "Created at") {
                        sortKey = "created_at_unix";
                      } else if (head === "Last updated at") {
                        sortKey = "updated_at_unix";
                      } else if (head === "Created by") {
                        sortKey = "createdBy.evaluation";
                      } else if (head === "Evaluated Data") {
                        sortKey = "evaluation";
                      } else if (head === "Actions") {
                        sortKey = "action";
                      } else {
                        sortKey = head.toLowerCase();
                      }
                      requestSort(sortKey);
                    }}
                  >
                    <p className="cursor-pointer whitespace-nowrap font-inter text-xs font-semibold leading-5 3xl:text-sm">
                      {head}
                      {sortConfig.key ===
                        (head === "Created at"
                          ? "created_at_unix"
                          : head === "Last updated at"
                            ? "updated_at_unix"
                            : head === "Created by"
                              ? "createdBy.evaluation"
                              : head === "Evaluated Data"
                                ? "evaluation"
                                : head === "Actions"
                                  ? "action"
                                  : head.toLowerCase()) && sortConfig.direction === "asc" ? (
                        <ChevronUpIcon className="inline-block h-4 w-3.5" />
                      ) : (
                        <ChevronDownIcon className="inline-block h-4 w-3.5" />
                      )}
                    </p>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="6">
                    <Skeleton count={4} height={50} />
                  </td>
                </tr>
              ) : items.length > 0 ? (
                items.map((item, index) => (
                  <tr key={item?.id} className="even:bg-gray-50">
                    <td className="py-3 pl-6 pr-4">
                      <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">
                        {item?.created_at ? moment.unix(item?.created_at_unix).format("MMM D YYYY") : "N/A"}
                      </p>
                    </td>

                    <td className="py-3 pl-6 pr-4">
                      <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">
                        {item?.created_at ? moment.unix(item?.updated_at_unix).format("MMM D YYYY") : "N/A"}
                      </p>
                    </td>

                    <td className="py-3 pl-6 pr-4">
                      <div className="flex gap-x-2">
                        <div className="h-5 w-5 rounded-full">
                          <img
                            src={
                              item?.user?.nursery?.length > 0 && item?.user?.nursery[0]?.logo !== null
                                ? mediaUrl + item?.user?.nursery[0]?.logo
                                : item?.user?.evaluation_employee[0]?.profile_image_url !== null
                                  ? employeeMediaUrl + item?.user?.evaluation_employee[0]?.profile_image_url
                                  : images.placeholder
                            }
                            alt="profile"
                          />
                        </div>
                        <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">
                          {item?.user?.nursery?.length > 0 ? item?.user?.nursery[0]?.name : item?.user?.evaluation_employee[0]?.name}
                        </p>
                      </div>
                    </td>

                    <td className="w-60 py-3 pl-4 pr-3 3xl:w-72 3xl:px-4">
                      <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">
                        {item?.evaluation ? (
                          <>
                            {item?.evaluation?.length > shortDescription ? (
                              <div className="cursor-pointer" onClick={() => setOpenReadMoreMessageModal({ open: true, data: item })}>
                                <EllipsisText text={item?.evaluation} maxLength={shortDescription} />
                              </div>
                            ) : (
                              <EllipsisText text={item?.evaluation} maxLength={shortDescription} />
                            )}
                          </>
                        ) : (
                          "N/A"
                        )}
                      </p>
                    </td>
                    <td className="w-60 py-3 pl-4 pr-3 3xl:w-72 3xl:px-4">
                      <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">
                        {item?.action ? (
                          <>
                            {item?.action?.length > shortDescription ? (
                              <div className="cursor-pointer" onClick={() => setOpenReadMoreMessageModal({ open: true, data: item })}>
                                <EllipsisText text={item?.action} maxLength={shortDescription} />
                              </div>
                            ) : (
                              <EllipsisText text={item?.action} maxLength={shortDescription} />
                            )}
                          </>
                        ) : (
                          "N/A"
                        )}
                      </p>
                    </td>

                    <td className="py-3 pl-4 pr-3 3xl:px-4">
                      <div className="flex gap-x-3">
                        <span
                          className="cursor-pointer text-xs font-normal text-accent underline underline-offset-4 3xl:text-sm"
                          onClick={() => setOpenReadMoreMessageModal({ open: true, data: item })}
                        >
                          View
                        </span>

                        <span
                          className="cursor-pointer text-xs font-normal text-secondary underline underline-offset-4 3xl:text-sm"
                          onClick={() => setOpenEvaluatedModal({ open: true, data: item, editMode: true })}
                        >
                          Edit
                        </span>

                        <span
                          onClick={() => {
                            setOpenRemoveEvaluationModal(true);
                            setRemoveEvaluationId(item?.id);
                          }}
                          className="undreline cursor-pointer text-xs font-normal text-red-500 underline underline-offset-4 3xl:text-sm"
                        >
                          Delete
                        </span>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                // Render "No Data" message
                <tr className="h-[500px]">
                  <td colSpan="6">
                    <Lottie options={emptyFolderAnimation} width={200} height={200} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="absolute bottom-4">
          <ReactPaginate
            breakLabel="..."
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            activeClassName="active"
            nextClassName="item next"
            renderOnZeroPageCount={null}
            breakClassName="item break-me "
            containerClassName="pagination"
            previousClassName="item previous"
            pageCount={totalPages}
            pageClassName="item pagination-page"
            forcePage={currentPage - 1}
            onPageChange={handlePageChange}
            nextLabel={<ChevronRightIcon className="h-5 w-5" />}
            previousLabel={<ChevronLeftIcon className="h-5 w-5" />}
          />
        </div>
      </div>

      <ConfirmationModal
        isOpen={openRemoveEvaluationModal}
        label="Remove this Evaluation"
        handleSubmit={deleteEvaluateData}
        setIsOpen={setOpenRemoveEvaluationModal}
      />

      <EvaluateReadMoreModal
        data={openReadMoreMessageModal.data}
        isOpenReadMore={openReadMoreMessageModal.open}
        onSuccess={getRecentEvaluations}
        setIsOpenReadMore={(isOpenReadMore) => setOpenReadMoreMessageModal({ ...openReadMoreMessageModal, open: isOpenReadMore })}
      />

      <AddEvaluatedModal
        onSuccess={getRecentEvaluations}
        editMode={openEvaluatedModal?.editMode}
        data={openEvaluatedModal?.data}
        isOpen={openEvaluatedModal.open}
        setIsOpen={(isOpen) => setOpenEvaluatedModal({ ...openEvaluatedModal, open: isOpen })}
      />
    </>
  );
};

export default RecentEvaluations;
