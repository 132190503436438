import moment from "moment";
import Lottie from "react-lottie";
import { useEffect } from "react";
import { Tooltip } from "react-tooltip";
import React, { useState } from "react";
import Badge from "../components/Badge";
import ApiServices from "../api/services";
import ReactPaginate from "react-paginate";
import { mediaUrl } from "../utils/config";
import Heading from "../components/Heading";
import Spinner from "../components/Spinner";
import Skeleton from "react-loading-skeleton";
import { useMediaQuery } from "react-responsive";
import { images } from "../assets/styles/Images";
import InputField from "../components/InputField";
import RangePicker from "../components/RangePicker";
import { useLoggedContext } from "../context/provider";
import { useSortableData } from "../hooks/useSortableData";
import ChangeCardModal from "../components/ChangeCardModal";
import animationData from "../assets/lotties/empty-folder";
import ConfirmationModal from "../components/ConfirmationModal";
import { validatePartialForm } from "../helperFunctions/helper";
import SubscriptionPlansModal from "../components/SubscriptionPlansModal";
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import Select from "react-select";
import countryList from "react-select-country-list";
import { useMemo } from "react";
import ReciptModal from "../components/ReciptModal";
import { ArrowPathIcon } from "@heroicons/react/24/outline";

// table head
const TABLE_HEAD_HISTORY = ["Date", "Package", "Price", "Status", "Recipt"];

const Profile = () => {
  // Context
  const { loading, setLoading, openSuccessModal, setBtnLoading, btnLoading, user, updateUser } = useLoggedContext();
   

  // useStates
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [billingLoading, setBillingLoading] = useState(false);
  const [selectedFileError, setSelectedFileError] = useState("");

  // Data
  const [profileData, setProfileData] = useState([]);
   

  const preData = profileData?.user?.nursery[0];

  const [nurseryTransactionData, setNurseryTransactionData] = useState([]);

  // Pagination
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  // Modal
  const [openChangeCardModal, setOpenChangeCardModal] = useState(false);
  const [openSubscriptionPlansModal, setOpenSubscriptionPlansModal] = useState({ open: false, data: null });
  const [openReciptModal, setOpenReciptModal] = useState({ open: false, data: null });
  const [openCancelSubcriptionModal, setOpenCancelSubcriptionModal] = useState({ open: false, label: "" });

  // Date
  const [employeeTransactionHistoryRange, setEmployeeTransactionHistoryRange] = useState({ startDate: null, endDate: null });

  const [formData, setFormData] = useState({
    contactPerson: "",
    phoneNumber: "",
    numberOfEmployees: "",
    address: "",
    firstAiderName: "",
    settingWellbeing: "",
  });

  const [country, setCountry] = useState(null);
  const [countryErr, setCountryErr] = useState("");
  const countriesList = useMemo(() => countryList().getData(), []);

  // Destructure Values From formData
  const { contactPerson, phoneNumber, numberOfEmployees, address, firstAiderName, settingWellbeing } = formData;

  // Handle Change
  const handleChange = (event) => {
    const { name, value } = event.target;
    const trimmedValue = value.trimStart();

    setErrors({ ...errors, [name]: "" });
    setFormData({ ...formData, [name]: trimmedValue });
  };

  // Cancel Edit Mode
  const handleCancelEditMode = () => {
    setEditMode(false);
    setErrors({});
  };

  // Table Sorting
  const { items, requestSort, sortConfig } = useSortableData(nurseryTransactionData);

  // Media Queries
  const isLaptop = useMediaQuery({ minWidth: 1024 });
  const isLaptopMedium = useMediaQuery({ minWidth: 1536 });
  const isLargeScreenLaptop = useMediaQuery({ minWidth: 1700 });
  const itemsPerPage = isLargeScreenLaptop ? 8 : isLaptopMedium ? 5 : isLaptop ? 6 : 7;

  // Get Profile Details
  const getProfileDetail = () => {
    setLoading(true);

    ApiServices.profile
      .getProfile()
      .then((res) => {
        const { data, message } = res;
        if (data.code === 200) {
          setLoading(false);
          setProfileData(data?.data);
        }
      })
      .catch((err) => {
         
      });
  };

  // Handle Submit Profile
  const handleSubmit = () => {
    const errors = validatePartialForm(formData);
    const { contactPerson, phoneNumber,  address, firstAiderName, settingWellbeing } = errors;
    setErrors(errors);
    //  
    if (!contactPerson && !phoneNumber && !address && !firstAiderName && !settingWellbeing) {
      const payload = new FormData();

      if (selectedFile instanceof File) {
        payload.append("logo", selectedFile);
      }

      payload.append("mode", "setting_user");
      payload.append("contact_person_name", formData.contactPerson);
      payload.append("phone_number", formData.phoneNumber);
      payload.append("address", formData.address);
      payload.append("mental_health_first_aider_name", formData.firstAiderName);
      payload.append("setting_wellbeing_menifesto", formData.settingWellbeing);
      payload.append("country", country ? country?.label : null);

      setBtnLoading(true);

       
       
      ApiServices.profile
        .updateProfile(payload)
        .then((res) => {
          const { data, message } = res;

          if (data.code === 200) {
            setBtnLoading(false);
            setEditMode(false);
            updateUser(data.data);
            getProfileDetail();
            openSuccessModal({
              title: "Success!",
              message: "Detail updated successfully.",
              onPress: (close) => {
                close();
                getProfileDetail();
              },
            });
          }
        })
        .catch((err) => {
           
          setBtnLoading(false);
        });
    }
  };

  // Get Transaction Listing
  const getNurseryTransactionListing = () => {
    setBillingLoading(true);
    let payload = {
      page: currentPage,
      records_no: itemsPerPage,
      start_date: employeeTransactionHistoryRange?.startDate ? employeeTransactionHistoryRange.startDate : null,
      end_date: employeeTransactionHistoryRange?.endDate ? employeeTransactionHistoryRange.endDate : null,
    };

    ApiServices.profile
      .getNurseryTransaction(payload)
      .then((res) => {
        const { data, message } = res;

        if (data.code === 200) {
          setBillingLoading(false);
          setNurseryTransactionData(data?.data?.data);
          setCurrentPage(data?.data?.current_page);
          setTotalPages(Math.ceil(data?.data?.total / data?.data?.per_page));
        }
      })
      .catch((err) => {
         
        setBillingLoading(false);
      });
  };

  // Cancel Renewal
  // const handelCancelRenewal = () => {
  //   setBtnLoading(true);
  //   ApiServices.profile
  //     .renewalStatusChange()
  //     .then((res) => {
  //       const { data, message } = res;

  //       if (data.code === 200) {
  //         setOpenCancelRenewalModal({ open: false, label: "Cancel Renewal" });
  //         setBtnLoading(false);
  //         openSuccessModal({
  //           title: "Success!",
  //           message: `${profileData?.user?.subscription[0]?.renewal_status == 0 ? "Auto-renewal" : "Cancel Auto-renewal"} successfully.`,
  //           onPress: (close) => {
  //             close();
  //             getProfileDetail();
  //           },
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //        
  //     });
  // };

  // Cancel Subscription Plan
  const handleCancelSubscription = () => {
    setBtnLoading(true);
    ApiServices.profile
      .cancelSubscriptionPlan()
      .then((res) => {
        const { data, message } = res;

        if (data.code === 200) {
          setOpenCancelSubcriptionModal({ open: false, label: "" });
          setBtnLoading(false);
          openSuccessModal({
            title: "Success!",
            message: "Your cancel subscription request has been submitted ",
            onPress: (close) => {
              close();
              getProfileDetail();
            },
          });
        }
      })
      .catch((err) => {
         
      });
  };
  // Pagination
  const handleTransactionHisttoryChange = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  // useEffects
  useEffect(() => {
    getProfileDetail();
  }, []);

  useEffect(() => {
    getNurseryTransactionListing();
  }, [currentPage, employeeTransactionHistoryRange]);

  // On Edit
  useEffect(() => {
    setFormData({
      contactPerson: preData?.contact_person_name || "N/A",
      phoneNumber: preData?.phone_number || "0000",
      address: preData?.address || "N/A",
      firstAiderName: preData?.mental_health_first_aider_name ? preData?.mental_health_first_aider_name : "N/A",
      settingWellbeing: preData?.setting_wellbeing_menifesto ? preData?.setting_wellbeing_menifesto : "N/A",
    });
    if (preData?.country) {
      const selectedCountry = countriesList?.find((option) => option?.label === preData?.country);

      if (selectedCountry) {
         
      }
      setCountry(selectedCountry);
    } else {
      setCountry(null);
    }
  }, [profileData]);

  // Lottie Option
  const emptyFolderAnimation = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <div className="grid min-h-[83vh] grid-cols-12 gap-4">
        <div className="col-span-12 space-y-4 xl:col-span-7">
          <div className="card">
            <div className="divide-y divide-grayColor200 px-4">
              {loading ? (
                <div className="flex items-center gap-x-4 pt-4 pb-8">
                  <Skeleton className="!rounded-full" count={1} height={100} width={100} />
                  <Skeleton className="!rounded-xl" count={1} height={100} width={300} />
                </div>
              ) : (
                <div className="flex items-center gap-x-4 pt-4 pb-8">
                  <div className="relative">
                    <div className="h-16 w-16 rounded-full 3xl:h-36 3xl:w-36">
                      {selectedFile ? (
                        <>
                          <img src={URL.createObjectURL(selectedFile)} className="h-full w-full rounded-full " alt="Upload Image" />
                        </>
                      ) : (
                        <>
                          <img
                            src={profileData?.user?.nursery[0]?.logo ? mediaUrl + profileData?.user?.nursery[0]?.logo : images.placeholder}
                            className="h-full w-full rounded-full object-cover "
                            alt="Upload Image"
                          />
                        </>
                      )}
                      {selectedFileError && <span className="ml-5 text-sm text-red-600">{selectedFileError}</span>}
                    </div>
                    {editMode && (
                      <>
                        <div className="absolute -bottom-2 -right-6 rounded-full">
                          <input
                            type="file"
                            accept="image/png, image/jpeg, image/jpg"
                            onChange={(e) => {
                              setSelectedFile(e.target.files[0]);
                              setSelectedFileError("");
                            }}
                            style={{ display: "none" }}
                            id="imageInput"
                          />
                          <label htmlFor="imageInput">
                            <img src={images.Camera} alt="Camera Icon" className="h-16 w-16 cursor-pointer" />
                          </label>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="space-y-1">
                    <div className="flex items-center gap-x-4">
                      <h2 className="text-2xl font-medium text-black 3xl:text-3xl">{profileData?.user?.nursery[0]?.name}</h2>
                      <Badge status={profileData?.user?.status === 1 ? "Active" : ""} />
                    </div>
                    <h3 className="text-xl text-grayColor300 3xl:text-2xl">{profileData?.user?.email}</h3>
                  </div>
                </div>
              )}
              <div className="space-y-4 pt-6 pb-2">
                {/* Heading and edit button */}
                <div className="flex items-baseline justify-between ">
                  <div className="flex items-center gap-x-4">
                    <Heading
                      heading="Profile"
                      icon
                      handleRefresh={() => {
                        getProfileDetail();
                      }}
                    />
                  </div>

                  {loading ? (
                    <Skeleton count={1} height={50} width={100} />
                  ) : editMode ? (
                    <div className="flex gap-x-4">
                      <h2 onClick={handleCancelEditMode} className="cursor-pointer text-base font-medium text-secondary underline underline-offset-2">
                        Cancel
                      </h2>
                      <button
                        onClick={handleSubmit}
                        className="edit-button text-base font-medium text-accent underline underline-offset-2"
                        type="button"
                      >
                        {btnLoading ? <Spinner /> : "Save"}
                      </button>
                    </div>
                  ) : (
                    <>
                      {(user?.user?.permissions?.includes("profile-edit") || user?.user?.role === "setting_user") && (
                        <button
                          onClick={() => setEditMode(true)}
                          className="edit-button text-base font-medium text-secondary underline underline-offset-2"
                          type="button"
                        >
                          Edit
                        </button>
                      )}
                    </>
                  )}
                </div>
                {/* End Heading and edit button */}

                <div className="grid grid-cols-2 items-center">
                  <h2 className="text-sm text-grayColor300">Contact Person</h2>

                  {loading ? (
                    <Skeleton count={1} height={50} />
                  ) : (
                    <InputField
                      type="text"
                      name="contactPerson"
                      value={contactPerson}
                      onChange={handleChange}
                      error={errors.contactPerson}
                      readOnly={!editMode && true}
                      // className={`${!editMode && "read-only-input"}`}
                    />
                  )}
                </div>
                <div className="grid grid-cols-2 items-center">
                  <h2 className="text-sm text-grayColor300">Phone</h2>

                  {loading ? (
                    <Skeleton count={1} height={50} />
                  ) : (
                    <InputField
                      type="text"
                      name="phoneNumber"
                      value={phoneNumber}
                      onChange={handleChange}
                      error={errors.phoneNumber}
                      readOnly={!editMode && true}
                      // className={`${!editMode && "read-only-input"}`}
                    />
                  )}
                </div>

                <div className="grid grid-cols-2 items-center">
                  <h2 className="text-sm text-grayColor300">Country</h2>
                  {loading ? (
                    <Skeleton count={1} height={50} />
                  ) : editMode ? (
                    <>
                      {countryErr && <span className="text-xs text-red-500">{countryErr}</span>}

                      <Select
                        options={countriesList}
                        value={country}
                        name="country"
                        // onChange={(value) => {
                        //   setCountry(value);
                        //   setCountryErr("");
                        // }}
                        onChange={setCountry}
                        styles={customStyles}
                        placeholder="Type to search your country"
                        className="mt-2 "
                      />
                    </>
                  ) : (
                    <InputField type="text" value={country?.label} readOnly={true} />
                  )}
                </div>

                <div className="grid grid-cols-2 items-center">
                  <h2 className="text-sm text-grayColor300">Address</h2>

                  {loading ? (
                    <Skeleton count={1} height={50} />
                  ) : (
                    <InputField
                      type="text"
                      name="address"
                      value={address}
                      error={errors.address}
                      onChange={handleChange}
                      readOnly={!editMode && true}
                      // className={`${!editMode && "read-only-input"}`}
                    />
                  )}
                </div>
                <div className="grid grid-cols-2 items-center">
                  <h2 className={`text-sm text-grayColor300 ${!editMode && "pt-4"}`}>Mental Health First Aider name</h2>

                  {loading ? (
                    <Skeleton count={1} height={50} />
                  ) : (
                    <InputField
                      type="text"
                      name="firstAiderName"
                      value={firstAiderName}
                      onChange={handleChange}
                      readOnly={!editMode && true}
                      error={errors.firstAiderName}
                      // className={`${!editMode && "read-only-input"}`}
                    />
                  )}
                </div>
                <div className={`grid grid-cols-2  ${editMode ? "items-center" : "items-start"}`}>
                  <h2 className={`text-sm text-grayColor300 ${!editMode && "pt-8"}`}>Setting Wellbeing Manifesto</h2>

                  {loading ? (
                    <Skeleton count={1} height={50} />
                  ) : (
                    <div className="space-y-1">
                      <span className="text-xs text-red-500">{errors.settingWellbeing}</span>
                      <textarea
                        rows={3}
                        name="settingWellbeing"
                        value={settingWellbeing}
                        className={` ${errors.settingWellbeing ? "!border-red-500 focus:ring-red-500" : "border-primary-color-300 "} w-full rounded-2xl border py-3 text-primary-color-200 placeholder:text-primary-color-100`}
                        onChange={handleChange}
                        readOnly={!editMode && true}
                      >
                        {" "}
                        {/* ${!editMode && "read-only-input"} */}
                      </textarea>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={`card space-y-8 ${user?.user?.role === "setting_user_employee" && "opacity-50"}`}>
            <div className="flex items-end justify-between">
              <Heading heading="Payment Method" subheading="Your subscription plan details" />
              <img className="h-8 shrink-0" src={images.Stripe} alt="Stripe" />
            </div>
            <div className="flex items-center justify-between rounded-xl border border-primary-color-300 p-6">
              <div>
                <h2 className="text-lg font-semibold text-primary-color-100">
                  {profileData?.user?.card[0]?.card_type}*******{profileData?.user?.card[0]?.card_last_four_digit}
                </h2>
                <h2 className="text-base font-semibold text-primary-color-100">Exp Date: {profileData?.user?.card[0]?.card_expiry_date}</h2>
              </div>

              {(user?.user?.role === "setting_user" || user?.user?.permissions?.includes("profile-update-payment-method") ) && (
                <span
                  onClick={() => setOpenChangeCardModal(true)}
                  className="cursor-pointer text-base font-semibold text-secondary underline underline-offset-4"
                >
                  Change Card
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="col-span-12 flex flex-col space-y-4 xl:col-span-5">
          <div className="card space-y-8">
            <div className="flex">
              <Heading
                heading="My subscription"
                subheading="Your subscription plan details"
                icon
                handleRefresh={() => {
                  getProfileDetail();
                }}
              />
            </div>
            {loading ? (
              <Skeleton className="mt-8" count={1} height={150} />
            ) : (
              <div className="space-y-4 rounded-xl border border-gray-200 p-6">
                <div>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-x-2">
                      <h2 className="text-xl font-semibold text-secondary 3xl:text-22">{`${profileData?.user?.subscription[0]?.plan_name} Plan`} </h2>
                      <Badge status="Current Plan" />
                    </div>
                  </div>
                  {profileData?.user?.subscription[0]?.cancel_status === 1 ? (
                    <h2 className="mt-2 flex gap-x-1 text-xs text-primary-color-400 xl:text-xs 2xl:text-sm">
                      Your subscription is valid until{" "}
                      <span className="font-medium">
                        <p id={`date`} className="text-xs font-normal text-primary-color-200 3xl:text-sm">
                          {moment.unix(parseFloat(profileData?.user?.subscription[0]?.end_date)).format("D MMM YYYY")}
                        </p>
                        <Tooltip
                          anchorSelect={`#date`}
                          id={`date-tooltip`}
                          offset={5}
                          className="border border-gray-100 !bg-white !text-primary-color-100 !opacity-100 shadow-card"
                          classNameArrow="hidden"
                          effect="solid"
                          place="bottom"
                        >
                          {/* Tooltip Content */}
                          <span className="font-medium">
                            {moment.unix(parseFloat(profileData?.user?.subscription[0]?.end_date)).format("D MMM YYYY h:mm A")}
                          </span>
                        </Tooltip>
                      </span>
                    </h2>
                  ) : (
                    <>
                      {/* {profileData?.user?.subscription[0]?.end_date !== null && ( */}
                      <h2 className="mt-2 flex gap-x-1 text-xs text-primary-color-400 xl:text-xs 2xl:text-sm">
                        Next Payment: <span className="font-medium">£{profileData?.user?.subscription[0]?.price}</span> on
                        <span className="font-medium">
                          <p id={`date`} className="text-xs font-normal text-primary-color-200 3xl:text-sm">
                            {moment.unix(parseFloat(profileData?.user?.subscription[0]?.end_date)).format("D MMM YYYY")}
                          </p>
                          <Tooltip
                            anchorSelect={`#date`}
                            id={`date-tooltip`}
                            offset={5}
                            className="border border-gray-100 !bg-white !text-primary-color-100 !opacity-100 shadow-card"
                            classNameArrow="hidden"
                            effect="solid"
                            place="bottom"
                          >
                            {/* Tooltip Content */}
                            <span className="font-medium">
                              {moment.unix(parseFloat(profileData?.user?.subscription[0]?.end_date)).format("D MMM YYYY h:mm A")}
                            </span>
                          </Tooltip>
                        </span>
                        (VAT may apply)
                      </h2>
                      {/* )} */}
                      {profileData?.user?.free_trial === 1 && (
                        <h2 className="pt-1 text-sm text-primary-color-400">
                          Free trial ending at: {moment.unix(parseFloat(profileData?.user?.subscription[0]?.end_date)).format("D MMM YYYY")}
                        </h2>
                      )}
                    </>
                  )}
                </div>
                <div className="flex items-center justify-end gap-x-4">
                  {profileData?.user?.subscription[0]?.cancel_status === 0 ? (
                    <>
                      <button
                        className="text-sm font-medium text-secondary underline underline-offset-2 3xl:text-base"
                        type="button"
                        onClick={() =>
                          setOpenCancelSubcriptionModal({
                            open: true,
                            label: "Cancel",
                          })
                        }
                      >
                        Cancel subscription
                      </button>
                      {(user?.user?.permissions?.includes("profile-change-plan") || user?.user?.role === "setting_user") && (
                        <button
                          onClick={() => setOpenSubscriptionPlansModal({ open: true, data: profileData?.user?.subscription[0]?.plan_id })}
                          className="rounded-full bg-secondary py-2.5 px-8 text-sm font-medium text-white  3xl:text-base"
                          type="button"
                        >
                          Change Plan
                        </button>
                      )}
                    </>
                  ) : (
                    <button
                      onClick={() => setOpenSubscriptionPlansModal({ open: true, data: "" })}
                      className="rounded-full bg-secondary py-2.5 px-8 text-sm font-medium text-white  3xl:text-base"
                      type="button"
                    >
                      Buy Plan
                    </button>
                  )}

                  {/* <h2 className="text-sm text-primary-color-400">
                    Automatic renewal every {profileData?.user?.subscription[0]?.plan_type === "Monthly" ? "month" : "year"}
                  </h2>
                  {(user?.user?.permissions?.includes("profile-cancel-auto-renewal") || user?.user?.role === "setting_user") && (
                    <button
                      onClick={() =>
                        setOpenCancelRenewalModal({
                          open: true,
                          label: profileData?.user?.subscription[0]?.renewal_status == 0 ? "Auto Renew" : "Cancel Auto-renewal",
                        })
                      }
                      className="text-sm font-medium text-secondary underline underline-offset-2 3xl:text-base"
                      type="button"
                    >
                      {profileData?.user?.subscription[0]?.renewal_status == 0 ? "Auto Renew" : "Cancel Auto-renewal"}
                    </button>
                  )} */}
                </div>
              </div>
            )}
          </div>
          {(user?.user?.permissions?.includes("profile-view-billing-history") || user?.user?.role === "setting_user") && (
            <div className="card flex-grow">
              <div className="flex justify-between">
                <div className="relative">
                  <Heading
                    heading="Billing History"
                    subheading="View plan history of packages"
                    icon
                    handleRefresh={() => {
                      getNurseryTransactionListing();
                    }}
                  />
                </div>
                <RangePicker setRangePicker={setEmployeeTransactionHistoryRange} width="xl:w-80" />
              </div>
              <div className="mt-4">
                <div className="-mx-6 mb-12 overflow-x-auto">
                  <table className="w-full text-left">
                    <thead>
                      <tr>
                        {TABLE_HEAD_HISTORY?.map((head) => (
                          <th
                            key={head}
                            className="border-b border-gray-100 bg-white p-4 first:pl-6"
                            onClick={() => {
                              let sortKey;
                              if (head === "Date") {
                                sortKey = "created_at";
                              } else if (head === "Package") {
                                sortKey = "plan_name";
                              } else if (head === "Price") {
                                sortKey = "price";
                              } else {
                                sortKey = head.toLowerCase();
                              }
                              requestSort(sortKey);
                            }}
                          >
                            <p className="cursor-pointer whitespace-nowrap font-inter text-xs font-semibold leading-5 3xl:text-sm">
                              {head}
                              {sortConfig.key ===
                                (head === "Date"
                                  ? "created_at"
                                  : head === "Package"
                                    ? "plan_name"
                                    : head === "Price"
                                      ? "price"
                                      : head.toLowerCase()) && sortConfig.direction === "asc" ? (
                                <ChevronUpIcon className="inline-block h-4 w-3.5" />
                              ) : (
                                <ChevronDownIcon className="inline-block h-4 w-3.5" />
                              )}
                            </p>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {billingLoading ? (
                        <tr>
                          <td colSpan="6">
                            <Skeleton count={itemsPerPage} height={50} />
                          </td>
                        </tr>
                      ) : items.length > 0 ? (
                        items.map((item, index) => (
                          <tr key={item?.id} className="transition-all duration-300 even:bg-gray-50 hover:bg-secondary/10">
                            <td className="py-4 pl-4 3xl:pl-6 3xl:pr-4">
                              <p id={`date${index + 1}`} className="text-xs font-normal text-primary-color-200 3xl:text-sm">
                                {moment.unix(item?.created_at_unix).format("D MMM YYYY")}
                              </p>
                              <Tooltip
                                anchorSelect={`#date${index + 1}`}
                                id={`date-tooltip${index + 1}`}
                                offset={5}
                                className="border border-gray-100 !bg-white !text-primary-color-100 !opacity-100 shadow-card"
                                classNameArrow="hidden"
                                effect="solid"
                                place="bottom"
                              >
                                {/* Tooltip Content */}
                                <span>{moment.unix(item?.created_at_unix).format("D MMM YYYY h:mm A")}</span>
                              </Tooltip>
                            </td>
                            <td className="py-4 pl-4 3xl:px-4">
                              <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item?.plan_name + "/" + item?.plan_type}</p>
                            </td>

                            <td className="py-4 pl-4 3xl:px-4">
                              <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">£{item?.price}</p>
                            </td>
                            <td className="py-3 pl-4 pr-3 3xl:px-4">
                              <Badge status={item?.status === 1 ? "Failed" : "Success"} />
                            </td>
                            <td className="py-3 pl-4 pr-3 3xl:px-4">
                              <span
                                onClick={() => setOpenReciptModal({ open: true, data: item })}
                                className="cursor-pointer text-xs font-normal text-secondary underline underline-offset-2 3xl:text-sm"
                              >
                                Receipt
                              </span>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr className=" h-[300px]">
                          <td colSpan="6">
                            <Lottie options={emptyFolderAnimation} width={200} height={200} />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="absolute bottom-4">
                  <ReactPaginate
                    breakLabel="..."
                    pageRangeDisplayed={5}
                    pageCount={totalPages}
                    marginPagesDisplayed={2}
                    activeClassName="active"
                    nextClassName="item next"
                    forcePage={currentPage - 1}
                    renderOnZeroPageCount={null}
                    breakClassName="item break-me "
                    containerClassName="pagination"
                    previousClassName="item previous"
                    pageClassName="item pagination-page"
                    onPageChange={handleTransactionHisttoryChange}
                    nextLabel={<ChevronRightIcon className="h-5 w-5" />}
                    previousLabel={<ChevronLeftIcon className="h-5 w-5" />}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Cancel Renewal Modal */}
      <ConfirmationModal
        isOpen={openCancelSubcriptionModal.open}
        label={openCancelSubcriptionModal.label}
        labelTwo="your subscription"
        handleSubmit={handleCancelSubscription}
        setIsOpen={(open) => setOpenCancelSubcriptionModal((prev) => ({ ...prev, open }))}
      />

      {/* Subscription Plans Modal */}
      <SubscriptionPlansModal
        isOpen={openSubscriptionPlansModal.open}
        data={openSubscriptionPlansModal.data}
        setIsOpen={(open) => setOpenSubscriptionPlansModal((prev) => ({ ...prev, open }))}
        onSuccess={getProfileDetail}
      />

      <ReciptModal
        data={openReciptModal.data}
        isOpen={openReciptModal.open}
        setIsOpen={(open) => setOpenReciptModal((prev) => ({ ...prev, open }))}
      />

      {/* Change Card Modal (VISA, MASTER) */}
      <ChangeCardModal isOpen={openChangeCardModal} setIsOpen={setOpenChangeCardModal} getProfile={getProfileDetail} />
    </>
  );
};

export default Profile;

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    height: "50px",
    minHeight: "50px",
    borderRadius: "4.375rem",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "50px",
    padding: "0 24px",
  }),
  input: (provided, state) => ({
    ...provided,
    margin: "0px",
    color: "rgb(91 91 91)",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "rgb(91 91 91)",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "50px",
  }),

  option: (provided, state) => ({
    ...provided,
    padding: "10px 12px",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#9ca3af",
    opacity: 1,
    fontSize: "14px",
  }),
};
