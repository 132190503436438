import { Tab } from "@headlessui/react";
import { Fragment, useState } from "react";
import InviteViaEmail from "./InviteViaEmail";
import ImportEmployees from "./ImportEmployees";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Dialog, Transition } from "@headlessui/react";
import { useLoggedContext } from "../../context/provider";

const InviteUsersModal = ({ isOpen, setIsOpen, onSuccess }) => {
// Context
  const {setBtnLoading } = useLoggedContext();
 
  // Close Modal
  function closeModal() {
    setIsOpen(false);
    setBtnLoading(false)
  }

  const tabs = ["Invite via Email", "Import Employees"];

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-75"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-75"
              >
                <Dialog.Panel
                  className={`min-w-[50%] max-w-full overflow-hidden rounded-2xl bg-white p-8 align-middle shadow-xl transition-all 3xl:max-w-[90%]`}
                >
                  <div className="flex items-center justify-between">
                    <Dialog.Title as="h3" className="text-lg font-semibold leading-7 font-poppins text-secondary-color">
                      Add New Employee
                    </Dialog.Title>
                    <XMarkIcon onClick={closeModal} className="w-8 h-8 cursor-pointer text-primary-color-200" />
                  </div>

                  <Tab.Group>
                    <Tab.List className="flex max-w-sm mt-8">
                      {tabs.map((tabLabel, index) => (
                        <Tab
                          key={index}
                          className={({ selected }) => `w-full border-b px-2 py-2.5 text-left text-sm font-medium leading-5 focus:outline-none
                           ${selected ? "border-secondary font-semibold text-secondary" : "text-primary-color-300 hover:text-secondary"}`}
                        >
                          {tabLabel}
                        </Tab>
                      ))}
                    </Tab.List>
                    <Tab.Panels className="mt-8">
                      <Tab.Panel>
                        <InviteViaEmail closeModal={closeModal} onSuccess={onSuccess} />
                      </Tab.Panel>

                      <Tab.Panel>
                        <ImportEmployees closeModal={closeModal} onSuccess={onSuccess}/>
                      </Tab.Panel>
                    </Tab.Panels>
                  </Tab.Group>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default InviteUsersModal;
