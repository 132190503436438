import React from "react";
import { images } from "../assets/styles/Images";

const TitleValue = ({ title, value , lastUpdated }) => {
  return (
    <div>
      <h3 className="text-[10px] 2xl:text-xs text-primary-color-100">{title}</h3>
      <h2 className="text-xs 2xl:text-sm font-medium text-secondary-color">{value} {lastUpdated && <img src={images.info} alt="info" className="inline-block" />}</h2>
    </div>
  );
};

export default TitleValue;
