import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { LocalProvider } from "./context/provider";
import { CookiesProvider } from "react-cookie";
import "react-datepicker/dist/react-datepicker.css";
import "react-loading-skeleton/dist/skeleton.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <LocalProvider>
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </LocalProvider>
  </BrowserRouter>,
);

reportWebVitals();
