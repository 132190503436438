import { axios } from "../axios";
import { GET , POST } from "../endpoints";


export const getReport = async (data) => {
    try {
      let response = await axios.get(GET.GET_REPORT, {
        params: data,
      });
      return response;
    } catch (err) {
      throw err;
    }
  };

  export const uploadReport = async (formData) => {
    try {
      let response = await axios.post(POST.UPLOAD_FILE_REPORT, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  };

  export const getHistoryList = async (data) => {
    try {
      let response = await axios.get(GET.HISTORY_LIST , {
        params: data,
      });
      return response;
    } catch (err) {
      throw err;
    }
  };
