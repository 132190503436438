import React from "react";

// ===================== React Router DOM ====================== //
import { Navigate } from "react-router-dom";

// ===================== Pages ====================== //
import Login from "../pages/Login";
import ForgotPassword from "../pages/ForgotPassword";

// ===================== Routes ====================== //
import {
  login,
  logout,
  users,
  settings,
  payments,
  dashboard,
  resetPassword,
  forgotPassword,
  supportTickets,
  subscriptionsPlans,
  employees,
  admins,
  notifications,
  reports,
  profile,
  employeeDetail,
  contactBook,
  causeForConcern,
  recentEvaluations,
  campuses,
  department
} from "./routesDefined";

import Users from "../pages/Users";
import Settings from "../pages/Settings";
import Payments from "../pages/Payments";
import Dashboard from "../pages/Dashboard";
import ResetPassword from "../pages/ResetPassword";
import SupportTicket from "../pages/SupportTicket";
import SubscriptionPlans from "../pages/SubscriptionPlans";
import SuperAdminDashboard from "../pages/SuperAdminDashboard";
import Employees from "../pages/Employees";
import Admins from "../pages/Admins";
import Reports from "../pages/Reports";
import Profile from "../pages/Profile";
import Notifications from "../pages/Notifications";
import EmployeeDetail from "../pages/EmployeeDetail";
import ContactBook from "../pages/ContactBook";
import CauseForConcern from "../pages/CauseForConcern";
import RecentEvaluations from "../pages/RecentEvaluations";
import Campuses from "../pages/Campuses"
import Department from "../pages/Department"


export const ProtectedRoutes = [
  {
    id: "1",
    path: dashboard,
    component: <Dashboard />,
  },
  {
    id: "2",
    path: employees,
    component: <Employees />,
  },
  {
    id: "3",
    path: contactBook,
    component: <ContactBook />,
  },
  {
    id: "4",
    path: admins,
    component: <Admins />,
  },
  {
    id: "4",
    path: notifications,
    component: <Notifications />,
  },
  {
    id: "5",
    path: supportTickets,
    component: <SupportTicket />,
  },

  {
    id: "6",
    path: reports,
    component: <Reports />,
  },
  {
    id: "7",
    path: profile,
    component: <Profile />,
  },
  {
    id: "9",
    path: causeForConcern,
    component: <CauseForConcern />,
  },
  {
    id: "8",
    path: employeeDetail,
    component: <EmployeeDetail />,
  },
  {
    id: "9",
    path: recentEvaluations,
    component: <RecentEvaluations />,
  },
  {
    id: "10",
    path: campuses,
    component: <Campuses />,
  },
  {
    id: "11",
    path: department,
    component: <Department />,
  },
];

export const PublicRoutes = [
  {
    id: "1",
    path: login,
    component: <Login />,
  },
  {
    id: "2",
    path: forgotPassword,
    component: <ForgotPassword />,
  },
  {
    id: "3",
    path: resetPassword,
    component: <ResetPassword />,
  },
  {
    id: "4",
    path: logout,
    component: <Navigate to={{ pathname: login }} />,
  },
  {
    id: "5",
    path: "/",
    component: <Navigate to={{ pathname: login }} />,
  },
];
