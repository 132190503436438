import React, { useState } from "react";
import Spinner from "./Spinner";
import { Fragment } from "react";
import { images } from "../assets/styles/Images";
import { Dialog, Transition } from "@headlessui/react";
import { useLoggedContext } from "../context/provider";
import ReactDatePicker from "react-datepicker";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { employeeMediaUrl } from "../utils/config";

export default function CheckInFormModal({ label, isOpen, setIsOpen, message, setSelectedDate, setMessage, handleSubmit, data, selectedDate, handleDateChange }) {
  // Context
  const { btnLoading } = useLoggedContext();

  const backgroundColor = "secondary";

  const closeModal = () => {
    setMessage("");
    setIsOpen(false);
    setSelectedDate("")
  };

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false); // State to control the date picker visibility

  const getButtonStyles = (buttonType) => {
    const baseStyles =
      "inline-flex w-full justify-center rounded-full px-4 py-2.5 text-base font-medium transition-all duration-300 focus:outline-none";
    switch (buttonType) {
      case "Yes":
        return `bg-${backgroundColor} text-white ${baseStyles} ${btnLoading ? "" : "hover:opacity-75"}`;
      case "No":
        return `border border-${backgroundColor} text-${backgroundColor} hover:bg-${backgroundColor} ${baseStyles} hover:text-white`;
      default:
        return "";
    }
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-75"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-75"
              >
                <Dialog.Panel className="w-full max-w-md p-8 overflow-hidden text-center align-middle transition-all bg-white shadow-xl rounded-2xl">
                  <div onClick={closeModal} className="float-right">
                    <XMarkIcon className="cursor-pointer h-7 w-7 text-secondary-color" />
                  </div>
                  <div className="mx-auto h-36 w-36">
                    <img
                      src={data?.employee[0]?.profile_image_url ? employeeMediaUrl + data?.employee[0]?.profile_image_url : images.placeholder}
                      alt="question mark icon"
                      className="object-contain w-full h-full rounded-full "
                    />
                  </div>
                  <Dialog.Title
                    as="h3"
                    className={`mx-auto mt-6 max-w-[20rem] font-inter text-lg font-semibold leading-7 text-secondary 3xl:text-xl `}
                  >
                    Are you sure you want to send <span className="text-red-500">check-in form</span> to
                    <span className="pl-2 underline">{data?.employee[0]?.name}</span> ?
                  </Dialog.Title>
                  <div className="px-8 pt-8 space-y-1 ">
                    <h2 className="pl-2 text-sm text-left text-grayColor300">Date</h2>
                    <div onClick={() => setIsDatePickerOpen(true)}>
                      <ReactDatePicker
                        selected={selectedDate}
                        onChange={handleDateChange}
                          open={isDatePickerOpen}
                          onClickOutside={() => setIsDatePickerOpen(false)}
                        showTimeSelect
                        dateFormat="Pp"
                        placeholderText="Select Date"
                        minDate={new Date()}
                      />
                    </div>
                    <div className="mt-4 text-left">
                      <p className="text-sm text-red-500">{message}</p>
                    </div>
                  </div>

                  <div className="flex px-8 mt-12 gap-x-6">
                    <button type="button" className={getButtonStyles("Yes")} onClick={handleSubmit || closeModal}>
                      {btnLoading ? <Spinner /> : "Yes"}
                    </button>

                    <button type="button" className={getButtonStyles("No")} onClick={closeModal}>
                      No
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
