import moment from "moment";

// Function to convert a date/time format to UTC and then into a Unix milliseconds timestamp
export function toUTCUnixTimestamp(date) {
  return moment(date).utc().valueOf() / 1000;
}

// Function to convert a Unix milliseconds timestamp to a date/time format in local time
export function getLocalDateFromUnixTimestamp(unixTimestamp) {
  return moment(unixTimestamp);
}

// Check if the string is a Unix timestamp
export function isUnixTimestamp(str){
  return /^\d{10}$/.test(str);
};

// Regex
const isValidFullName = (fullName) => {
  const validRegex = /^[a-zA-Z\s]+$/;
  return validRegex.test(fullName);
};

// Function to validate form data
export const validateForm = (formData) => {
  let errors = {};

  // validate email
  if (formData.hasOwnProperty("email") && !formData.email) {
    errors.email = "Email is required";
  } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
    errors.email = "Please enter a valid email";
  }

  // validate password
  if (formData.hasOwnProperty("password") && !formData.password) {
    errors.password = "Password is required";
  } else if (formData.password && formData.password.length < 6) {
    errors.password = "Please enter at least 6 characters";
  }

  // validate title
  if (formData.hasOwnProperty("title") && !formData.title) {
    errors.title = "Required";
  }

  // validate notification message
  if (formData.hasOwnProperty("description") && !formData.description) {
    errors.description = "Required";
  }

  // validate fullName if present
  if (formData.hasOwnProperty("fullName") && !formData.fullName) {
    errors.fullName = "Full name is required";
  } else if (formData.hasOwnProperty("fullName") && !isValidFullName(formData.fullName)) {
    errors.fullName = "Please enter a valid full name";
  }


  // Validate Phone Number
  if (formData.hasOwnProperty("phoneNumber") && !formData.phoneNumber) {
    errors.phoneNumber = "Phone number is required";
  } else if (formData.hasOwnProperty("phoneNumber") && !/^[0-9+()-\s]+$/.test(formData.phoneNumber)) {
    errors.phoneNumber = "Please enter a valid phone number";
  }
  //  else if (formData.hasOwnProperty("phoneNumber") && formData.phoneNumber.length !== 11) {
  //   errors.phoneNumber = "Phone number must be 11 digits";
  // }

  // Validate Date of Birth
  if (formData.hasOwnProperty("dateOfBirth") && !formData.dateOfBirth) {
    errors.dateOfBirth = "Date of birth is required";
  }

  // Validate Employee ID
  if (formData.hasOwnProperty("employeeId") && !formData.employeeId) {
    errors.employeeId = "Employee ID is required";
  }

  // Validate Qualification
  if (formData.hasOwnProperty("qualification") && !formData.qualification) {
    errors.qualification = "Qualification is required";
  }

  // Validate Joining Date
  if (formData.hasOwnProperty("joiningDate") && !formData.joiningDate) {
    errors.joiningDate = "Joining date is required";
  }

  // Check if both dates are provided before comparing
  if (formData.dateOfBirth && formData.joiningDate) {
    const dateOfBirth = new Date(formData.dateOfBirth);
    const joiningDate = new Date(formData.joiningDate);

    if (dateOfBirth >= joiningDate) {
      errors.dateOfBirth = "Date of birth must be before joining date";
      errors.joiningDate = "Joining date must be after date of birth";
    }
  }

  // Validate Work Days Per Week
  if (formData.hasOwnProperty("workDaysPerWeek") && !formData.workDaysPerWeek) {
    errors.workDaysPerWeek = "Work days per week is required";
  }

  // Validate Job Title
  if (formData.hasOwnProperty("jobTitle") && !formData.jobTitle) {
    errors.jobTitle = "Job title is required";
  }

  // Validate Room
  if (formData.hasOwnProperty("room") && !formData.room) {
    errors.room = "Room is required";
  }

  // Validate Key Children
  if (formData.hasOwnProperty("keyChildren") && !formData.keyChildren) {
    errors.keyChildren = "Key children is required";
  }

  // Validate Mental Health Condition
  if (formData.hasOwnProperty("mentalHealthCondition") && !formData.mentalHealthCondition) {
    errors.mentalHealthCondition = "Mental health condition is required";
  }

  // Validate Medication

  if (formData.hasOwnProperty("medication") && !formData.medication) {
    errors.medication = "Medication is required";
  } else if (formData.hasOwnProperty("medication") && !isValidFullName(formData.medication)) {
    errors.medication = "Contains only letters and spacing";
  }

  // Validate Emotional Triggers
  if (formData.hasOwnProperty("emotionalTriggers") && !formData.emotionalTriggers) {
    errors.emotionalTriggers = "Emotional triggers is required";
  }

  return errors;
};

// Partail validation function
export const validatePartialForm = (formData) => {
  let errors = {};

  if (formData.hasOwnProperty("email") && !formData.email) {
    errors.email = "Email is required";
  } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
    errors.email = "Please enter a valid email";
  }

  if (formData.hasOwnProperty("employeeId") && !formData.employeeId) {
    errors.employeeId = "Id is required";
  }

  // Validate Full Name if present
  if (formData.fullName && !isValidFullName(formData.fullName)) {
    errors.fullName = "Please enter a valid full name";
  }

  if (formData.hasOwnProperty("contactPerson") && !formData.contactPerson) {
    errors.contactPerson = "Required";
  }
  if (formData.hasOwnProperty("phoneNumber") && !formData.phoneNumber) {
    errors.phoneNumber = "Required";
  } else if (formData.hasOwnProperty("phoneNumber") && !/^[0-9+()-\s]+$/.test(formData.phoneNumber)) {
    errors.phoneNumber = "Please enter a valid phone number";
  }
  //  else if (formData.hasOwnProperty("phoneNumber") && formData.phoneNumber.length !== 11) {
  //   errors.phoneNumber = "Phone number must be 11 digits";
  // }

  // if (formData.hasOwnProperty("numberOfEmployees") && !formData.numberOfEmployees) {
  //   errors.numberOfEmployees = "Required";
  // } else if (formData.hasOwnProperty("numberOfEmployees") && !/^[0-9]+$/.test(formData.numberOfEmployees)) {
  //   errors.numberOfEmployees = "Please enter numbers only";
  // }

  if (formData.hasOwnProperty("country") && !formData.country) {
    errors.country = "Required";
  } 

  if (formData.hasOwnProperty("address") && !formData.address) {
    errors.address = "Required";
  }

  if (formData.hasOwnProperty("firstAiderName") && !formData.firstAiderName) {
    errors.firstAiderName = "Required";
  }

  if (formData.hasOwnProperty("settingWellbeing") && !formData.settingWellbeing) {
    errors.settingWellbeing = "Required";
  }

  return errors;
};
