import { Fragment } from "react";
import React, { useEffect, useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Dialog, Transition } from "@headlessui/react";
import { PDFViewer, Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#FFFFFF",
    padding: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  questionContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 6,
  },
  questionNumber: {
    fontSize: 12,
    fontWeight: "bold",
    marginRight: 5,
  },
  question: {
    fontSize: 12,
  },
  answerContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 16,
  },
  answerTitle: {
    fontSize: 12,
    fontWeight: "bold",
    marginRight: 8,
  },
  answer: {
    fontSize: 12,
    color: "gray",
  },
  pageBreak: {
    pageBreakAfter: "always",
  },
});

const ViewConsultationFormModal = ({ isOpen, setIsOpen, data }) => {
  
  const [consultationFormData, setConsultationFormData] = useState(null);

  useEffect(() => {
    setConsultationFormData(data);
  }, [data]);

  // Close Modal
  function closeModal() {
    setIsOpen(false);
  }

  const formatData = (data) => {
    let convertedQuestions = [];
    try {
      const parsedData = JSON.parse(data);
      if (parsedData.length && parsedData[0].question) {
        // Data is already in the desired format
        convertedQuestions = parsedData;
      } else {
        // Convert data to the desired format
        convertedQuestions = parsedData.map((item) => {
          const key = Object.keys(item)[0];
          const value = item[key];
          return { question: key, answer: value };
        });
      }
    } catch (error) {
      console.error("Error parsing questions JSON:", error);
    }
    return convertedQuestions;
  };

  const convertedQuestions = consultationFormData ? formatData(consultationFormData.questions) : [];

  // Component for PDF content
  const PDFContent = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          {convertedQuestions?.map((item, index) => (
            <View key={index}>
              <View style={styles.questionContainer}>
                <Text style={styles.questionNumber}>Q {index + 1}:</Text>
                <Text style={styles.question}>{item.question}</Text>
              </View>
              <View style={styles.answerContainer}>
                <Text style={styles.answerTitle}>Answer:</Text>
                <Text style={styles.answer}>{item.answer || "N/A"}</Text>
              </View>
              {index !== convertedQuestions?.length - 1 && <Text style={styles.pageBreak} />}
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className=" z-30" onClose={closeModal}>
          {/* Your existing modal content */}
         <div className="fixed inset-0 bg-black/20"/>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-50"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-50"
          >
            <div className="fixed inset-0 overflow-y-auto z-20">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Dialog.Panel className="scal w-full max-w-7xl  transform space-y-4 overflow-hidden rounded-2xl bg-white p-10 align-middle shadow-xl transition-all">
                  <div className="flex items-center justify-between">
                    <Dialog.Title as="h3" className="font-poppins text-lg font-semibold leading-7 text-secondary-color">
                      Form
                    </Dialog.Title>
                    <XMarkIcon onClick={closeModal} className="h-8 w-8 cursor-pointer text-grayColor300" />
                  </div>
                  <PDFViewer width="100%" height={620}>
                    <PDFContent />
                  </PDFViewer>
                </Dialog.Panel>
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition>
    </>
  );
};

export default ViewConsultationFormModal;
