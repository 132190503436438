import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useLoggedContext } from "../context/provider";
import Button from "./Button";
import RichTextEditor from "react-rte";
import PropTypes from "prop-types";
import ApiServices from "../api/services";

const AddEvaluatedModal = ({ isOpen, setIsOpen, data, editMode, onSuccess, setIsOpenReadMore }) => {

  // Context
  const { btnLoading, setBtnLoading, openSuccessModal } = useLoggedContext();

  // Use State
  const [evaluation, setEvaluation] = useState(RichTextEditor.createEmptyValue());
  const [action, setAction] = useState(RichTextEditor.createEmptyValue());
  const [evaluationError, setEvaluationError] = useState("");
  const [actionError, setActionError] = useState("");

  // const htmlTextForEvaluaiton = evaluation?.toString("html");
  // const htmlTextForAction = action?.toString("html");

  const isValidForm = () => {
    let isValidData = true;
  
    if (evaluation.toString("html").trim() === "<p><br></p>") {
      setEvaluationError("Required");
      isValidData = false;
    } else {
      setEvaluationError(""); 
    }
  
    if (action.toString("html").trim() === "<p><br></p>") {
      setActionError("Required");
      isValidData = false;
    } else {
      setActionError(""); 
    }
  
    return isValidData;
  };
  

  

  useEffect(() => {
    if (data && isOpen && data) {
      setEvaluation(RichTextEditor.createValueFromString(data.evaluation, "html"));
      setAction(RichTextEditor.createValueFromString(data.action, "html"));
    }
  }, [data, isOpen, data]);

  const updateEvaluateData = (event) => {
    event.preventDefault();

    const isValid = isValidForm();
    if (!isValid) return;


    let payload = {
      evaluation: evaluation?.toString("html"),
      action: action?.toString("html"),
    };

    setBtnLoading(true);
    ApiServices.recentEvaluations
      .updateEvaluateData(data?.id, payload)
      .then((res) => {
        const { data, message } = res;

        if (res.code === 200) {
          setBtnLoading(false);
          onSuccess()
          setIsOpen(false);
          openSuccessModal({
            title: "Success!",
            message: "Evaluated Data has been Updated",
            onPress: (close) => {
              close();
              onSuccess()
            },
          });
        }
      })
      .catch((err) => {
         
      });
  };

  const createEvaluation = (event) => {
    event.preventDefault();

    const isValid = isValidForm();
    if (!isValid) return;

    let payload = {
      evaluation: evaluation?.toString("html"),
      action: action?.toString("html"),
    };
    setBtnLoading(true);
    ApiServices.recentEvaluations
      .createEvaluateData(payload)
      .then((res) => {
        const { data, message } = res;
        if (res?.code === 200) {
          setBtnLoading(false);
          
          setIsOpen(false);
          
          openSuccessModal({
            title: "Success!",
            message: "Evaluated Data has been Created",
            onPress: (close) => {
              close();
              onSuccess()
              closeModal()
              
            },
          });
        }
      })
      .catch((err) => {
         
      });
  };

  // Close Modal
  function closeModal() {
    setIsOpen(false);
    setBtnLoading(false);
    setEvaluation(RichTextEditor.createEmptyValue());
    setAction(RichTextEditor.createEmptyValue());
    setEvaluationError("");
    setActionError("");
    setIsOpenReadMore(true)
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-75"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-75"
              >
                <Dialog.Panel className="w-full max-w-5xl p-10 overflow-hidden align-middle transition-all bg-white shadow-xl rounded-2xl">
                  <form className="space-y-8 ">
                    <div className="grid grid-cols-2 gap-5">
                      <div>
                        <p className="text-lg font-semibold leading-7 text-textColor font-poppins">
                          Evaluation {evaluationError && <span className="ml-1 text-sm text-red-600">{evaluationError}</span>}
                        </p>
                        <p className="mb-5 text-xs leading-7 text-textColor font-poppins">Add your thoughts related above data</p>
                        <RichTextEditor
                          value={evaluation}
                          onChange={(value) => {
                            setEvaluation(value);
                            setEvaluationError("");
                          }}
                          // onChange={setEvaluation}
                          placeholder="Write here..."
                        />
                      </div>
                      <div>
                        <p className="text-lg font-semibold leading-7 text-textColor font-poppins">
                          Actions {actionError && <span className="ml-1 text-sm text-red-600">{actionError}</span>}
                        </p>
                        <p className="mb-5 text-xs leading-7 text-textColor font-poppins">Add your actions related above data</p>
                        <RichTextEditor
                          value={action}
                          onChange={(value) => {
                            setAction(value);
                            setActionError("");
                          }}
                          placeholder="Write here..."
                        />
                      </div>
                    </div>
                    <div className="flex items-center justify-end gap-x-6">
                      <h3 onClick={closeModal} className="text-lg font-medium cursor-pointer text-secondary">
                        Cancel
                      </h3>
                      {editMode ? (
                        <Button loading={btnLoading} onClick={updateEvaluateData} title="Update" />
                      ) : (
                        <Button onClick={createEvaluation} loading={btnLoading} title="Submit" />
                      )}
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

AddEvaluatedModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  data: PropTypes.object, // Adjust prop type as per your requirement
};

export default AddEvaluatedModal;
