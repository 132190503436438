import { axios } from "../axios";
import { POST, GET } from "../endpoints";

export const getBroadcastNotifications = async (data) => {
  try {
    let response = await axios.get(GET.GET_BROADCAST_NOTIFICATION_LIST, {
      params: data,
    });
    return response;
  } catch (err) {
    throw err;
  }
};


export const addBroadcastNotification = async (notificationData) => {
  try {
    let response = await axios.post(POST.ADD_BROADCAST_NOTIFICATION,notificationData);
    return response;
  } catch (err) {
    throw err;
  }
};

export const sendQuestionnaireForm = async () => {
  try {
    let response = await axios.post(POST.SEND_QUESTIONNAIRE_FORM);
    return response;
  } catch (err) {
    throw err;
  }
};
