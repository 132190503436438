import Camera from "../img/camera.png";
import logo from "../img/workwell-logo.svg";
import Logo_Icon from "../img/icons/logo_icon.svg";
import Logo_Icon_White from "../img/icons/logo_icon_white.svg";
import authImgSvg from "../img/auth-image.webp";
import placeholder from "../img/placeholder.svg";
import logout from "../img/icons/signout-icon.svg";
import fileImage from "../img/fileImage.png";
import Stripe from "../img/stripe.png";
import NewLogo from "../img/new-logo.svg";
import QuestionRed from "../img/icons/question-red.svg";
import QuestionBlue from "../img/icons/question-blue.svg";
import QuestionGreen from "../img/icons/question-green.svg";
import GreatEmoji from "../img/icons/great-emoji.svg";

import LowIcon from "../img/icons/low.svg";
import PdfIcon from "../img/icons/pdf.svg";
import MehIcon from "../img/icons/meh.svg";
import OkayIcon from "../img/icons/okay.svg";
import GreatIcon from "../img/icons/great.svg";
import SleepIcon from "../img/icons/sleep.svg";
import UnWellIcon from "../img/icons/unwell.svg";
import StressIcon from "../img/icons/stress.svg";
import MentleHealth from "../img/icons/mental-health.svg";
import RelationIcon from "../img/icons/relation.svg";
import StugglingIcon from "../img/icons/struggling.svg";
import BereavementIcon from "../img/icons/bereavement.svg";

// sidebar
import HomeLifeIcon from "../img/icons/home.svg";
import Evaluation from "../img/icons/evaluation.svg";
import Users_Icon from "../img/icons/users-icon.svg";
import ContactBook from "../img/icons/contact_book.svg";
import Payments_Icon from "../img/icons/payments.svg";
import Settings_Icon from "../img/icons/settings.svg";
import Dashboard_Icon from "../img/icons/dashboard-icon.svg";
import Subscription_Icon from "../img/icons/subscription.svg";
import SupportTicket_Icon from "../img/icons/support-tickets.svg";
import Notification_Icon from "../img/icons/notifications-icon.svg";
import Employee_Icon from "../img/icons/employees.svg";
import Report_Icon from "../img/icons/reports.svg";
import Campus_Icon from "../img/icons/campus.svg";
import Department_Icon from "../img/icons/department.svg";
// Card Widget
import ArrowDown from "../img/icons/down.svg";
import ArrowRise from "../img/icons/arrow-rise.svg";
import PaymentCardIcon from "../img/icons/card-icon.svg";
import PaymentCardIcon2 from "../img/icons/card-icon2.svg";

// settings
import Recipt from "../img/recipt.png";
import info from "../img/icons/info.svg";
import Setting1 from "../img/setting1.webp";
import Setting2 from "../img/setting2.webp";

// support Tickets
import Open from "../img/icons/Open.svg";
import Crown from "../img/icons/crown.svg";
import Closed from "../img/icons/Closed.svg";
import Tickets from "../img/icons/Tickets.svg";
import Comment from "../img/icons/Comment.svg";
import TicketDetail from "../img/ticketDetail.png";
import OpenTicket from "../img/icons/open-ticket.svg";
import ActiveOpen from "../img/icons/ActiveOpen.svg";
import Attachments from "../img/icons/attachment.svg";
import CompanyIcon from "../img/icons/CompanyIcon.svg";
import ActiveClosed from "../img/icons/ActiveClosed.svg";
import ActiveTickets from "../img/icons/ActiveTickets.svg";

// dashboard
import TotalUsers from "../img/icons/total-users.svg";
import TotalTickets from "../img/icons/total-tickets.svg";
import TotalSettings from "../img/icons/total-settings.svg";
import TotalEarnings from "../img/icons/total-earnings.svg";

// subcription
import SaveIcon from "../img/icons/save-icon.svg";
import EditPencil from "../img/icons/edit-pencil.svg";
import CancelIcon from "../img/icons/cancel-icon.svg";
import HistoryInfo from "../img/icons/historyInfo.svg";
import RedCancelIcon from "../img/icons/red-cancel-icon.svg";
import GreenSaveIcon from "../img/icons/green-save-icon.svg";

import OpenTicketIcon from "../img/icons/open-ticket.svg";
import CloseTicketIcon from "../img/icons/close-ticket.svg";

import PDFIcon from "../img/icons/pdf-icon.svg";
import PDF from "../img/icons/pdf2.svg";
import Delete_Icon from "../img/icons/delete.svg";
import CalenderIcon from "../img/icons/calender.svg";
import AllSettingIcon from "../img/icons/all-settings.svg";
import AllDepartmentIcon from "../img/icons/all-departments.svg";
import AllCampusIcon from "../img/icons/all-campuses.svg";
import CLOSE from "../img/icons/cross.svg";
import REDCLOSE from "../img/icons/RedCross.svg";
import FILTER from "../img/icons/filter.svg";
import National_Icon from "../img/icons/national.webp";
import Award_Icon from "../img/icons/award.webp";
import Studio_Icon from "../img/icons/studio.svg";

export const images = {
  National_Icon,
  Award_Icon,
  Studio_Icon,
  logo,
  info,
  info,
  Open,
  Crown,
  NewLogo,
  logout,
  Recipt,
  Closed,
  Camera,
  PDF,
  fileImage,
  PDFIcon,
  MehIcon,
  Stripe,
  Tickets,
  GreatEmoji,
  LowIcon,
  Comment,
  OkayIcon,
  Report_Icon,
  SleepIcon,
  SaveIcon,
  REDCLOSE,
  ContactBook,
  Setting2,
  Setting1,
  Employee_Icon,
  GreatIcon,
  ArrowDown,
  ArrowRise,
  Logo_Icon,
  Logo_Icon_White,
  CancelIcon,
  TotalUsers,
  Users_Icon,
  Evaluation,
  ActiveOpen,
  OpenTicket,
  authImgSvg,
  StressIcon,
  EditPencil,
  Attachments,
  QuestionRed,
  CompanyIcon,
  UnWellIcon,
  placeholder,
  HistoryInfo,
  MentleHealth,
  QuestionBlue,
  HomeLifeIcon,
  TicketDetail,
  ActiveClosed,
  TotalTickets,
  RelationIcon,
  RedCancelIcon,
  StugglingIcon,
  GreenSaveIcon,
  ActiveTickets,
  QuestionGreen,
  TotalSettings,
  TotalEarnings,
  Settings_Icon,
  Payments_Icon,
  Dashboard_Icon,
  BereavementIcon,
  PaymentCardIcon,
  PaymentCardIcon2,
  Notification_Icon,
  Subscription_Icon,
  SupportTicket_Icon,

  OpenTicketIcon,
  CloseTicketIcon,
  Delete_Icon,
  PDFIcon,
  Department_Icon,
  Campus_Icon,
  CalenderIcon,
  AllSettingIcon,
  AllDepartmentIcon,
  AllCampusIcon,
  FILTER,
  CLOSE,
};
