import React from "react";
import { ArrowPathIcon } from "@heroicons/react/24/solid";

const Heading = ({ heading, subheading, inlineTitle ,icon , handleRefresh}) => {
  const MAX_LENGTH = 500;

  // Function to truncate the subheading if it exceeds the maximum character length
  const truncateSubheading = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  };

  const truncatedSubheading = truncateSubheading(subheading, MAX_LENGTH);

  return (
    <div className="space-y-2">
      <div className="flex justify-between">
        <h2 className="text-base xl:text-lg font-semibold 3xl:text-xl text-secondary-color">{heading} {icon && <ArrowPathIcon onClick={handleRefresh} className="inline-block  h-5 w-5 cursor-pointer text-grayColor300" />}</h2>
        <h2 className="text-sm font-semibold 3xl:text-base text-secondary-color">{inlineTitle}</h2>
      </div>
      <p className="text-xs 3xl:text-sm text-primary-color-100">{truncatedSubheading}</p>
    </div>
  );
};

export default Heading;
