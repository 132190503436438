import { axios } from "../axios";
import { POST, GET , DELETE } from "../endpoints";

export const getDepartments = async (data) => {
    try {
      let response = await axios.get(GET.GET_DEPARTMENTS_LIST, {
        params: data,
      });
      return response;
    } catch (err) {
      throw err;
    }
  };

  export const addDepartment = async (data) => {
    try {
      let response = await axios.post(POST.ADD_DEPARMENT,data);
      return response;
    } catch (err) {
      throw err;
    }
  };

  export const updateDepartment = async (id, data) => {
    try {
      let response = await axios.post(`${POST.ADD_DEPARMENT}/${id}`,data);
      return response;
    } catch (err) {
      throw err;
    }
  };


  export const removeCampusDepartment = async (data) => {
    try {
      let response = await axios.post(POST.REMOVE_CAMPUS_DEPARTMENT,data);
      return response;
    } catch (err) {
      throw err;
    }
  };

  export const removeDepartment = async (id) => {
    try {
      let response = await axios.delete(`${DELETE.DELETE_DEPARTMENT}/${id}`);
      return response;
    } catch (err) {
      throw err;
    }
  };


  export const addCampusDepartment = async (data) => {
    try {
      let response = await axios.post(POST.ADD_CAMPUS_DEPARTMENT,data);
      return response;
    } catch (err) {
      throw err;
    }
  };
