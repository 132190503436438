import React from "react";
import { Routes, Route } from "react-router-dom";
import "./assets/styles/style.css";
import "./assets/styles/index.css";

// ===================== Page Not Found ===================== //
import NotFound from "./pages/NotFound";

// ===================== Protected Routes ===================== //
import { ProtectedRoutes } from "./routes/index";
import ProtectedRoute from "./routes/ProtectedRoute";

// ===================== Public Routes ===================== //
import { PublicRoutes } from "./routes/index";
import PublicRoute from "./routes/PublicRoute";

// ===================== Routes ======================= //
import { dashboard, login } from "./routes/routesDefined";

import SessionModal from "./components/SessionModal";
import { useLoggedContext } from "./context/provider";

function App() {
  const { sessionExpire, setSessionExpire } = useLoggedContext();
  return (
    <>
      <Routes>
        <Route element={<ProtectedRoute RedirectPath={login} />}>
          {ProtectedRoutes.map((route) => (
            <Route key={route.id} path={route.path} element={route.component} />
          ))}
        </Route>

        <Route element={<PublicRoute RedirectPath={dashboard} />}>
          {PublicRoutes.map((route) => (
            <Route key={route.id} path={route.path} element={route.component} />
          ))}
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>

      {sessionExpire && <SessionModal sessionExpire={sessionExpire} setSessionExpire={setSessionExpire} />}
    </>
  );
}

export default App;
