import { axios } from "../axios";
import { POST, GET } from "../endpoints";

export const getCauseForConcern = async (data) => {
  try {
    let response = await axios.get(GET.CAUSE_FOR_CONCERN, {
      params: data,
    });
    return response;
  } catch (err) {
    throw err;
  }
};
