import { axios } from "../axios";
import { GET , DELETE , POST } from "../endpoints";

export const getRecentEvaluations = async (data) => {
  try {
    let response = await axios.get(GET.GET_EVALUATION_LIST, {
      params: data,
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const deleteEvaluateData = async (id) => {
  try {
    let response = await axios.delete(`${DELETE.DELETE_EVALUATION}/${id}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const updateEvaluateData = async (id, data) => {
  try {
    let response = await axios.post(`${POST.UPDATE_EVALUATION_DATA}/${id}`, data);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const createEvaluateData = async (data) => {
  try {
    let response = await axios.post(`${POST.CREATE_EVALUATION_DATA}`, data )
    return response.data;
  } catch (err) {
    throw err;
  }
};