import React from "react";
import moment from "moment";
import { images } from "../assets/styles/Images";
const ReportTable = ({ reportData }) => {
  const poorSleepPercentage = reportData?.ReasonPercentage?.poorSleep_percentage;
  const relationshipProblemsPercentage = reportData?.ReasonPercentage?.relationship_problems_percentage;
  const unwellPercentage = reportData?.ReasonPercentage?.unwell_percentage;

  const poorSleepTotalCount = reportData?.ReasonPercentage?.poorSleepTotalCount;
  const relationshipProblemsCount = reportData?.ReasonPercentage?.relationshipProblemsCount;
  const unwellTotalCount = reportData?.ReasonPercentage?.unwellTotalCount;

  const greateOkValue = reportData?.ResponsePercentage?.greate_ok;
  const mehLowStrugglingValue = reportData?.ResponsePercentage?.meh_low_struggling;

  let highestEntryValue, lowestEntryValue;

  // Determine the highest and lowest values
  if (greateOkValue >= mehLowStrugglingValue) {
    highestEntryValue = greateOkValue;
    lowestEntryValue = mehLowStrugglingValue;
  } else {
    highestEntryValue = mehLowStrugglingValue;
    lowestEntryValue = greateOkValue;
  }

  const allEqualEntryValue = greateOkValue == mehLowStrugglingValue;

  const greatOkPercentage = reportData?.ResponsePercentage?.greate_ok_percentage;
  const mehLowStrugglingPercentage = reportData?.ResponsePercentage?.meh_low_struggling_percentage;

  let highestEntryPercentage, lowestEntryPercentage;

  // Determine the highest and lowest percentages
  if (greatOkPercentage >= mehLowStrugglingPercentage) {
    highestEntryPercentage = greatOkPercentage;
    lowestEntryPercentage = mehLowStrugglingPercentage;
  } else {
    highestEntryPercentage = mehLowStrugglingPercentage;
    lowestEntryPercentage = greatOkPercentage;
  }

  const allEqualEntryPercentage = greatOkPercentage == mehLowStrugglingPercentage;

  const getColorClass = (value, allValues) => {
    const sortedValues = allValues.sort((a, b) => b - a);
    if (value === sortedValues[0]) {
      return "bg-[#88FA4E]"; // Green
    } else if (value === sortedValues[1]) {
      return "bg-[#FED477]"; // Yellow
    } else if (value === sortedValues[5]) {
      return "bg-[#FF968D]"; // Red
    }
  };

  const getBackgroundColorClass = (value, allValues) => {
    const sortedValues = allValues.sort((a, b) => b - a);
    if (value === sortedValues[0]) {
      return "bg-[#88FA4E]"; // Green
    } else if (value === sortedValues[1]) {
      return "bg-[#FED477]"; // Yellow
    } else if (value === sortedValues[4]) {
      return "bg-[#FF968D]"; // Red
    }
  };

  const counts = reportData?.Response?.map((row) => row.Count) || [];
  const maxCount = Math.max(...counts);
  const minCount = Math.min(...counts);

  return (
    <>
      <div>
        <table className="mt-12 min-w-full border-collapse overflow-hidden text-secondary-color">
          <thead className="bg-[#F6F7F8]">
            <tr>
              <th colSpan={7} className="border border-primary-color-300 bg-white px-1 py-1 text-left text-lg">
                Entries
              </th>{" "}
            </tr>
            <tr className="text-base">
              <th className="border border-primary-color-300 px-1 py-1 text-left font-semibold">
                {reportData?.type === "daily" ? "Hour" : reportData?.type === "monthly" ? "Date" : reportData?.type === "weekly" ? "weekly" : "Month"}
              </th>
              <th className="border border-primary-color-300 px-1 py-1 text-left font-semibold">
                <div className="flex items-center gap-x-2">
                  <div>
                    <img className="h-7 w-7" src={images.GreatIcon} alt="icon" />
                  </div>
                  Great
                </div>
              </th>
              <th className="border border-primary-color-300 px-1 py-1 text-left font-semibold">
                <div className="flex items-center gap-x-2">
                  <div>
                    <img className="h-7 w-7" src={images.OkayIcon} alt="icon" />
                  </div>
                  Okay
                </div>
              </th>
              <th className="border border-primary-color-300 px-1 py-1 text-left font-semibold">
                <div className="flex items-center gap-x-2">
                  <div>
                    <img className="h-7 w-7" src={images.MehIcon} alt="icon" />
                  </div>
                  Meh
                </div>
              </th>
              <th className="border border-primary-color-300 px-1 py-1 text-left font-semibold">
                <div className="flex items-center gap-x-2">
                  <div>
                    <img className="h-7 w-7" src={images.LowIcon} alt="icon" />
                  </div>
                  Low
                </div>
              </th>
              <th className="border border-primary-color-300 px-1 py-1 text-left font-semibold">
                <div className="flex items-center gap-x-2">
                  <div>
                    <img className="h-7 w-7" src={images.StugglingIcon} alt="icon" />
                  </div>
                  Struggling
                </div>
              </th>
              <th className="border border-primary-color-300 px-1 py-1 text-left font-semibold">Entries</th>
            </tr>
          </thead>
          <tbody>
            {reportData?.Response?.map((row, index) => (
              <tr key={index} className={`text-sm ${index % 2 === 0 ? "bg-white" : "bg-[#F6F7F8]"}`}>
                <td className="border border-primary-color-300 px-1 py-1">
                  {/* {row?.month || moment(row?.date).format("MMM D YYYY") || moment(row.hour).format("MMM D YYYY h:mm A ")} */}
                  {reportData?.type === "daily"
                    ? moment(row.hour).format("MMM D YYYY h:mm A ")
                    : reportData?.type === "monthly"
                      ? moment(row?.date).format("MMM D YYYY")
                      : reportData?.type === "weekly"
                        ? moment(row?.date).format("MMM D YYYY")
                        : row?.month}
                </td>
                <td className="border border-primary-color-300 px-1 py-1 text-right">{row.Great === null ? "0" : row.Great}</td>
                <td className="border border-primary-color-300 px-1 py-1 text-right">{row.Okay === null ? "0" : row.Okay}</td>
                <td className="border border-primary-color-300 px-1 py-1 text-right">{row.Meh === null ? "0" : row.Meh}</td>
                <td className="border border-primary-color-300 px-1 py-1 text-right">{row.Low === null ? "0" : row.Low}</td>
                <td className="border border-primary-color-300 px-1 py-1 text-right">{row.Struggling === null ? "0" : row.Struggling}</td>
                <td
                  className={`border border-primary-color-300 px-1 py-1 text-right ${row.Count === maxCount ? "bg-[#88FA4E]" : ""} ${
                    row.Count === minCount ? "bg-[#FF968D]" : ""
                  }`}
                >
                  {row.Count}
                </td>
              </tr>
            ))}

            <tr className="bg-white text-sm">
              <td className="border border-primary-color-300 px-1 py-1 font-semibold">Total</td>
              <td
                className={`border border-primary-color-300 px-1 py-1 text-right font-semibold ${getBackgroundColorClass(reportData?.ResponsePercentage?.greatTotal, [reportData?.ResponsePercentage?.greatTotal, reportData?.ResponsePercentage?.okTotal, reportData?.ResponsePercentage?.mehTotal, reportData?.ResponsePercentage?.lowTotal, reportData?.ResponsePercentage?.strugglingTotal])}`}
              >
                {reportData?.ResponsePercentage?.greatTotal}
              </td>
              <td
                className={`border border-primary-color-300 px-1 py-1 text-right font-semibold ${getBackgroundColorClass(reportData?.ResponsePercentage?.okTotal, [reportData?.ResponsePercentage?.greatTotal, reportData?.ResponsePercentage?.okTotal, reportData?.ResponsePercentage?.mehTotal, reportData?.ResponsePercentage?.lowTotal, reportData?.ResponsePercentage?.strugglingTotal])}`}
              >
                {reportData?.ResponsePercentage?.okTotal}
              </td>
              <td
                className={`border border-primary-color-300 px-1 py-1 text-right font-semibold ${getBackgroundColorClass(reportData?.ResponsePercentage?.mehTotal, [reportData?.ResponsePercentage?.greatTotal, reportData?.ResponsePercentage?.okTotal, reportData?.ResponsePercentage?.mehTotal, reportData?.ResponsePercentage?.lowTotal, reportData?.ResponsePercentage?.strugglingTotal])}`}
              >
                {reportData?.ResponsePercentage?.mehTotal}
              </td>
              <td
                className={`border border-primary-color-300 px-1 py-1 text-right font-semibold ${getBackgroundColorClass(reportData?.ResponsePercentage?.lowTotal, [reportData?.ResponsePercentage?.greatTotal, reportData?.ResponsePercentage?.okTotal, reportData?.ResponsePercentage?.mehTotal, reportData?.ResponsePercentage?.lowTotal, reportData?.ResponsePercentage?.strugglingTotal])}`}
              >
                {reportData?.ResponsePercentage?.lowTotal}
              </td>
              <td
                className={`border border-primary-color-300 px-1 py-1 text-right font-semibold ${getBackgroundColorClass(reportData?.ResponsePercentage?.strugglingTotal, [reportData?.ResponsePercentage?.greatTotal, reportData?.ResponsePercentage?.okTotal, reportData?.ResponsePercentage?.mehTotal, reportData?.ResponsePercentage?.lowTotal, reportData?.ResponsePercentage?.strugglingTotal])}`}
              >
                {reportData?.ResponsePercentage?.strugglingTotal}
              </td>
            </tr>

            <tr className=" text-sm font-bold">
              <td className="bg-white"></td>
              <td
                colSpan={2}
                className={`border border-primary-color-300 ${allEqualEntryValue ? "bg-[#88FA4E]" : lowestEntryValue === greateOkValue ? "bg-[#FF968D]" : "bg-[#88FA4E]"} px-1 py-1 text-center`}
              >
                {greateOkValue}
              </td>
              <td
                colSpan={3}
                className={`border border-primary-color-300 ${allEqualEntryValue ? "bg-[#88FA4E]" : highestEntryValue === mehLowStrugglingValue ? "bg-[#88FA4E]" : "bg-[#FF968D]"} px-1 py-1 text-center`}
              >
                {mehLowStrugglingValue}
              </td>
            </tr>
            <tr className="text-sm font-extrabold">
              <td className="bg-white"></td>
              <td
                colSpan={2}
                className={`border border-primary-color-300 ${allEqualEntryPercentage ? "bg-[#88FA4E]" : lowestEntryPercentage === greatOkPercentage ? "bg-[#FF968D]" : "bg-[#88FA4E]"} px-1 py-1 text-center`}
              >
                {greatOkPercentage}%
              </td>
              <td
                colSpan={3}
                className={`border border-primary-color-300 ${allEqualEntryPercentage ? "bg-[#88FA4E]" : highestEntryPercentage === mehLowStrugglingPercentage ? "bg-[#88FA4E]" : "bg-[#FF968D]"} px-1 py-1 text-center`}
              >
                {mehLowStrugglingPercentage}%
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div>
        <table className="mt-12 min-w-full border-collapse overflow-hidden text-secondary-color">
          <thead className="bg-[#F6F7F8]">
            <tr>
              <th colSpan={7} className="border border-primary-color-300 bg-white px-1 py-1 text-left text-lg">
                Reasons ( meh , low , struggling )
              </th>{" "}
            </tr>
            <tr className="text-base">
              <th className="border border-primary-color-300 px-1 py-1 text-left font-semibold">
                {reportData?.type === "daily" ? "Hour" : reportData?.type === "monthly" ? "Date" : reportData?.type === "weekly" ? "weekly" : "Month"}
              </th>
              <th className="border border-primary-color-300 px-0.5 py-1 text-left  text-xs font-semibold">
                <div className="flex items-center gap-x-1">
                  <div className="shrink-0">
                    <img className="h-7 w-7" src={images.SleepIcon} alt="icon" />
                  </div>
                  Poor Sleep
                </div>
              </th>
              <th className="border border-primary-color-300 py-1 pl-1 pr-3 text-left  text-xs font-semibold">
                <div className="flex items-center gap-x-1">
                  <div className="shrink-0">
                    <img className="h-7 w-7" src={images.RelationIcon} alt="icon" />
                  </div>
                  Relationships
                </div>
              </th>
              <th className="border border-primary-color-300 py-1 pl-1 pr-4 text-left  text-xs font-semibold">
                <div className="flex items-center gap-x-1">
                  <div className="shrink-0">
                    <img className="h-7 w-7" src={images.BereavementIcon} alt="icon" />
                  </div>
                  Bereavement
                </div>
              </th>
              <th className="border border-primary-color-300 px-0.5 py-1 text-left  text-xs font-semibold">
                <div className="flex items-center gap-x-1">
                  <div className="shrink-0">
                    <img className="h-7 w-7" src={images.HomeLifeIcon} alt="icon" />
                  </div>
                  Home Life
                </div>
              </th>
              <th className="border border-primary-color-300 py-1 pl-1 pr-4 text-left  text-xs font-semibold">
                <div className="flex items-center gap-x-1">
                  <div className="shrink-0">
                    <img className="h-7 w-7" src={images.UnWellIcon} alt="icon" />
                  </div>
                  Unwell
                </div>
              </th>
              <th className="border border-primary-color-300 px-0.5 py-1 text-left  text-xs font-semibold">
                <div className="flex items-center gap-x-1">
                  <div className="shrink-0">
                    <img className="h-7 w-7" src={images.StressIcon} alt="icon" />
                  </div>
                  Work Related
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {reportData?.Reason?.map((row, index) => (
              <tr key={index} className={`text-sm ${index % 2 === 0 ? "bg-white" : "bg-[#F6F7F8]"}`}>
                <td className="border border-primary-color-300 px-1 py-1">
                  {reportData?.type === "daily"
                    ? moment(row.hour).format("MMM D YYYY h:mm A ")
                    : reportData?.type === "monthly"
                      ? moment(row?.date).format("MMM D YYYY")
                      : reportData?.type === "weekly"
                        ? moment(row?.date).format("MMM D YYYY")
                        : row?.month}
                </td>
                <td className="border border-primary-color-300 px-1 py-1 text-right">{row?.poor_sleep === null ? "0" : row?.poor_sleep}</td>
                <td className="border border-primary-color-300 px-1 py-1 text-right">{row?.relationship_problems === null ? "0" : row?.relationship_problems}</td>
                <td className="border border-primary-color-300 px-1 py-1 text-right">{row?.bereavement === null ? "0" : row?.bereavement}</td>
                <td className="border border-primary-color-300 px-1 py-1 text-right">{row?.home_life_difficulties === null ? "0" : row?.home_life_difficulties}</td>
                <td className="border border-primary-color-300 px-1 py-1 text-right">{row?.unwell === null? "0" : row?.unwell}</td>
                <td className="border border-primary-color-300 px-1 py-1 text-right">{row?.work_stress === null ? "0" : row?.work_stress}</td>
              </tr>
            ))}
            <tr className="bg-white text-sm">
              <td className="border border-primary-color-300 px-1 py-1 font-semibold">Total</td>
              <td
                className={`border border-primary-color-300 ${getColorClass(poorSleepTotalCount, [poorSleepTotalCount, relationshipProblemsCount, reportData?.ReasonPercentage?.bereavementTotalCount, reportData?.ReasonPercentage?.homeLifeDifficultyTotalCount, unwellTotalCount, reportData?.ReasonPercentage?.workStressTotalCount])} px-1 py-1 text-right`}
              >
                {poorSleepTotalCount}
              </td>
              <td
                className={`border border-primary-color-300 ${getColorClass(relationshipProblemsCount, [poorSleepTotalCount, relationshipProblemsCount, reportData?.ReasonPercentage?.bereavementTotalCount, reportData?.ReasonPercentage?.homeLifeDifficultyTotalCount, unwellTotalCount, reportData?.ReasonPercentage?.workStressTotalCount])} px-1 py-1 text-right`}
              >
                {relationshipProblemsCount}
              </td>
              <td
                className={`border border-primary-color-300 ${getColorClass(reportData?.ReasonPercentage?.bereavementTotalCount, [poorSleepTotalCount, relationshipProblemsCount, reportData?.ReasonPercentage?.bereavementTotalCount, reportData?.ReasonPercentage?.homeLifeDifficultyTotalCount, unwellTotalCount, reportData?.ReasonPercentage?.workStressTotalCount])} px-1 py-1 text-right font-semibold`}
              >
                {reportData?.ReasonPercentage?.bereavementTotalCount}
              </td>
              <td
                className={`border border-primary-color-300 ${getColorClass(reportData?.ReasonPercentage?.homeLifeDifficultyTotalCount, [poorSleepTotalCount, relationshipProblemsCount, reportData?.ReasonPercentage?.bereavementTotalCount, reportData?.ReasonPercentage?.homeLifeDifficultyTotalCount, unwellTotalCount, reportData?.ReasonPercentage?.workStressTotalCount])} px-1 py-1 text-right font-semibold`}
              >
                {reportData?.ReasonPercentage?.homeLifeDifficultyTotalCount}
              </td>
              <td
                className={`border border-primary-color-300 ${getColorClass(unwellTotalCount, [poorSleepTotalCount, relationshipProblemsCount, reportData?.ReasonPercentage?.bereavementTotalCount, reportData?.ReasonPercentage?.homeLifeDifficultyTotalCount, unwellTotalCount, reportData?.ReasonPercentage?.workStressTotalCount])} px-1 py-1 text-right`}
              >
                {unwellTotalCount}
              </td>
              <td
                className={`border border-primary-color-300 ${getColorClass(reportData?.ReasonPercentage?.workStressTotalCount, [poorSleepTotalCount, relationshipProblemsCount, reportData?.ReasonPercentage?.bereavementTotalCount, reportData?.ReasonPercentage?.homeLifeDifficultyTotalCount, unwellTotalCount, reportData?.ReasonPercentage?.workStressTotalCount])} px-1 py-1 text-right font-semibold`}
              >
                {reportData?.ReasonPercentage?.workStressTotalCount}
              </td>
            </tr>

            <tr className="bg-white text-sm font-bold">
              <td></td>
              <td
                className={`border border-primary-color-300 ${getColorClass(poorSleepPercentage, [poorSleepPercentage, relationshipProblemsPercentage, reportData?.ReasonPercentage?.bereavement_percentage, reportData?.ReasonPercentage?.homeLifeDifficulty_percentage, unwellPercentage, reportData?.ReasonPercentage?.workStress_percentage])} px-1 py-1 text-right`}
              >
                {poorSleepPercentage}%
              </td>
              <td
                className={`border border-primary-color-300 ${getColorClass(relationshipProblemsPercentage, [poorSleepPercentage, relationshipProblemsPercentage, reportData?.ReasonPercentage?.bereavement_percentage, reportData?.ReasonPercentage?.homeLifeDifficulty_percentage, unwellPercentage, reportData?.ReasonPercentage?.workStress_percentage])} px-1 py-1 text-right`}
              >
                {relationshipProblemsPercentage}%
              </td>

              <td
                className={`border border-primary-color-300 ${getColorClass(reportData?.ReasonPercentage?.bereavement_percentage, [poorSleepPercentage, relationshipProblemsPercentage, reportData?.ReasonPercentage?.bereavement_percentage, reportData?.ReasonPercentage?.homeLifeDifficulty_percentage, unwellPercentage, reportData?.ReasonPercentage?.workStress_percentage])} px-1 py-1 text-right`}
              >
                {reportData?.ReasonPercentage?.bereavement_percentage}%
              </td>

              <td
                className={`border border-primary-color-300 ${getColorClass(reportData?.ReasonPercentage?.homeLifeDifficulty_percentage, [poorSleepPercentage, relationshipProblemsPercentage, reportData?.ReasonPercentage?.bereavement_percentage, reportData?.ReasonPercentage?.homeLifeDifficulty_percentage, unwellPercentage, reportData?.ReasonPercentage?.workStress_percentage])} px-1 py-1 text-right`}
              >
                {reportData?.ReasonPercentage?.homeLifeDifficulty_percentage}%
              </td>

              <td
                className={`border border-primary-color-300 ${getColorClass(unwellPercentage, [poorSleepPercentage, relationshipProblemsPercentage, reportData?.ReasonPercentage?.bereavement_percentage, reportData?.ReasonPercentage?.homeLifeDifficulty_percentage, unwellPercentage, reportData?.ReasonPercentage?.workStress_percentage])} px-1 py-1 text-right`}
              >
                {unwellPercentage}%
              </td>

              <td
                className={`border border-primary-color-300 ${getColorClass(reportData?.ReasonPercentage?.workStress_percentage, [poorSleepPercentage, relationshipProblemsPercentage, reportData?.ReasonPercentage?.bereavement_percentage, reportData?.ReasonPercentage?.homeLifeDifficulty_percentage, unwellPercentage, reportData?.ReasonPercentage?.workStress_percentage])} px-1 py-1 text-right`}
              >
                {reportData?.ReasonPercentage?.workStress_percentage}%
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <table className="mt-12 min-w-full border-collapse overflow-hidden text-secondary-color">
          <thead className="bg-[#F6F7F8]">
            <tr className="text-base">
              <th className="w-40 border border-primary-color-300 px-1 py-1 text-left font-semibold">Date</th>
              <th className="w-60 border border-primary-color-300 px-1 py-1 text-left font-semibold">Evaluation of data</th>
              <th className="w-60 border border-primary-color-300 px-1 py-1 text-left font-semibold">Actions</th>
            </tr>
          </thead>
          <tbody>
            {reportData?.Evaluation?.map((row, index) => (
              <tr key={index} className={`text-sm${index % 2 === 0 ? " bg-white" : " bg-[#F6F7F8]"}`}>
                <td className="border border-primary-color-300 px-1 py-1 text-start align-top">
                  {moment.unix(row?.created_at_unix).format("D MMM YYYY")}
                </td>
                <td className="border border-primary-color-300 px-1 py-1 text-start" dangerouslySetInnerHTML={{ __html: row?.evaluation }} />
                <td className="border border-primary-color-300 px-1 py-1 text-start align-top" dangerouslySetInnerHTML={{ __html: row?.action }} />
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ReportTable;
