import { axios } from "../axios";
import { GET } from "../endpoints";

export const getDashboardUsersStats = async (data) => {
  try {
    let response = await axios.get(GET.GET_DASHBOARD_USERS_STATS, {
      params: data,
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const getSupportTicketsCount = async (data) => {
  try {
    let response = await axios.get(GET.GET_DASHBOARD_SUPPORT_TICKET_COUNTS, {
      params: data,
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const getForms = async (data) => {
  try {
    let response = await axios.get(GET.GET_DASHBOARD_FORMS, {
      params: data,
    });
    return response;
  } catch (err) {
    throw err;
  }
};
export const getRecentResponseAndPendingForms = async (data) => {
  try {
    let response = await axios.get(GET.GET_RECENT_RESPONSE_AND_PENDING_FORMS, {
      params: data,
    });
    return response;
  } catch (err) {
    throw err;
  }
};



export const getEmployeeDashboardStats = async (data) => {
  try {
    let response = await axios.get(GET.GET_EMPLOYEE_DASHBOARD_STATS, {
      params: data
    });
    return response;
  } catch (err) {
    throw err;
  }
};