import moment from "moment";
import Lottie from "react-lottie";
import Badge from "../components/Badge";
import { Tooltip } from "react-tooltip";
import ApiServices from "../api/services";
import Button from "../components/Button";
import ReactPaginate from "react-paginate";
import Heading from "../components/Heading";
import { images } from "../assets/styles/Images";
import { useMediaQuery } from "react-responsive";
import React, { useEffect, useState } from "react";
import ImagesModal from "../components/ImagesModal";
import FilterSelect from "../components/FilterSelect";
import StatusFilter from "../components/StatusFilter";
import TicketDetail from "../components/TicketDetail";
import { useLoggedContext } from "../context/provider";
import SupportFilter from "../components/SupportFilter";
import animationData from "../assets/lotties/empty-folder";
import { employeeMediaUrl, mediaUrl, ticketMediaUrl } from "../utils/config";
import NotifyUserModal from "../components/NotifyUserModal";
import SupportTicketComment from "../components/SupportTicketComment";
import { ChevronLeftIcon, ChevronRightIcon, MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/solid";
import Spinner from "../components/Spinner";
import RangePicker from "../components/RangePicker";
import EllipsisText from "../components/EllipsisText";
import ReadMoreModal from "../components/ReadMoreModal";
import Skeleton from "react-loading-skeleton";
import ConfirmationModal from "../components/ConfirmationModal";
import { ArrowPathIcon } from "@heroicons/react/24/outline";

const statusOption = [
  { label: "All", value: "All" },
  { label: "Opened", value: "Open" },
  { label: "Closed", value: "Closed" },
];

const SupportTicket = () => {
  // Context
  const { btnLoading, setBtnLoading, setLoading, user } = useLoggedContext();

  const [searchText, setSearchText] = useState("");
  const [replayText, setReplayText] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [allNursries, setAllNursries] = useState([]);
  const [showMsgBox, setShowMsgBox] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [ticketEndDate, setTicketEndDate] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [replayTextError, setReplayTextError] = useState("");
  const [ticketStartDate, setTicketStartDate] = useState(null);
  const [selectedTicketId, setSelectedTicketId] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [previewReplyImages, setPreviewReplyImages] = useState([]);
  const [supportTicketsData, setSupportTicketsData] = useState([]);
  const [selectedReplyImages, setSelectedReplyImages] = useState([]);
  const [selectedTicketDetails, setSelectedTicketDetails] = useState(null);
   
  const [openCreateTicketModal, setOpenCreateTicketModal] = useState(false);
  const [openImageModal, setOpenImageModal] = useState({ open: false, data: null });
  const [selectedStatusOption, setSelectedStatusOption] = useState(null);
  const [openCounts, setOpenCounts] = useState("");
  const [closeCounts, setCloseCounts] = useState("");
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [openReadMoreMessageModal, setOpenReadMoreMessageModal] = useState({ open: false, data: null });
  const [supportTicketsDataLoading, setSupportTicketsDataLoading] = useState(false);
  const [supportTicketsDetailLoading, setSupportTicketsDetailLoading] = useState(false);
  const [openCloseConfirmationModal, setOpenCloseConfirmationModal] = useState({ open: false });
  const [openTicketOpenConfirmationModal, setOpenTicketOpenConfirmationModal] = useState({ open: false });
  const [replyLoading, setReplyLoading] = useState(false);

  // Handle Select Images
  const handleReplyImageChange = (event) => {
    const files = event.target.files;

    if (files?.length > 0) {
      const newImages = Array.from(files)?.map((file) => URL.createObjectURL(file));
      setPreviewReplyImages((prevImages) => [...prevImages, ...newImages]);

      const filesArray = Array.from(files);
      setSelectedReplyImages((prevImages) => [...prevImages, ...filesArray]);
    }
  };

  // Handle Cancel Selection
  const handleCancelSelection = (index) => {
    setPreviewReplyImages((prevImages) => prevImages?.filter((_, i) => i !== index));
    setSelectedReplyImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  // Handle Ticket Click
  const handleTicketClick = (ticketId) => {
    setSelectedTicketId(ticketId);
    getSupportTicketDetails(ticketId);
  };

  // Handle Image Click
  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    setOpenImageModal({ open: true, data: null });
  };

  // Media Queries
  const isTablet = useMediaQuery({ maxWidth: 1024 });
  const isLaptop = useMediaQuery({ minWidth: 1024 });
  const isLaptopMedium = useMediaQuery({ minWidth: 1536 });
  const isLaptopLarge = useMediaQuery({ minWidth: 1599 });
  const isLargeScreenLaptop = useMediaQuery({ minWidth: 1700 });

  const itemsPerPage = isLargeScreenLaptop ? 2 : isLaptopMedium ? 2 : isLaptop ? 2 : 10;

  const shortDescription = isLargeScreenLaptop ? 700 : isLaptopLarge ? 600 : isLaptopMedium ? 500 : isLaptop ? 400 : isTablet ? 400 : 700;

  // Handle Change Pagination
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  // Get Support Tickets
  const getSupportTickets = async (sfilter, emptySearch) => {
    setSupportTicketsDataLoading(true);

    let payload = {
      search: emptySearch ? "" : searchText,
      page: currentPage,
      records_no: 2,
      start_date: dateRange.startDate ? dateRange.startDate : null,
      end_date: dateRange.endDate ? dateRange.endDate : null,
    };

    if (selectedFilter !== null && selectedFilter !== "All") {
      payload.setting = selectedFilter;
    }

    if (sfilter !== "All") {
      payload.status = sfilter;
    }

    const res = await ApiServices.supportTickets
      .getSupportTickets(payload)
      .then((res) => {
        const { data, message } = res;
         
        if (data.code === 200) {
          setSupportTicketsData(data?.data?.data);
          setCurrentPage(data?.data?.current_page);
          setTotalPageCount(Math.ceil(data?.data?.total / data?.data?.per_page));

          if (data?.data?.data && data?.data?.data.length > 0) {
            const firstTicketId = data?.data?.data[0].id;
            handleTicketClick(firstTicketId);
          }
          setSupportTicketsDataLoading(false);
        }
      })
      .catch((err) => {
         
        setSupportTicketsDataLoading(false);
      });
  };

  // Get Ticket Detail
  const getSupportTicketDetails = (ticketId) => {
    setSupportTicketsDetailLoading(true);
    ApiServices.supportTickets
      .getSupportTicketsDetail(ticketId)
      .then((res) => {
        const { data, message } = res;
        if (data.code === 200) {
           
          setSelectedTicketDetails(data?.data);
          setSupportTicketsDetailLoading(false);
        }
      })
      .catch((err) => {
        setSupportTicketsDetailLoading(false);
      });
  };

  const getSupportTicketCounts = async () => {
    try {
      let payload = {
        start_date: dateRange.startDate ? dateRange.startDate : null,
        end_date: dateRange.endDate ? dateRange.endDate : null,
      };
      if (user?.user?.role === "setting_user_employee" || user?.user?.role === "setting_user") {
        payload.mode = "nursery";
      }

      // API call with dates
      const response = await ApiServices.supportTickets.getSupportTicketCounts(payload);

      // Process the response
      const responseData = response?.data;

      if (responseData && responseData.code === 200) {
        setOpenCounts(responseData?.data?.open_count);
        setCloseCounts(responseData?.data?.closed_count);
      } else {
        // Handle errors or no data
        setOpenCounts(0);
        setCloseCounts(0);
      }
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
      setOpenCounts(0);
      setCloseCounts(0);
    }
  };

  // Validations - Reply Text
  const validateReplayText = () => {
    if (!replayText.trim()) {
      setReplayTextError("Required");

      return false;
    }
    setReplayTextError("");
    return true;
  };

  // Update Ticket Status
  const updateTicketStatus = (status, callback) => {
    setBtnLoading(true);
    let id = selectedTicketDetails?.id;
    let payload = {
      status: status,
    };
    ApiServices.supportTickets
      .updateTicketStatus(id, payload)
      .then(({ data, message }) => {
        // callback(data);
        setBtnLoading(false);
        setShowMsgBox(false);
        getSupportTickets(selectedStatusOption?.value);
        getSupportTicketCounts();
        setSelectedReplyImages([]);
        setPreviewReplyImages([]);
        setReplayText("");
        setOpenCloseConfirmationModal({ open: false });
        setOpenTicketOpenConfirmationModal({ open: false });
      })
      .catch(({ response }) => {
        callback(response);
      });
  };

  // Handle Replay
  const handleReplay = () => {
    if (showMsgBox && replayText !== "") {
      const isReplayTextValid = validateReplayText();

      if (isReplayTextValid) {
        let id = selectedTicketDetails?.id;

        let formData = new FormData();
        formData.append("ticket_id", id);
        formData.append("response", replayText);
        selectedReplyImages.forEach((image, index) => {
          formData.append(`images[${index}]`, image);
        });
        setReplyLoading(true);

        ApiServices.supportTickets
          .updateSupportTicket(formData)
          .then((res) => {
            const { data, message } = res;

            if (data.code === 200) {
              setReplyLoading(false);

              getSupportTicketDetails(id);
              setShowMsgBox(false);
              setReplayText("");
              if (isChecked === true) {
                setReplyLoading(false);

                getSupportTicketDetails(id);
                setShowMsgBox(false);
                setReplayText("");
                updateTicketStatus("closed", (res) => {});
              } else {
                setShowMsgBox(false);
                getSupportTickets(selectedStatusOption?.value);
                setSelectedReplyImages([]);
                setPreviewReplyImages([]);
                setReplayText("");
              }
            }
          })
          .catch((err) => {
             
            setBtnLoading(false);
          });
      }
    } else {
      setShowMsgBox(true);
    }
  };

  // Close Reply Box
  const handleCloseReplayBox = () => {
    setShowMsgBox(false);
    setReplayText("");
    setPreviewReplyImages(null);
  };

  // Handle Search
  const handleSearch = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (searchText.trim() !== "") {
        await getSupportTickets();
        setCurrentPage(1);
      }
    }
  };

  // Use Effects

  useEffect(() => {
    setSelectedTicketId(supportTicketsData[0]?.id);
  }, [supportTicketsData]);

  useEffect(() => {
    getSupportTickets(selectedStatusOption?.value);
  }, [currentPage, dateRange, selectedFilter, selectedStatusOption]);

  useEffect(() => {
    // getSupportTicketDetails();
  }, []);

  useEffect(() => {
    getSupportTicketCounts();
  }, [dateRange]);

  // Lottie Option
  const emptyFolderAnimation = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };

  return (
    <>
      <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
        <div className="card min-h-[85vh] space-y-4">
          <div className="flex justify-between">
            <div className="relative">
              <Heading
                heading="Tickets"
                subheading="Details of open & closed tickets."
                icon
                handleRefresh={() => {
                  getSupportTicketCounts();
                  getSupportTickets();
                  getSupportTicketDetails(selectedTicketDetails?.id);
                }}
              />
            </div>
            <div className="flex items-center gap-x-3">
              {(user?.user?.permissions?.includes("support-ticket-create-new-ticket") || user?.user?.role === "setting_user") && (
                <Button title="New Ticket" btnSmall onClick={() => setOpenCreateTicketModal(true)} />
              )}
              <div className="relative flex w-36 items-center 3xl:w-auto">
                <div className="pointer-events-none absolute inset-y-0 left-0 z-10 flex items-center pl-4">
                  <MagnifyingGlassIcon className="h-5 w-5 text-primary-color-100" />
                </div>
                <input
                  type="search"
                  id="search"
                  name="search"
                  placeholder="Search..."
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                    if (e.target.value.trim() === "") {
                      getSupportTickets(selectedStatusOption?.value, true);
                    }
                  }}
                  onKeyDown={handleSearch}
                  className="focus:ring-primary-color block w-full rounded-full border border-grayColor200 px-4 py-2 pl-11 text-sm text-primary-color-100 focus:border-primary-color-100"
                />
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between gap-x-4 border-b pb-1">
            <div className="flex items-center gap-x-5">
              <div className="flex">
                <img src={images.OpenTicketIcon} alt="open_ticket_icon" />
                <p className="text-sm text-primary-color-100">
                  <span className="pl-2 pr-1 text-sm font-semibold">{openCounts}</span>Open
                </p>
              </div>
              <div className="flex">
                <img src={images.CloseTicketIcon} alt="close_ticket_icon" />
                <p className="text-sm text-primary-color-100">
                  <span className="pl-2 pr-1 text-sm font-semibold">{closeCounts}</span>Closed
                </p>
              </div>
            </div>
            <div className="flex items-center gap-x-4">
              <RangePicker setRangePicker={setDateRange} width="xl:w-76 xl:-mr-9 2xl:-mr-0 2xl:w-76" />
              <StatusFilter className="border-0" selected={selectedStatusOption} setSelected={setSelectedStatusOption} options={statusOption} />
            </div>
          </div>

          <div className="relative space-y-4 pt-4 pb-12 xl:pb-12 xl:pt-0">
            {supportTicketsDataLoading ? (
              <Skeleton count={itemsPerPage} height={200} />
            ) : supportTicketsData?.length > 0 ? (
              supportTicketsData?.map((item, index) => (
                <div key={index} onClick={() => handleTicketClick(item?.id)}>
                  <TicketDetail
                    activeColor={selectedTicketId === item.id ? "border-secondary" : "border-grayColor-200"}
                    heading={item?.title}
                    subheading={item?.description}
                    ticketNo={`#${item?.ticket_number}`}
                    status={item?.status}
                    date={moment.unix(item.unix_created_at).format("D MMM YYYY")}
                    dateWithTime={moment.unix(item.unix_created_at).format("D MMM YYYY h:mm A")}
                    companyIcon={mediaUrl + item?.user?.nursery[0]?.logo}
                    companyName={item?.user?.nursery[0]?.name}
                    comment={item?.response_count}
                  />
                </div>
              ))
            ) : (
              <div className="justify-cente flex h-[500px] items-center">
                <Lottie options={emptyFolderAnimation} height={200} width={200} />
              </div>
            )}
          </div>
          <div className="absolute bottom-4">
            <ReactPaginate
              breakLabel="..."
              pageRangeDisplayed={5}
              forcePage={currentPage - 1}
              marginPagesDisplayed={2}
              activeClassName="active"
              nextClassName="item next"
              renderOnZeroPageCount={null}
              breakClassName="item break-me "
              containerClassName="pagination"
              onPageChange={handlePageChange}
              previousClassName="item previous"
              pageClassName="item pagination-page "
              pageCount={totalPageCount}
              nextLabel={<ChevronRightIcon className="h-5 w-5" />}
              previousLabel={<ChevronLeftIcon className="h-5 w-5" />}
            />
          </div>
        </div>

        <div className="card min-h-[85vh]">
          {supportTicketsData?.length > 0 ? (
            <>
              {selectedTicketDetails ? (
                <>
                  <div className="space-y-3 border-b-2 border-grayColor200 pb-4">
                    <div className="">
                      <h2 className="text-lg font-semibold text-secondary-color 3xl:text-xl">{selectedTicketDetails?.title}</h2>
                      <span className="text-sm font-semibold text-grayColor400 3xl:text-base">{`#${selectedTicketDetails?.ticket_number || "0000"}`}</span>
                    </div>
                    <div className="flex items-center gap-x-2">
                      <Badge status={selectedTicketDetails?.status || "N/A"} />
                      <h2 className="text-xs text-primary-color-200 2xl:text-sm">
                        <span className="pr-1 font-medium">
                          <img
                            src={
                              selectedTicketDetails?.user?.nursery[0]?.logo
                                ? mediaUrl + "/" + selectedTicketDetails?.user?.nursery[0]?.logo
                                : images.placeholder
                            }
                            alt="Company"
                            className="inline-block h-6 w-6 rounded-full"
                          />{" "}
                          {selectedTicketDetails?.user?.nursery[0]?.name || "N/A"}
                        </span>
                        opened on
                        <span id="ticketdate" className="pl-1 font-medium">
                          {moment.unix(selectedTicketDetails?.created_at_unix).format("D MMM YYYY")}
                        </span>
                        <Tooltip
                          anchorSelect="#ticketdate"
                          id="ticketdate-tooltip"
                          offset={5}
                          className="border border-gray-100 !bg-white !text-primary-color-100 !opacity-100 shadow-card"
                          classNameArrow="hidden"
                          effect="solid"
                          place="bottom"
                        >
                          <span> {moment.unix(selectedTicketDetails?.created_at_unix).format("D MMM YYYY h:mm A")}</span>
                        </Tooltip>
                      </h2>
                    </div>
                    <div>
                      {/* {selectedTicketDetails?.description || "No Description Found"} */}
                      <p className="font-inter text-sm text-grayColor400">
                        {selectedTicketDetails?.description?.length > shortDescription ? (
                          <div
                            className="cursor-pointer"
                            onClick={() => setOpenReadMoreMessageModal({ open: true, data: selectedTicketDetails?.description })}
                          >
                            <EllipsisText text={selectedTicketDetails?.description} maxLength={shortDescription} />
                          </div>
                        ) : (
                          <EllipsisText text={selectedTicketDetails?.description} maxLength={shortDescription} />
                        )}
                      </p>
                    </div>
                    <div className="relative grid grid-cols-12 gap-x-20">
                      {selectedTicketDetails?.images && selectedTicketDetails.images.length > 0 ? (
                        selectedTicketDetails.images
                          .filter((image) => image !== null)
                          .map((image, index) => (
                            <div
                              key={index}
                              className={`relative mx-auto h-16 w-16 cursor-pointer rounded-xl border border-grayColor200`}
                              onClick={() => handleImageClick(index)}
                            >
                              {image.endsWith(".pdf") ? (
                                <div className="flex h-full w-full items-center justify-center rounded-2xl bg-gray-200">
                                  <span>
                                    <img src={images.PDF} className="h-16 w-16" alt="pdf-icon" />
                                  </span>
                                </div>
                              ) : (
                                <img src={ticketMediaUrl + "/" + image} className="h-full w-full rounded-2xl object-cover" alt={`Image ${index}`} />
                              )}
                              {index === 2 && selectedTicketDetails.images.length > 3 && (
                                <div className="absolute inset-0 flex items-center justify-center rounded-xl bg-black/50">
                                  <span className="text-xl font-bold text-white">{`+${selectedTicketDetails.images.length - 3}`}</span>
                                </div>
                              )}
                            </div>
                          ))
                      ) : (
                        <div>
                          <p>Images:</p>
                          <Lottie options={emptyFolderAnimation} height={100} width={100} />
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <Lottie options={emptyFolderAnimation} height={200} width={200} />
                  <p className="text-center text-base font-medium text-gray-400">Event Not Selected</p>
                </>
              )}

              <h2 className="py-3 text-xl font-semibold text-secondary-color">
                Comments <span>({selectedTicketDetails?.ticket_responses.length})</span>
              </h2>

              <div className="max-h-full space-y-3 overflow-y-auto pb-12 pr-3 xl:max-h-40 xl:pb-4 xxl:max-h-64 3xl:max-h-[41vh]">
                {supportTicketsDetailLoading ? (
                  <Skeleton count={1} height={300} />
                ) : selectedTicketDetails?.ticket_responses?.length > 0 ? (
                  selectedTicketDetails?.ticket_responses?.map((comment, index) => (
                    <SupportTicketComment
                      key={comment.id}
                      personImage={
                        comment?.user?.nursery?.length > 0 && comment.user.nursery[0]?.logo
                          ? mediaUrl + comment.user.nursery[0].logo
                          : comment?.user?.employee?.length > 0 && comment.user.employee[0]?.profile_image_url
                            ? employeeMediaUrl + comment.user.employee[0].profile_image_url
                            : images.placeholder
                      }
                      personName={
                        comment?.user?.nursery?.length > 0
                          ? comment.user.nursery[0]?.name
                          : comment?.user?.employee?.length > 0
                            ? comment.user.employee[0]?.name
                            : "Unknown"
                      }
                      personStatus={comment?.response_by_tag}
                      commentDate={moment.unix(comment?.created_at_unix).format("D MMM YYYY")}
                      tooltipDate={moment.unix(comment?.created_at_unix).format("D MMM YYYY h:mm A")}
                      commentMsg={comment?.response}
                      selectedTicketDetail={selectedTicketDetails}
                      commentImage={comment?.images}
                      commentIndex={index}
                    />
                  ))
                ) : (
                  <Lottie options={emptyFolderAnimation} height={200} width={200} />
                )}
                <div className="!-mb-2 flex w-full items-center justify-center">
                  {selectedTicketDetails?.closed_by && <div className={`h-[1px] w-full bg-grayColor200`}></div>}
                  <>
                    {selectedTicketDetails?.status === "Closed" && (
                      <div className="w-full">
                        <div className="ml-5 text-sm text-grayColor400">
                          <span>Closed by </span>
                          <span className="text-sm font-medium text-grayColor300">
                            {selectedTicketDetails?.closed_by?.employee.length > 0
                              ? selectedTicketDetails?.closed_by?.employee[0]?.name
                              : selectedTicketDetails?.closed_by?.nursery[0]?.name}
                          </span>

                          <span> at </span>
                          <span>{moment.unix(selectedTicketDetails?.updated_at_unix).format("D MMM YYYY h:mm A")}</span>
                        </div>
                      </div>
                    )}
                  </>
                </div>
              </div>

              <div
                className={`absolute bottom-0 left-0   flex w-full flex-col justify-end ${showMsgBox ? "rounded-20" : "rounded-b-20"}  bg-white px-6 py-3 shadow-card-bottom 3xl:py-4`}
              >
                {showMsgBox && (
                  <div className="w-full py-2">
                    <textarea
                      type="text"
                      rows={3}
                      value={replayText}
                      onChange={(e) => setReplayText(e.target.value)}
                      className="w-full rounded-lg border-0 outline-none placeholder:text-primary-color-100 "
                      placeholder="Enter your response..."
                    />
                    <div className={`flex items-center  ${previewReplyImages ? "justify-between" : "justify-end"}`}>
                      {previewReplyImages && (
                        <div className="flex flex-wrap gap-2">
                          {previewReplyImages.map((image, index) => (
                            <div key={index} className="relative h-12 w-12 cursor-pointer">
                              <div
                                className="absolute top-0 right-0 flex h-4 w-4 items-center rounded-full bg-white shadow-tool-tip"
                                onClick={() => handleCancelSelection(index)}
                              >
                                <XMarkIcon className="h-4 w-4 text-black" />
                              </div>
                              <img src={image} alt={`Selected Image ${index}`} className="h-full w-full rounded-sm" />
                            </div>
                          ))}
                        </div>
                      )}

                      <div className="flex flex-col gap-y-2">
                        {/* {showToolTip && <div className="p-2 bg-white rounded-lg text-primary-color-100 shadow-tool-tip">Upload Attachments</div>} */}

                        <div className="self-end">
                          <label htmlFor="attachments" id="attachment">
                            <img src={images.Attachments} alt="Attachments" style={{ cursor: "pointer" }} />
                          </label>
                          <input
                            type="file"
                            multiple
                            name="attachments"
                            id="attachments"
                            accept="image/*,.pdf"
                            style={{ display: "none" }}
                            onChange={handleReplyImageChange}
                          />
                        </div>
                        <Tooltip
                          anchorSelect="#attachment"
                          id="attachment-tooltip"
                          offset={5}
                          accept=".jpg, .jpeg, .png"
                          className="border border-gray-100 !bg-white !text-primary-color-100 !opacity-100 shadow-card"
                          classNameArrow="hidden"
                          effect="solid"
                          place="top-start"
                        >
                          {/* Tooltip Content */}
                          <span>Upload Attachments</span>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                )}
                {(user?.user?.permissions?.includes("support-ticket-respond-ticket") || user?.user?.role === "setting_user") && (
                  <div className={`flex items-center  ${showMsgBox ? "justify-between border-t-2 border-grayColor200 pt-3" : "justify-end"}`}>
                    {showMsgBox && (
                      <>
                        <div className="flex items-center gap-x-1">
                          <input
                            type="checkbox"
                            id="remember"
                            name="remember"
                            className="rounded-sm"
                            checked={isChecked}
                            onChange={() => setIsChecked(!isChecked)}
                          />
                          <label for="remember" className="label ps-2 font-medium text-primary-color-200">
                            Mark <span className="font-semibold">{`#${selectedTicketDetails?.ticket_number || "##"}`}</span> as{" "}
                            <span className="text-red-500">Closed</span>
                          </label>
                        </div>
                      </>
                    )}

                    {selectedTicketDetails?.status === "Closed" ? (
                      <button
                        className="rounded-full bg-accent px-12 py-2 text-sm text-white 3xl:text-base"
                        type="button"
                        onClick={() => setOpenTicketOpenConfirmationModal({ open: true })}
                      >
                        Re-open
                      </button>
                    ) : (
                      <>
                        <div>
                          {showMsgBox && (
                            <button
                              onClick={handleCloseReplayBox}
                              className="rounded-full px-12 py-2 text-sm text-secondary 3xl:text-base "
                              type="button"
                            >
                              Cancel
                            </button>
                          )}

                          {selectedTicketDetails?.status === "Open" && !showMsgBox && (
                            <button
                              onClick={() => setOpenCloseConfirmationModal({ open: true })}
                              className="rounded-full px-6 py-2 text-sm font-medium text-red-500 3xl:text-base "
                              type="button"
                            >
                              Close Ticket
                            </button>
                          )}
                          <button
                            onClick={handleReplay}
                            className="rounded-full bg-secondary px-12 py-2 text-sm text-white 3xl:text-base "
                            type="button"
                          >
                            {replyLoading ? <Spinner /> : "Reply"}
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="justify-cente flex h-full items-center">
                <Lottie options={emptyFolderAnimation} height={200} width={200} />
              </div>
            </>
          )}
        </div>
      </div>

      <ImagesModal
        imagespdf={selectedTicketDetails?.images?.map((imageUrl) => ({
          original: ticketMediaUrl + "/" + imageUrl,
          thumbnail: ticketMediaUrl + "/" + imageUrl,
        }))}
        images={selectedTicketDetails?.images?.map((imageUrl) => ({
          original: imageUrl.endsWith(".pdf") ? ticketMediaUrl + "/" + imageUrl : ticketMediaUrl + "/" + imageUrl,
          thumbnail: imageUrl.endsWith(".pdf") ? images.PDF : ticketMediaUrl + "/" + imageUrl,
        }))}
        isOpen={openImageModal.open}
        setIsOpen={(isOpen) => setOpenImageModal({ ...openImageModal, open: isOpen })}
        selectedImageIndex={selectedImageIndex}
      />

      {/* Create Ticket Modal */}
      <NotifyUserModal isOpen={openCreateTicketModal} setIsOpen={setOpenCreateTicketModal} createTicket onSuccess={getSupportTickets} />

      <ReadMoreModal
        data={openReadMoreMessageModal.data}
        isOpen={openReadMoreMessageModal.open}
        setIsOpen={(isOpen) => setOpenReadMoreMessageModal({ ...openReadMoreMessageModal, open: isOpen })}
        name="Support Ticket Description"
      />

      <ConfirmationModal
        isOpen={openCloseConfirmationModal.open}
        setIsOpen={(isOpen) => setOpenCloseConfirmationModal({ ...openCloseConfirmationModal, open: isOpen })}
        handleSubmit={() => updateTicketStatus("closed")}
        label="Close"
        labelTwo="the ticket"
      />

      <ConfirmationModal
        isOpen={openTicketOpenConfirmationModal.open}
        setIsOpen={(isOpen) => setOpenTicketOpenConfirmationModal({ ...openTicketOpenConfirmationModal, open: isOpen })}
        handleSubmit={() => updateTicketStatus("open")}
        label="Re-open"
        labelTwo="the ticket"
      />
    </>
  );
};

export default SupportTicket;
