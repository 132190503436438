import Spinner from "./Spinner";
import { Fragment } from "react";
import React, { useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useLoggedContext } from "../context/provider";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { useLocation, useParams } from "react-router-dom";
import ApiServices from "../api/services";
import { useEffect } from "react";

export default function ActionLogModal({ isOpen, setIsOpen, data , editMode, onSuccess }) {
    // react router hook
    const location = useLocation();
    const { id: paramId } = useParams();
    const { employeeDetail } = location.state || {};
    const employeeId = employeeDetail ? employeeDetail?.id : null;

  // Context
  const { btnLoading, setBtnLoading, openSuccessModal } = useLoggedContext();

  const [actionLog, setActionLog] = useState("");
  const [message, setMessage] = useState("");
  const backgroundColor = "secondary";

  // Close Modal
  function closeModal() {
    setIsOpen(false);
    setActionLog("");
    setBtnLoading(false);
    setMessage("")
  }

  const getButtonStyles = (buttonType) => {
    const baseStyles = "inline-flex  justify-center rounded-full px-8 py-2.5 text-base font-medium transition-all duration-300 focus:outline-none";
    switch (buttonType) {
      case "Add Action":
        return `bg-${backgroundColor} text-white ${baseStyles} ${btnLoading ? "" : "hover:opacity-75"}`;
      case "No":
        return `border border-${backgroundColor} text-${backgroundColor} hover:bg-${backgroundColor} ${baseStyles} hover:text-white`;
      default:
        return "";
    }
  };

  // handle reason detail
  const handleActionLog = (event) => {
    setActionLog(event.target.value);
  };

  const handleSubmit = () => {

    if (!actionLog) {
      setMessage("Please add note")
      return
    }
    else{
      setBtnLoading(true);
      const formData = new FormData();
      formData.append("uid", employeeId ? employeeId : paramId);
      formData.append("note",actionLog);
  
      ApiServices.employees
        .addActionLogByID(formData)
        .then((res) => {
          if (res?.data?.code === 200) {
            setMessage("")
            setBtnLoading(false);
            openSuccessModal({
              title: "Success!",
              message: "Employee Log has been Added!",
              onPress: (close) => {
                close();
                onSuccess();
              },
            });
            setActionLog("");
            setIsOpen(false);
            setBtnLoading(false);
  
          }
        })
        .catch(({ response }) => {
           
        });
    }
  };

  const handleUpdate = ()=>{
    if (!actionLog) {
      setMessage("Please add note")
      return
    }
    else{
      setBtnLoading(true);
      const formData = new FormData();
      formData.append("id", data?.id);
      formData.append("note",actionLog);
  
      ApiServices.employees
        .updateActionLog(formData)
        .then((res) => {
          if (res?.data?.code === 200) {
            setBtnLoading(false);
            openSuccessModal({
              title: "Success!",
              message: "Employee Log has been Updated!",
              onPress: (close) => {
                close();
                onSuccess();
              },
            });
            setActionLog("");
            setIsOpen(false);
            setBtnLoading(false);
  
          }
        })
        .catch(({ response }) => {
           
        });
    }
  }


   // useEffect
   useEffect(() => {
    if (editMode) {
       setActionLog(data?.note);
    } else {
      setActionLog("");
      setMessage("")
    }
  }, [editMode, isOpen]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-75"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-75"
              >
                <Dialog.Panel className="w-full max-w-xl p-8 overflow-hidden text-center align-middle transition-all bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="flex justify-between text-lg font-medium leading-7 text-left font-inter text-secondary-color 3xl:text-xl"
                  >
                    Add Action Logs
                    <div onClick={closeModal}>
                      <XMarkIcon className="cursor-pointer h-7 w-7 text-secondary-color" />
                    </div>
                  </Dialog.Title>
                  <h4 className="text-sm text-left text-secondary">
                    Action Log for your daily <span className="font-semibold">TODOS</span>{" "}
                  </h4>

                  <div className="py-5">
                    <textarea
                      name="actionLog"
                      id="actionLog"
                      rows="7"
                      value={actionLog}
                      required
                      className="w-full rounded-lg border-grayColor200 placeholder:text-sm placeholder:text-grayColor200"
                      placeholder="Write here..."
                      onChange={handleActionLog}
                    ></textarea>
                  </div>

{/* Message */}
<div className="flex justify-center">
  <p className="text-sm text-red-500 ">{message}</p>
</div>
                  <div className="flex justify-end mt-2">
                    <button type="button" className={getButtonStyles("Add Action")} onClick={editMode? handleUpdate : handleSubmit}>
                      {btnLoading ? <Spinner /> : editMode? "Update Action": "Add Action"}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
