import moment from "moment";
import Button from "../Button";
import { useEffect } from "react";
import InputField from "../InputField";
import { Fragment, useState } from "react";
import FilterSearch from "../FilterSearch";
import ApiServices from "../../api/services";
import { images } from "../../assets/styles/Images";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { employeeMediaUrl } from "../../utils/config";
import { Dialog, Transition } from "@headlessui/react";
import { useLoggedContext } from "../../context/provider";
import { toUTCUnixTimestamp, validateForm } from "../../helperFunctions/helper";
import ComboBoxSelect from "../ComboBoxSelect";

const dummyDepartment = [
  { label: "Computer Science", value: "computer-science" },
  { label: "Business Administration", value: "business-administration" },
  { label: "Environmental Studies", value: "environmental-studies" },
  { label: "Psychology", value: "psychology" },
];

const dummyCampuses = [
  { label: "Maplewood", value: "maplewood" },
  { label: "Kingsford", value: "kingsford" },
  { label: "Highland Hills ", value: "highland-hills " },
  { label: "Greenfield Academy", value: "greenfield-academy" },
];

const AddManuallyModal = ({ isOpen, setIsOpen, onSuccess, data, editMode }) => {
  // Context
  const { btnLoading, setBtnLoading, openSuccessModal } = useLoggedContext();

  const [errors, setErrors] = useState({});
  const [imagePreview, setImagePreview] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedFileError, setSelectedFileError] = useState("");
  const [selectedCampus, setSelectedCampus] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  console.log("🚀 ~ AddManuallyModal ~ selectedDepartment:", selectedDepartment);
  const [message, setMessage] = useState("");
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    dateOfBirth: "",
    password: "",
    employeeId: "",
    qualification: "",
    joiningDate: "",
    workDaysPerWeek: "",
    jobTitle: "",
    room: "",
    keyChildren: "",
    mentalHealthCondition: "",
    medication: "",
    emotionalTriggers: "",
  });

  // Destructure Values From formData
  const {
    fullName,
    email,
    phoneNumber,
    dateOfBirth,
    password,
    employeeId,
    qualification,
    joiningDate,
    workDaysPerWeek,
    jobTitle,
    room,
    keyChildren,
    mentalHealthCondition,
    medication,
    emotionalTriggers,
  } = formData;
  const [departmentError, setDepartmentError] = useState("");
  // Input Values Handler
  const handleChange = (event) => {
    const { name, value } = event.target;
    const trimmedValue = value.trimStart();

    setErrors({ ...errors, [name]: "" });
    setFormData({ ...formData, [name]: trimmedValue });
  };

  // Image Upload
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
      setSelectedFileError("");
      setImagePreview(URL.createObjectURL(file));
    }
  };

  // Image delete
  const handleImageDelete = () => {
    setSelectedImage(null);
    setImagePreview(null);
  };

  // Submit Function
  const handleSubmit = async (event) => {
    event.preventDefault();

    const errors = validateForm(formData);
    const {
      fullName,
      email,
      phoneNumber,
      dateOfBirth,
      password,
      // employeeId,
      qualification,
      joiningDate,
      workDaysPerWeek,
      jobTitle,
      // room,
      // keyChildren,
      mentalHealthCondition,
      medication,
      emotionalTriggers,
    } = errors;
    setErrors(errors);

    if (
      !fullName &&
      !email &&
      !phoneNumber &&
      !dateOfBirth &&
      !password &&
      !qualification &&
      !joiningDate &&
      !workDaysPerWeek &&
      !jobTitle &&
      // !room &&
      // !keyChildren &&
      !mentalHealthCondition &&
      !medication &&
      !emotionalTriggers
    ) {
      console.log("🚀 ~ handleSubmit ~ selectedCampus:", selectedCampus);
      if (selectedCampus?.value === null || selectedCampus?.value === undefined) {
        setDepartmentError("Please select a site.");
        return;
      }
      // } else if (selectedCampus && !selectedDepartment) {
      //   setDepartmentError("Please select a department.");
      //   return;
      // }
      // setDepartmentError("");

      // If No Error
      const payload = new FormData();
      let joiningDate = toUTCUnixTimestamp(formData.joiningDate);
      let dateOfBirth = toUTCUnixTimestamp(formData.dateOfBirth);

      // Checking if selectedImage is a valid File object
      if (selectedImage instanceof File) {
        payload.append("profile_image_url", selectedImage);
      }

      payload.append("name", formData.fullName);
      if (selectedDepartment && selectedDepartment.value) {
        payload.append("department_id", selectedDepartment.value);
      }

      payload.append("campus_id", selectedCampus?.value);

      payload.append("email", formData.email);
      payload.append("phone_number", formData.phoneNumber);
      payload.append("date_of_birth", dateOfBirth);
      payload.append("password", formData.password);
      payload.append("emp_id", formData.employeeId);
      payload.append("qualification", formData.qualification);
      payload.append("unix_joining_date", joiningDate);
      payload.append("shift_time", formData.workDaysPerWeek);
      payload.append("job_title", formData.jobTitle);
      payload.append("room_name", formData.room);
      payload.append("key_children", formData.keyChildren);
      payload.append("mental_health", formData.mentalHealthCondition);
      payload.append("medication", formData.medication);
      payload.append("emotional_trigger", formData.emotionalTriggers);
      payload.append("mode", editMode ? "update_employee" : "setting_user_employee");

      setBtnLoading(true);
      setMessage("");
      if (editMode) {
        // update function
        ApiServices.employees
          .updateEmployee(data?.id, payload)
          .then((res) => {
            const { data, message } = res;

            if (data.code === 200) {
              setBtnLoading(false);
              onSuccess();
              closeModal();
              openSuccessModal({
                title: "Success!",
                message: "Employee updated successfully.",
                onPress: (close) => {
                  close();
                  onSuccess();
                },
              });
            }
          })
          .catch((err) => {
            setBtnLoading(false);
            setMessage(err.response.data.message);
          });
      } else {
        ApiServices.employees
          .addEmployee(payload)
          .then((res) => {
            const { data, message } = res;

            if (data.code === 200) {
              setBtnLoading(false);
              onSuccess();
              closeModal();
              openSuccessModal({
                title: "Success!",
                message: "Employee added successfully.",
                onPress: (close) => {
                  close();
                  onSuccess();
                },
              });
            }
          })
          .catch((err) => {
            setBtnLoading(false);
            setMessage(err.response.data.message);
          });
      }
    }
  };

  const getEmployeeIds = async () => {
    try {
      const result = await ApiServices.employees.getEmployeeIds();

      if (result.data.code === 200) {
        setFormData({ ...formData, employeeId: result?.data?.data });
      }
    } catch (error) {}
  };

  // Close Modal
  function closeModal() {
    setIsOpen(false);
    setErrors({});
    setSelectedImage(null);
    setImagePreview(null);
    setFormData({
      fullName: "",
      email: "",
      phoneNumber: "",
      dateOfBirth: "",
      password: "",
      employeeId: "",
      qualification: "",
      joiningDate: "",
      workDaysPerWeek: "",
      jobTitle: "",
      room: "",
      keyChildren: "",
      mentalHealthCondition: "",
      medication: "",
      emotionalTriggers: "",
    });
    setSelectedCampus(null);
    setSelectedDepartment(null);
    setBtnLoading(false);
    setMessage("");
    setDepartmentError("");
  }

  useEffect(() => {
    if (isOpen && editMode && data) {
      const {
        name,
        phone_number,
        date_of_birth,
        emp_id,
        qualification,
        unix_joining_date,
        job_title,
        room_name,
        key_children,
        mental_health,
        shift_time,
        medication,
        emotional_trigger,
        profile_image_url,
      } = data.employee[0];

      const selectedDepartment = data?.employee?.map((item) => ({
        value: item?.department?.id,
        label: item?.department?.name,
      }))[0]; // Select the first object

      const selectedCampus = data?.employee?.map((item) => ({
        value: item?.campus?.id,
        label: item?.campus?.name,
      }))[0]; // Select the first object

      setSelectedDepartment(selectedDepartment);
      setSelectedCampus(selectedCampus);

      setSelectedImage(profile_image_url ? profile_image_url : null);
      setImagePreview(profile_image_url ? employeeMediaUrl + profile_image_url : null);
      setFormData({
        fullName: name || "",
        email: data?.email || "",
        phoneNumber: phone_number || "",
        dateOfBirth: date_of_birth ? moment.unix(date_of_birth).format("YYYY-MM-DD") : "",
        employeeId: emp_id || "",
        qualification: qualification || "",
        joiningDate: unix_joining_date ? moment.unix(unix_joining_date).format("YYYY-MM-DD") : "",
        workDaysPerWeek: shift_time || "",
        jobTitle: job_title || "",
        room: room_name || "",
        keyChildren: key_children || "",
        mentalHealthCondition: mental_health || "",
        medication: medication || "",
        emotionalTriggers: emotional_trigger || "",
      });
    } else {
      getEmployeeIds();
    }
  }, [isOpen, editMode, data]);

  // useEffect(() => {

  // }, [isOpen]);

  const [allCampuses, setAllCampuses] = useState([]);
  const [allDepartments, setAllDepartments] = useState([]);
  console.log("🚀 ~ allDepartments:", allDepartments);

  const getCampusList = () => {
    ApiServices.campus
      .getCampuses()
      .then((res) => {
        const { data, message } = res;

        if (data.code === 200) {
          let settingsData = data?.data?.data?.map((item) => ({
            label: item.name,
            value: item.id,
          }));

          // settingsData = [, ...settingsData];

          setAllCampuses(settingsData);
        }
      })
      .catch((err) => {});
  };

  // const getDepartmentList = () => {
  //   let payload = {
  //     mode: 1,

  //   };

  //   ApiServices.department
  //     .getDepartments(payload)
  //     .then((res) => {
  //       const { data, message } = res;
  //       if (data.code === 200) {
  //
  //         let settingsData = data?.data?.map((item) => ({
  //           label: item.name,
  //           value: item.id,
  //         }));

  //         settingsData = [{ label: "All", value: "All" }, ...settingsData];

  //         setAllDepartments(settingsData);
  //       }
  //     })
  //     .catch((err) => {});
  // };
  const getDepartmentList = () => {
    const campusValue = selectedCampus?.value;
    if (campusValue && campusValue !== "All") {
      let payload = {
        mode: 1,
        campus_id: campusValue,
      };

      ApiServices.department
        .getDepartments(payload)
        .then((res) => {
          const { data, message } = res;
          if (data.code === 200) {
            let settingsData = data?.data?.map((item) => ({
              label: item.name,
              value: item.id,
            }));

            // settingsData = [...settingsData];

            setAllDepartments(settingsData);
          }
        })
        .catch((err) => {
          // Handle error here, if necessary
        });
    }
  };
  useEffect(() => {
    getDepartmentList();
  }, [selectedCampus]);
  useEffect(() => {
    getCampusList();
  }, []);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-75"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-75"
              >
                <Dialog.Panel
                  className={`min-w-[50%] max-w-5xl overflow-hidden rounded-2xl bg-white p-8 align-middle shadow-xl transition-all 3xl:max-w-7xl`}
                >
                  <div className="flex items-center justify-between">
                    <Dialog.Title as="h3" className="font-poppins text-lg font-semibold leading-7 text-secondary-color">
                      {editMode ? "Update Employee" : "Add New Employee"}
                    </Dialog.Title>
                    <XMarkIcon onClick={closeModal} className="h-8 w-8 cursor-pointer text-primary-color-200" />
                  </div>

                  <div className="">
                    <div className="w-full">
                      <form>
                        {/* Personal Information */}
                        <div className="flex items-end gap-x-8 3xl:gap-x-12">
                          <div className="w-1/5">
                            <div className="group relative h-40 w-40 rounded-full 3xl:h-52 3xl:w-52">
                              {imagePreview ? (
                                <>
                                  <img src={imagePreview} alt="selected img" className="h-full w-full rounded-full object-cover" />
                                  <div
                                    className="font-poppins absolute top-0 right-0 flex h-6 w-6 cursor-pointer items-center justify-center rounded-full bg-red-500 p-2 text-sm text-white opacity-0 transition-all duration-200 group-hover:opacity-100"
                                    onClick={handleImageDelete}
                                  >
                                    X
                                  </div>
                                </>
                              ) : (
                                <>
                                  <label
                                    htmlFor="image-upload"
                                    className="absolute top-0 right-0 flex h-full w-full cursor-pointer items-center justify-center"
                                  >
                                    <img src={images.fileImage} alt="add img" className="h-full w-full object-contain" />
                                  </label>
                                  <div className="absolute -bottom-4 -right-5">
                                    <input type="file" accept="image/*" id="image-upload" className="hidden" onChange={handleImageUpload} />
                                    <label htmlFor="image-upload">
                                      <img src={images.Camera} alt="Camera Icon" className="cursor-pointer" />
                                    </label>
                                  </div>
                                </>
                              )}
                            </div>

                            <div>{selectedFileError && <p className="col-span-2 mt-3 text-sm text-red-500">{selectedFileError}</p>}</div>
                          </div>

                          <div className="w-4/5 pr-4">
                            <h2 className="mb-8 text-base font-medium text-secondary-color">Personal Information</h2>
                            <div className="relative grid grid-cols-3 gap-4 3xl:gap-6">
                              <InputField
                                type="text"
                                name="fullName"
                                label="Full Name"
                                value={fullName}
                                errorOnBottom
                                maxLength={25}
                                error={errors.fullName}
                                onChange={handleChange}
                                placeholder="Enter full name"
                              />

                              <InputField
                                type="text"
                                name="phoneNumber"
                                label="Phone Number"
                                value={phoneNumber}
                                errorOnBottom
                                error={errors.phoneNumber}
                                onChange={handleChange}
                                placeholder="Enter phone number"
                              />

                              <InputField
                                type="date"
                                name="dateOfBirth"
                                label="Date of Birth"
                                value={dateOfBirth}
                                errorOnBottom
                                error={errors.dateOfBirth}
                                onChange={handleChange}
                                placeholder="Enter Date of birth"
                              />

                              <InputField
                                type="email"
                                name="email"
                                label="Email"
                                value={email}
                                errorOnBottom
                                error={errors.email}
                                className={`${editMode && "bg-gray-100"}`}
                                disabled={editMode && true}
                                onChange={handleChange}
                                placeholder="Enter email address"
                              />

                              <InputField
                                errorOnBottom
                                type="password"
                                name="password"
                                label="Password"
                                value={password}
                                error={errors.password}
                                onChange={handleChange}
                                className={`${editMode && "bg-gray-100"}`}
                                disabled={editMode && true}
                                placeholder="Enter password"
                              />
                            </div>
                          </div>
                        </div>
                        {/* End Personal Information */}

                        {/* Border Bottom */}
                        <hr className="mx-6 my-12" />
                        {/* End Border Bottom */}

                        <div className="flex gap-x-4">
                          {/* Work Information */}
                          <div className="w-1/2 border-r pr-4">
                            <h2 className="mb-8 text-base font-medium text-secondary-color">Work Information</h2>
                            <div className="relative grid grid-flow-row grid-cols-2 gap-4 3xl:gap-6">
                              <InputField
                                type="text"
                                name="employeeId"
                                label="Employee ID"
                                value={employeeId}
                                errorOnBottom
                                // error={errors.employeeId}
                                onChange={handleChange}
                                placeholder="Enter Employee ID"
                                disabled={true}
                              />

                              <InputField
                                type="text"
                                name="qualification"
                                label="Qualification"
                                value={qualification}
                                errorOnBottom
                                error={errors.qualification}
                                onChange={handleChange}
                                placeholder="Enter Qualification"
                              />

                              <InputField
                                type="date"
                                name="joiningDate"
                                label="Joining Date"
                                value={joiningDate}
                                errorOnBottom
                                error={errors.joiningDate}
                                onChange={handleChange}
                                placeholder="Enter Joining Date"
                              />

                              <InputField
                                type="number"
                                name="workDaysPerWeek"
                                label="Work Days Per Week"
                                value={workDaysPerWeek}
                                errorOnBottom
                                error={errors.workDaysPerWeek}
                                onChange={handleChange}
                                placeholder="Enter Work Days Per Week"
                              />

                              <InputField
                                type="text"
                                name="jobTitle"
                                label="Job Title"
                                value={jobTitle}
                                errorOnBottom
                                error={errors.jobTitle}
                                onChange={handleChange}
                                placeholder="Enter Job Title"
                              />

                              {/* <InputField
                                type="text"
                                name="room"
                                label="Room"
                                value={room}
                                errorOnBottom
                                error={errors.room}
                                onChange={handleChange}
                                placeholder="Enter Room"
                              /> */}

                              <div className="space-y-2">
                                <h2 className="pl-2 text-sm font-medium text-primary-color-100">Site</h2>
                                <ComboBoxSelect
                                  selected={selectedCampus}
                                  setSelected={(e) => {
                                    setSelectedCampus(e);
                                    getDepartmentList(e.value);
                                    setDepartmentError("");
                                    setSelectedDepartment(null);
                                  }}
                                  options={allCampuses}
                                  placeholder="Select Site"
                                />
                              </div>

                              <div className="space-y-2">
                                <h2 className="pl-2 text-sm font-medium text-primary-color-100">Department</h2>

                                <ComboBoxSelect
                                  selected={selectedDepartment}
                                  setSelected={setSelectedDepartment}
                                  options={allDepartments}
                                  isDisabled={selectedCampus === null || selectedCampus === undefined ? true : false}
                                  placeholder="Select Department"
                                />
                              </div>

                              {/* <InputField
                                type="number"
                                name="keyChildren"
                                label="Key Children"
                                value={keyChildren}
                                errorOnBottom
                                error={errors.keyChildren}
                                onChange={handleChange}
                                placeholder="Enter Key Children"
                              /> */}
                            </div>
                          </div>
                          {/* End Work Information */}

                          {/* Well-being Information */}
                          <div className="w-1/2 pr-4">
                            <h2 className="mb-8 text-base font-medium text-secondary-color">Well-being Information</h2>
                            <div className="relative grid grid-cols-2 gap-4 3xl:gap-6">
                              <InputField
                                type="text"
                                name="mentalHealthCondition"
                                label="Mental Health Condition"
                                value={mentalHealthCondition}
                                errorOnBottom
                                error={errors.mentalHealthCondition}
                                onChange={handleChange}
                                placeholder="Enter Mental Health Condition"
                              />

                              <InputField
                                type="text"
                                name="medication"
                                label="Medication"
                                value={medication}
                                errorOnBottom
                                error={errors.medication}
                                onChange={handleChange}
                                placeholder="Enter Medication address"
                              />

                              <InputField
                                type="text"
                                name="emotionalTriggers"
                                label="Emotional Triggers"
                                value={emotionalTriggers}
                                errorOnBottom
                                error={errors.emotionalTriggers}
                                onChange={handleChange}
                                placeholder="Enter Emotional Triggers"
                              />
                            </div>
                          </div>
                          {/* End Well-being Information */}
                        </div>
                      </form>
                    </div>

                    <div className="mt-6 text-center">
                      <p className="text-sm text-red-500">{message}</p>
                      {departmentError && <p className="text-sm text-red-500">{departmentError}</p>}
                    </div>
                    {/* Submit Button */}
                    <div className="mt-8 flex items-center justify-end gap-x-8">
                      <h3 onClick={closeModal} className="cursor-pointer text-lg font-medium text-secondary">
                        Cancel
                      </h3>
                      <Button loading={btnLoading} title={editMode ? "Update Employee" : "Add Employee"} onClick={handleSubmit} />
                    </div>
                    {/* End Submit Button */}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default AddManuallyModal;
