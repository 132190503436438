import { Fragment, useState } from "react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { Combobox, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";

const ComboBoxSelect = ({ options, selected, setSelected, isDisabled, placeholder }) => {
  console.log("🚀 ~ ComboBoxSelect ~ options:", options);
  const [query, setQuery] = useState("");

  const filteredBatchNo =
    query === ""
      ? options
      : options?.filter((BatchNo) => BatchNo?.label?.toLowerCase().replace(/\s+/g, "").includes(query.toLowerCase().replace(/\s+/g, "")));

  console.log("🚀 ~ ComboBoxSelect ~ filteredBatchNo:", filteredBatchNo.length);
  return (
    <div className="w-full">
      <Combobox value={selected} onChange={setSelected} disabled={isDisabled}>
        <div className="relative mt-1">
          <div
            className={`  relative h-12 w-full cursor-default overflow-hidden  rounded-full border border-primary-color-300 bg-white 
           pt-1.5 text-left text-secondary-color focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm ${
             isDisabled ? "h-12 cursor-not-allowed border-gray-300 bg-gray-200 text-gray-400" : ""
           }`}
          >
            <Combobox.Input
              className={`w-full border-none py-2 pl-2 pr-10 text-sm leading-5 text-secondary-color  focus:ring-0 ${
                isDisabled ? "cursor-not-allowed border-gray-300 bg-gray-200 text-gray-400" : ""
              }`}
              displayValue={(BatchNo) => BatchNo?.label}
              onChange={(event) => setQuery(event.target.value)}
              placeholder={placeholder}
              disabled={isDisabled}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-4">
              <ChevronDownIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
            </Combobox.Button>
          </div>
          {!isDisabled && (
            <Transition
              as={Fragment}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
              afterLeave={() => setQuery("")}
            >
              <Combobox.Options className="absolute mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {filteredBatchNo?.length === 0 || query !== "" ? (
                  <div className="relative cursor-pointer select-none px-4 py-2 text-gray-700">Nothing found.</div>
                ) : (
                  filteredBatchNo?.map((BatchNo) => (
                    <Combobox.Option
                      key={BatchNo.id}
                      className={({ active }) =>
                        `relative cursor-pointer select-none py-2 px-2 ${active ? "bg-gray-200 text-secondary" : "text-primary-color-100"}`
                      }
                      value={BatchNo}
                    >
                      {({ selected, active }) => (
                        <>
                          <span className={`block  ${selected ? "font-medium" : "font-normal"}`}>{BatchNo.label}</span>
                          {/* {selected ? (
                            <span className={`absolute inset-y-0 left-0 flex items-center pl-3 text-green-600`}>
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null} */}
                        </>
                      )}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </Transition>
          )}
        </div>
      </Combobox>
    </div>
  );
};

export default ComboBoxSelect;
