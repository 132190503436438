import { useState, createContext, useContext, useEffect, useLayoutEffect } from "react";

// ===================== React Cookies ======================== //
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { superAdmin } from "../routes/routesDefined";
import SuccessModal from "../components/SuccessModal";
import SubscriptionPlansModal from "../components/SubscriptionPlansModal";
import ChangeCardModal from "../components/ChangeCardModal";

const LocalContext = createContext();

export const useLoggedContext = () => {
  return useContext(LocalContext);
};

export const LocalProvider = (props) => {
  const [isLoggedin, setIsLoggedin] = useState(null);
  const [employeesListing, setEmpoyeesListing] = useState();
  const [showSidebar, setShowSidebar] = useState("");
  const [cookies, setCookies] = useCookies("token", "");
  const [sessionExpire, setSessionExpire] = useState(false);
  const [adminRole, setAdminRole] = useState("");
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [openModal, setOpenModal] = useState({ open: false, message: "", title: "" });
  const [user, setUser] = useState(() => JSON.parse(localStorage.getItem("userData")) || {});

  const [openSubscriptionPlansModal, setOpenSubscriptionPlansModal] = useState({ open: user?.user?.subscribed === 0 ? true : false });
  const [subPlanId, setSubPlanId] = useState("");

  // Open Success Modal
  const openSuccessModal = ({ ...props }) => {
    setOpenModal({ ...openModal, ...props, open: true });
  };

  // Close Success Modal
  const closeSuccessModel = () => {
    setOpenModal({ ...openModal, open: false });
  };

  // Update User
  const updateUser = (newUserData) => {
    localStorage.setItem("userData", JSON.stringify(newUserData));
    setUser(newUserData);
  };

  useEffect(() => {
    let issubscribed = user?.user?.subscribed === 0 ? true : false;
    setOpenSubscriptionPlansModal({ open: issubscribed });
  }, [user]);

  useEffect(() => {
    const status = JSON.parse(localStorage.getItem("loginStatus")) ? JSON.parse(localStorage.getItem("loginStatus")) : "";
    if (status === null || status === "undefined") {
      localStorage.setItem("loginStatus", JSON.stringify(false));
    }
    setIsLoggedin(status);
    expireSession();
    const interval = setInterval(() => {
      expireSession();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useLayoutEffect(() => {
    const superAdmin = localStorage.getItem("superAdmin");
    setAdminRole(superAdmin);
  }, []);

  const login = (role) => {
    var now = new Date().getTime();
    localStorage.setItem("setupTime", now);
    localStorage.setItem("loginStatus", JSON.stringify(true));
    setIsLoggedin(true);
  };

  const logOut = () => {
    localStorage.setItem("loginStatus", JSON.stringify(false));
    setIsLoggedin(false);
    localStorage.clear();
    setCookies("token", "");
    setAdminRole(false);
  };

  // ======================= Session Expire Function ====================== //
  const expireSession = () => {
    var hours = 2;
    var now = new Date().getTime();
    var setupTime = localStorage.getItem("setupTime");
    if (setupTime != null) {
      if (now - setupTime > hours * 60 * 60 * 1000) {
        setSessionExpire(true);
      }
    }
  };

  const adminType = (state) => {
    localStorage.setItem("superAdmin", true);
    setAdminRole(state);
  };

  return (
    <LocalContext.Provider
      value={{
        isLoggedin,
        login,
        logOut,
        showSidebar,
        setShowSidebar,
        cookies,
        setCookies,
        sessionExpire,
        setSessionExpire,
        adminRole,
        openModal,
        setOpenModal,
        openSuccessModal,
        closeSuccessModel,
        showBackDrop,
        adminType,
        setShowBackDrop,
        employeesListing,
        btnLoading,
        setBtnLoading,
        loading,
        setLoading,
        user,
        updateUser,
        subPlanId,
        setOpenSubscriptionPlansModal,
      }}
    >
      {props.children && props.children}

      <SubscriptionPlansModal
        isOpen={openSubscriptionPlansModal.open}
        setIsOpen={(open) => setOpenSubscriptionPlansModal((prev) => ({ ...prev, open }))}
        fromProvider
        setSubPlanId={setSubPlanId}
      />

      <SuccessModal />
    </LocalContext.Provider>
  );
};
