import Button from "./Button";
import { useEffect } from "react";
import InputField from "./InputField";
import ApiServices from "../api/services";
import { Fragment, useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Dialog, Transition } from "@headlessui/react";
import { useLoggedContext } from "../context/provider";
import { useRef } from "react";

const AddCampus = ({ isOpen, setIsOpen, submit, data, editMode, onSuccess, setSelectedCampusIndex, api }) => {
  // Context
  const { btnLoading, setBtnLoading, openSuccessModal } = useLoggedContext();

  // Close Modal
  function closeModal() {
    setNewItem("");
    setSelectedItems([]);
    setDropdownOpen(false);
    setShowAddInput(false);
    setIsOpen(false);
  }

  const [items, setItems] = useState([]);
  const [newItem, setNewItem] = useState("");
  const [loading, setLoading] = useState(false);
  const [campusName, setCampusName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [showAddInput, setShowAddInput] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleSelect = (item) => {
    if (!selectedItems.includes(item)) {
      setSelectedItems([...selectedItems, item]);
      setItems(items.filter((i) => i !== item));
      setErrorMessage("");
    } else if (selectedItems.includes(item)) {
      setErrorMessage("Already Selected");
    }
    setDropdownOpen(false);
  };

  const handleRemove = (event, item) => {
    event.stopPropagation();
    event.preventDefault();
    setItems([...items, item]);
    setSelectedItems(selectedItems.filter((i) => i !== item));
    setErrorMessage("");
  };

  const toggleAddInput = () => {
    setShowAddInput(true);
  };

  const handleAddItem = () => {
    if (newItem && !items.includes(newItem) && !selectedItems.includes(newItem)) {
      setItems([...items, newItem]);
      setSelectedItems([...selectedItems, newItem]);
      setNewItem("");
      setShowAddInput(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSelectedCampusIndex(false);
    const department_name = [];
    const department_ids = [];
    if (Array.isArray(selectedItems)) {
      selectedItems?.forEach((item) => {
        if (typeof item === "string") {
          department_name.push(item);
        } else if (typeof item === "object" && item.label && item.value !== undefined) {
          department_ids.push(item.value);
        }
      });
    } else {
      console.error("selectedItems is not an array");
    }

    let payload = {
      name: campusName,
      department_name: department_name,
      department_ids: department_ids,
    };

    // Check if editMode is true
    if (editMode) {
      setLoading(true);
      ApiServices.campus
        .updateCampus(data.id, payload)
        .then((res) => {
          const { data, message } = res;

          if (data.code === 200) {
            api();
            setIsOpen(false);
            setLoading(false);
            setErrorMessage("");
            openSuccessModal({
              title: "Success!",
              message: "Campus updated successfully.",
              onPress: (close) => {
                close();
              },
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          setErrorMessage(err.response.data.message);
        });
    } else {
      setLoading(true);
      ApiServices.campus
        .addCampus(payload)
        .then((res) => {
          const { data, message } = res;

          if (data.code === 200) {
            api();
            setLoading(false);
            setIsOpen(false);
            setErrorMessage("");
            openSuccessModal({
              title: "Success!",
              message: "Campus added successfully.",
              onPress: (close) => {
                close();
              },
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          setErrorMessage(err.response.data.message);
        });
    }
  };

  const editData = data?.departments;

  const getDepartmentsList = () => {
    let payload = {
      mode: 1,
    };

    ApiServices.department
      .getDepartments(payload)
      .then((res) => {
        const { data, message } = res;
        if (data?.code === 200) {
          const formattedData = data?.data?.map((department) => ({
            label: department.name,
            value: department.id,
          }));

          if (editMode) {
            const selectedItemsValues = editData?.map((item) => item.id);
            const filteredData = formattedData?.filter((item) => !selectedItemsValues?.includes(item.value));
            setItems(filteredData);
          } else {
            setItems(formattedData);
          }
        } else {
        }
      })
      .catch((err) => {});
  };

  useEffect(() => getDepartmentsList(), [isOpen, editMode]);

  useEffect(() => {
    if (editMode) {
      setCampusName(data?.name);
      setSelectedItems(
        data?.departments.map((item) => ({
          value: item.id,
          label: item.name,
        })),
      );
    } else {
      setCampusName("");
      setSelectedItems("");
      setNewItem("");
    }
    setErrorMessage("");
  }, [isOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-75"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-75"
              >
                <Dialog.Panel className="w-full max-w-xl  rounded-2xl bg-white p-8 align-middle shadow-xl transition-all">
                  <div className="flex items-center justify-between">
                    <Dialog.Title as="h3" className="font-poppins text-lg font-semibold leading-7 text-secondary-color">
                      {editMode ? "Update New Site" : "Add New Site"}
                    </Dialog.Title>
                    <XMarkIcon onClick={closeModal} className="h-8 w-8 cursor-pointer text-primary-color-200" />
                  </div>

                  <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                    <InputField
                      type="text"
                      name="Name"
                      label="Name"
                      value={campusName}
                      placeholder="Enter Site Name"
                      onChange={(e) => {
                        setCampusName(e.target.value);
                        setErrorMessage("");
                      }}
                    />

                    <div>
                      <div style={{ marginTop: "10px" }}>
                        <label className="pl-2 text-sm font-medium text-primary-color-100 ">Department</label>
                        <div
                          className={`mt-2  w-full   rounded-full border px-3 py-2 text-sm font-normal text-primary-color-200`}
                          onClick={() => setDropdownOpen(!dropdownOpen)}
                        >
                          <div className="-mb-0.5  flex flex-nowrap overflow-x-auto  rounded-full ">
                            {selectedItems.length > 0 ? (
                              selectedItems.map((item, index) => (
                                <div key={index} className="m-1 flex shrink-0 rounded-full bg-secondary/5 py-1 px-3 text-secondary">
                                  {item.label || item}
                                  <button onClick={(event) => handleRemove(event, item)} className="ml-3 flex items-center">
                                    <XMarkIcon className="h-4 w-4 cursor-pointer text-primary-color-200" />
                                  </button>
                                </div>
                              ))
                            ) : (
                              <p className="py-2 px-6 text-primary-color-100">Select Department Name</p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="relative" ref={dropdownRef}>
                        {dropdownOpen && (
                          <div className="absolute mt-3 w-full rounded-md bg-white p-5 shadow-lg">
                            {!showAddInput && (
                              <div onClick={toggleAddInput} className="mb-5 cursor-pointer text-sm font-normal text-secondary">
                                + Add another
                              </div>
                            )}
                            {showAddInput && (
                              <div className="mb-2 flex items-center space-x-3 ">
                                <div className="w-full">
                                  <InputField type="text" value={newItem} onChange={(e) => setNewItem(e.target.value)} placeholder="Enter Name" />
                                </div>
                                <Button title="Add" btnSmall onClick={handleAddItem} />
                              </div>
                            )}
                            <div className="max-h-24 overflow-y-scroll">
                              {items.map((item, index) => (
                                <div key={index} onClick={() => handleSelect(item)} className="cursor-pointer p-1 text-primary-color-100">
                                  {item.label || item}
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    {errorMessage && <p className="flex justify-center text-sm font-semibold text-red-500">{errorMessage}</p>}

                    <div className="flex items-center justify-end gap-x-6">
                      <h3 onClick={closeModal} className="cursor-pointer text-lg font-medium text-secondary">
                        Cancel
                      </h3>
                      <Button loading={loading} title={editMode ? "Update Site" : "Add Site"} />
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default AddCampus;
