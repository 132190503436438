import Button from "./Button";
import React, { useState } from "react";
import ApiServices from "../api/services";
import { CardNumberElement, CardExpiryElement, CardCvcElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useLoggedContext } from "../context/provider";

const CheckoutForm = ({ closeModal, getProfile , price }) => {
  // Stripe
  const stripe = useStripe();
  const elements = useElements();

  const { subPlanId, setOpenSubscriptionPlansModal, updateUser, openSuccessModal } = useLoggedContext();

  // Use States
  const [error, setError] = useState(null);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleStripePayment = async () => {
    const cardNumberElement = elements.getElement(CardNumberElement);
    const result = await stripe.createToken(cardNumberElement);
     

    if (result.error) {
      throw new Error(result.error.message);
    } else {
      const payload = {
        card_last_four_digit: result.token.card.last4,
        card_token: result.token.id,
        card_type: result.token.card.brand,
        card_expiry_date: `${result.token.card.exp_month + "-" + result.token.card.exp_year}`
      };
       

      await ApiServices.profile.addUserCard(payload);
      setToken(result.token.id); // Assuming you want to keep this for some reason.
      return result.token.id;
    }
  };

  const subscribeToPlan = async (token) => {
    const formData = new FormData();
    formData.append("plan_id", subPlanId);

    const res = await ApiServices.subscriptionPlans.subscribedPlan(formData);
    if (res.data.code === 200) {
      closeModal();
      updateUser(res?.data?.data);
      openSuccessModal({
        title: "Success!",
        message: "Congratulations! You are now part of the Edufeel family! Enjoy your subscription!",
        onPress: (close) => {
          close();
        },
      });
      setOpenSubscriptionPlansModal({ open: false });
    } else {
      throw new Error(res.data.message);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      setError("Stripe has not loaded yet. Please try again later.");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const token = await handleStripePayment();

      if (subPlanId) {
        await subscribeToPlan(token);
      } else {
        closeModal();
        getProfile();
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const currentDate = new Date();
  const endDate = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000);
  const formattedEndDate = endDate.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
  
  return (
    <form onSubmit={handleSubmit}>
      <div className="mt-5">
        <div className="mb-4">
          <label className="mb-2 block text-base font-medium">
            Card number
            <CardNumberElement className="mt-2 h-10 w-full rounded-full border border-gray-300 px-4 pt-3 indent-5 text-base shadow-sm" />
          </label>
          <div className="mt-3 flex w-full items-center justify-between">
            <div className="w-5/12">
              <label className="mb-2 block text-base font-medium">
                Expiry date
                <CardExpiryElement className="mt-2 h-10 w-full rounded-full border border-gray-300 px-4 pt-3 indent-5 text-base shadow-sm" />
              </label>
            </div>
            <div className="w-5/12">
              <label className="mb-2 block text-base font-medium">
                CVC
                <CardCvcElement className="mt-2 h-10 w-full rounded-full border border-gray-300 px-4 pt-3 indent-5 text-base shadow-sm" />
              </label>
            </div>
          </div>
        </div>
        <p className="text-sm font-medium">{`You will be charged when your 7-day free trial ends on  ${formattedEndDate}.`}</p>
      </div>

      <div className="mt-4">{error && <div className="text-sm text-red-500">{error}</div>}</div>
      <div className="mt-6 flex items-center justify-end gap-x-6">
        <h3 className="cursor-pointer text-base font-medium text-secondary 3xl:text-lg" onClick={closeModal}>
          Cancel
        </h3>
        <Button title="Save card" loading={loading} onClick={handleSubmit} />
      </div>
    </form>
  );
};

export default CheckoutForm;
