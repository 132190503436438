import React from "react";

const EllipsisText = ({ text, maxLength }) => {
  if (!text) {
    return null;
  }

  if (text.length <= maxLength) {
    return <p className="font-poppins text-sm font-normal text-accent-color"><span dangerouslySetInnerHTML={{ __html: text }} /></p>;
  }

  const truncatedText = text.slice(0, maxLength) + "...";

  return (
    <div className="flex cursor-pointer items-center">
  
      <p className="font-poppins text-sm font-normal text-accent-color ">
     <span dangerouslySetInnerHTML={{ __html: truncatedText }} /><span className="ml-1 mt-1 font-poppins text-xs font-medium text-secondary underline 3xl:text-sm">Read more</span>
      </p>
    </div>
  );
};

export default EllipsisText;
