import React from "react";
import { Fragment } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Dialog, Transition } from "@headlessui/react";
import "react-image-gallery/styles/css/image-gallery.css";
import moment from "moment";
import { images } from "../assets/styles/Images";

const NotificationModal = ({ isOpen, setIsOpen, data }) => {
  // Close Modal
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-50"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-50"
              >
                <Dialog.Panel className="w-full max-w-md p-10 space-y-4 overflow-hidden align-middle transition-all transform bg-white shadow-xl scal rounded-2xl">
                  <div className="flex items-center justify-between">
                    <Dialog.Title as="h3" className="text-lg font-semibold leading-7 font-poppins text-secondary-color">
                      Response
                    </Dialog.Title>
                    <XMarkIcon onClick={closeModal} className="w-8 h-8 cursor-pointer text-grayColor300" />
                  </div>
                  <div className="space-y-4">
                    <div className="flex items-center gap-x-18">
                      <div className="col-span-3">
                        <h2 className="text-base text-grayColor300">Date:</h2>
                      </div>
                      <div>
                        <h2 className="text-lg font-medium text-secondary">{moment.unix(data?.created_date_unix).format("D/MM/YYYY")}</h2>
                      </div>
                    </div>
                    <div className="flex items-center gap-x-7">
                      <div>
                        <h2 className="text-base text-grayColor300">Response: </h2>
                      </div>
                      <div className="flex items-center gap-x-2">
                        {data?.response == null || data?.response === undefined ? (
                          ""
                        ) : (
                          <img
                            src={
                              data?.response === "Great"
                                ? images.GreatIcon
                                : data?.response === "Okay"
                                  ? images.OkayIcon
                                  : data?.response === "Meh"
                                    ? images.MehIcon
                                    : data?.response === "Low"
                                      ? images.LowIcon
                                      : data?.response === "Struggling"
                                        ? images.StugglingIcon
                                        : ""
                            }
                            alt="logo"
                            className="w-6 h-6"
                          />
                        )}

                        <h2 className="text-lg font-medium text-secondary">
                          {data?.response == null || data?.response === undefined ? "No Response" : data?.response}
                          {/* {data?.response || "N/A"} */}
                        </h2>
                      </div>
                    </div>
                    <div className="flex items-center gap-x-12">
                      <div>
                        <h2 className="text-base text-grayColor300">Reason:</h2>
                      </div>
                      <div className="flex items-center gap-x-2">
                        {data?.reason == null ||
                        data?.reason === undefined ||
                        data?.response?.toLowerCase() === "great" ||
                        data?.response?.toLowerCase() === "okay" ? (
                          ""
                        ) : (
                          <img
                            src={
                              data?.reason === "Relationship Problems"
                                ? images.RelationIcon
                                : data?.reason === "Poor Sleep"
                                  ? images.SleepIcon
                                  : data?.reason === "Unwell"
                                    ? images.UnWellIcon
                                    : data?.reason === "Work Stress"
                                      ? images.StressIcon
                                      : data?.reason === "Bereavement"
                                        ? images.BereavementIcon
                                        : data?.reason === "Home Life Difficulties"
                                          ? images.HomeLifeIcon
                                          : data?.reason === "Mental Health Rest Day"
                                            ? images.MentleHealth
                                            : ""
                            }
                            alt="logo"
                            className="w-6 h-6"
                          />
                        )}

                        <h2 className="text-lg font-medium text-secondary">
                          {/* {data?.reason || "N/A"} */}
                          {data?.reason == null ||
                          data?.reason === undefined ||
                          data?.response?.toLowerCase() === "great" ||
                          data?.response?.toLowerCase() === "okay"
                            ? "No Reason"
                            : data?.reason}
                        </h2>
                      </div>
                    </div>
                    <div className="space-y-1">
                      <h2 className="text-base text-left text-grayColor300">Additional Detail:</h2>
                      <p className="text-base font-medium text-left text-secondary">{data?.reason_detail || "N/A"}</p>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default NotificationModal;
