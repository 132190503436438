import moment from "moment";
import Lottie from "react-lottie";
import Badge from "../components/Badge";
import { Tooltip } from "react-tooltip";
import ApiServices from "../api/services";
import ReactPaginate from "react-paginate";
import { mediaUrl } from "../utils/config";
import Heading from "../components/Heading";
import Skeleton from "react-loading-skeleton";
import { images } from "../assets/styles/Images";
import TitleValue from "../components/TitleValue";
import { useMediaQuery } from "react-responsive";
import React, { useEffect, useState } from "react";
import StatusFilter from "../components/StatusFilter";
import { useLoggedContext } from "../context/provider";
import animationData from "../assets/lotties/empty-folder";
import { useSortableData } from "../hooks/useSortableData";
import ConfirmationModal from "../components/ConfirmationModal";
import NotificationCircle from "../components/NotificationCircle";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import AddNewSettingModal from "../components/AddNewSettingModal";
import TransactionHistoryModal from "../components/TransactionHistoryModal";
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, ChevronUpIcon } from "@heroicons/react/24/solid";

// Table Head
const TABLE_HEAD = ["Setting Name", "Contact Person", "Employees", "Joined at", "Status"];
const TABLE_HEAD_PENDING = ["Setting Name", "Contact Person", "Employees", "Created at", "Action"];
const TABLE_HEAD_TRANSACTION = ["Amount", "Package"];

// Options
const options = [
  { value: "all", label: "All" },
  { value: "active", label: "Active Settings" },
  { value: "deactive", label: "Deactive Settings" },
];

const Settings = () => {
  // Context
  const { loading, setLoading, openSuccessModal, setBtnLoading } = useLoggedContext();

  // Use States

  // Active row / tabs
  const [activeTab, setActiveTab] = useState(0);
  const [activeRow, setActiveRow] = useState(null);
  const [historyLoading, setHistoryLoading] = useState(false);

  // Select
  const [statusOption, setStatusOption] = useState(options[0]);

  // Data
  const [searchText, setSearchText] = useState("");
  const [totalPendingRequests, setTotalPendingRequests] = useState(0);
  const [allSettingsListing, setAllSettingsListing] = useState([]);
  const [settingListingById, setSettingListingById] = useState([]);
  const [pendingSettingsListing, setPendingSettingsListing] = useState([]);

  // Pagination
  const [totalPageAllSettings, setTotalPageAllSettings] = useState(0);
  const [currentPageAllSettings, setCurrentPageAllSettings] = useState(null);
  const [totalPagePendingSettings, setTotalPagePendingSettings] = useState(0);
  const [currentPagePendingSettings, setCurrentPagePendingSettings] = useState(null);

  // Modal
  const [addNewModal, setAddNewModal] = useState(false);
  const [openBlockModal, setOpenBlockModal] = useState(false);
  const [openAcceptDeclineModal, setOpenAcceptDeclineModal] = useState({ open: false, label: "" });
  const [openTransactionHistoryModal, setOpenTransactionHistoryModal] = useState({ open: false, data: null });

  const [settingEditData, setSettingEditData] = useState("");

  // Active Row
  const handleRowClick = (id) => {
    setActiveRow(id);
  };

  // Active Tab
  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  // Add new setting
  // const handleFormSubmission = (formData) => {
  //   // Your logic to handle the form submission goes here

  // };

  let selectedListing = activeTab === 0 ? allSettingsListing : pendingSettingsListing;
  const detail = selectedListing?.find((item) => item?.id === (activeRow || selectedListing[0]?.id));
  const transactions = detail?.transactions;

  // <------- Table Sorting ----------->

  // Active Table Sorting
  const { items, requestSort, sortConfig } = useSortableData(allSettingsListing);

  // Pending Table Sorting
  const {
    items: pendingSettingList,
    requestSort: pendingSettingListSort,
    sortConfig: pendingSettingListSortConfig,
  } = useSortableData(pendingSettingsListing);

  // Transaction History Table Sorting
  const {
    items: transactionHistoryList,
    requestSort: transactionHistoryListSort,
    sortConfig: transactionHistoryListSortConfig,
  } = useSortableData(transactions);

  // Media Queries
  const isLaptop = useMediaQuery({ minWidth: 1024 });
  const isLaptopMedium = useMediaQuery({ minWidth: 1536 });
  const isLargeScreenLaptop = useMediaQuery({ minWidth: 1700 });
  const itemsPerPage = isLargeScreenLaptop ? 9 : isLaptopMedium ? 6 : isLaptop ? 6 : 10;

  // Get all settings list
  const getAllSettings = () => {
    setLoading(true);
    let payload = {
      search: searchText,
      page: currentPageAllSettings,
      records_no: itemsPerPage,
      is_blocked: statusOption?.value === "active" ? 0 : statusOption?.value === "deactive" ? 1 : "",
    };

    ApiServices.settings
      .getSettings(payload)
      .then((res) => {
        const { data, message } = res;

        if (data.code === 200) {
          setLoading(false);
          setAllSettingsListing(data?.data?.data);
          setCurrentPageAllSettings(data?.data?.current_page);
          setTotalPageAllSettings(Math.ceil(data?.data?.total / data?.data?.per_page));
        }
      })
      .catch((err) => {
         
      });
  };

  // Get all settings list by id
  // const getAllSettingsById = () => {
  //   setLoading(true);
  //   let id = detail?.id
  //   ApiServices.settings
  //     .getSettingsById(id)
  //     .then((res) => {
  //       const { data, message } = res;

  //       if (data.code === 200) {
  //         setLoading(false);
  //         // setSettingListingById(data?.data?.data);
  //       }
  //     })
  //     .catch((err) => {
  //        
  //     });
  // }

  // Pending setting list
  const getPendingSettings = () => {
    setLoading(true);
    let payload = {
      status: "Pending",
      search: searchText,
      page: currentPagePendingSettings,
      records_no: 6,
    };

    ApiServices.settings
      .getSettings(payload)
      .then((res) => {
        const { data, message } = res;

        if (data.code === 200) {
          setLoading(false);
          setPendingSettingsListing(data?.data?.data);
          setTotalPendingRequests(data?.data?.total);
          setCurrentPagePendingSettings(data?.data?.current_page);
          setTotalPagePendingSettings(Math.ceil(data?.data?.total / data?.data?.per_page));
        }
      })
      .catch((err) => {
         
      });
  };

  // Block Setting
  const blockSetting = () => {
    setBtnLoading(true);
    let id = detail?.id;

    ApiServices.settings
      .blockSetting(id)
      .then((res) => {
        const { data, message } = res;

        if (data.code === 200) {
          setBtnLoading(false);
          getAllSettings();
          setOpenBlockModal(false);
          openSuccessModal({
            title: "Success!",
            message: "Nursery has been Deactivated",
            onPress: (close) => {
              close();
              getAllSettings();
            },
          });
        }
      })
      .catch((err) => {
         
      });
  };

  // Accept Decline
  const acceptDeclineSetting = () => {
    setBtnLoading(true);
    let settingMessage = openAcceptDeclineModal.label === "Accept" ? "Nursery has been Activated" : "Nursery has been Deactivated";
    let settingStatus = openAcceptDeclineModal.label === "Accept" ? `Accept` : `Reject`;
    let settingId = detail?.id;

    let formData = new FormData();
    formData.append("status", settingStatus);
    formData.append("id", settingId);

    ApiServices.settings
      .acceptDeclineSetting(formData)
      .then((res) => {
        const { data, message } = res;

        if (data.code === 200) {
          setBtnLoading(false);
          setActiveTab(0);
          getAllSettings();
          getPendingSettings();
          setOpenAcceptDeclineModal({ open: false, label: "" });
          openSuccessModal({
            title: "Success!",
            message: settingMessage,
            onPress: (close) => {
              close();
              setActiveTab(0);
              getAllSettings();
              getPendingSettings();
            },
          });
        }
      })
      .catch((err) => {
         
      });
  };

  // useEffects
  useEffect(() => {
    if (searchText?.length > 1 || searchText?.length === 0) {
      activeTab === 0 ? getAllSettings() : getPendingSettings();
    }
  }, [searchText, activeTab]);

  useEffect(() => {
    if (activeTab === 0) {
      setActiveRow(items[0]?.id);
    } else {
      setActiveRow(pendingSettingList[0]?.id);
    }
  }, [activeTab, items, pendingSettingList]);

  useEffect(() => {
    getAllSettings();
  }, [currentPageAllSettings, statusOption]);

  useEffect(() => {
    getPendingSettings();
  }, [currentPagePendingSettings]);

  // useEffect(()=>{
  //   getAllSettingsById()
  // },[])

  // Lottie Option
  const emptyFolderAnimation = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // <-------- pagination ----------->

  // All Settings Listing
  const handleAllSettingsPageChange = ({ selected }) => {
    setCurrentPageAllSettings(selected + 1);
  };

  // Pending Settings Listing
  const handlePendingSettingsPageChange = ({ selected }) => {
    setCurrentPagePendingSettings(selected + 1);
  };

  return (
    <>
      <div className="grid grid-cols-12 gap-4 ">
        <div className="col-span-12 md:col-span-12 lg:col-span-8">
          <div className="card h-[82vh] 3xl:h-[84vh]">
            <div className="mb-6">
              <div className="sticky top-0 z-[2] flex w-full flex-col items-start justify-between gap-x-6 bg-white md:flex-row md:items-center">
                <div className="flex gap-x-3 3xl:gap-x-6">
                  <h3
                    className={`cursor-pointer text-base font-medium 3xl:text-lg ${
                      activeTab === 0 ? "border-b-2 border-secondary text-secondary" : "text-gray-500"
                    }`}
                    onClick={() => handleTabClick(0)}
                  >
                    All
                  </h3>
                  <div className="flex items-center gap-x-2">
                    <h3
                      className={`cursor-pointer text-base font-medium 3xl:text-lg ${
                        activeTab === 1 ? "border-b-2 border-secondary text-secondary" : "text-gray-500"
                      }`}
                      onClick={() => handleTabClick(1)}
                    >
                      Pending
                    </h3>
                    <NotificationCircle notificationCount={totalPendingRequests} />
                  </div>
                </div>

                {/* Search */}

                <div className="flex items-center justify-center gap-x-2 md:mt-0 2xl:gap-x-4">
                  <button className="w-32 rounded-full bg-secondary py-2.5 text-sm text-white" type="button" onClick={() => setAddNewModal(true)}>
                    Add New
                  </button>
                  {activeTab === 0 && <StatusFilter selected={statusOption} setSelected={setStatusOption} options={options} />}
                  <div className="relative flex items-center ">
                    <div className="pointer-events-none absolute inset-y-0 left-0 z-10 flex items-center pl-4">
                      <MagnifyingGlassIcon className="h-5 w-5 text-primary-color-100" />
                    </div>

                    <input
                      type="search"
                      id="search"
                      name="search"
                      placeholder="Search..."
                      autocomplete="off"
                      value={searchText}
                      onChange={(e) => {
                        setSearchText(e.target.value);
                        activeTab === 0 ? setCurrentPageAllSettings(1) : setCurrentPagePendingSettings(1);
                      }}
                      className="focus:ring-primary-color block w-full rounded-full border border-grayColor200 px-4 py-2 pl-11 text-sm text-primary-color-100 focus:border-primary-color-100"
                    />
                  </div>
                  {/* select */}
                </div>
              </div>
              <Heading subheading="Details of active settings & pending for approval.  " />
            </div>

            {activeTab === 0 ? (
              <>
                <div className="-mx-6 mb-8 overflow-x-auto">
                  <table className="w-full text-left">
                    <thead>
                      <tr>
                        {TABLE_HEAD.map((head) => (
                          <th
                            key={head}
                            className="border-b border-gray-100 bg-white p-4 first:pl-6"
                            onClick={() => {
                              let sortKey;
                              if (head === "Setting Name") {
                                sortKey = "name";
                              } else if (head === "Contact Person") {
                                sortKey = "contact_person_name";
                              } else if (head === "Joined at") {
                                sortKey = "created_at";
                              } else {
                                sortKey = head.toLowerCase();
                              }
                              requestSort(sortKey);
                            }}
                          >
                            <p className="cursor-pointer whitespace-nowrap font-inter text-xs font-semibold leading-5 3xl:text-sm">
                              {head}
                              {sortConfig.key ===
                                (head === "Setting Name"
                                  ? "name"
                                  : head === "Contact Person"
                                    ? "contact_person_name"
                                    : head === "Joined at"
                                      ? "created_at"
                                      : head.toLowerCase()) && sortConfig.direction === "asc" ? (
                                <ChevronUpIcon className="inline-block h-4 w-3.5" />
                              ) : (
                                <ChevronDownIcon className="inline-block h-4 w-3.5" />
                              )}
                            </p>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan="6">
                            <Skeleton count={itemsPerPage} height={50} />
                          </td>
                        </tr>
                      ) : items.length > 0 ? (
                        items.map((item, index) => (
                          <tr
                            key={item?.id}
                            className={`cursor-pointer ${item?.id === activeRow ? "border-l-4 border-secondary" : "even:bg-gray-50"}`}
                            onClick={() => handleRowClick(item?.id)}
                          >
                            <td className="py-3 pl-4 3xl:px-4 3xl:first:pl-6">
                              <div className="flex items-center">
                                <div className="mr-3 h-8 w-8 rounded-full">
                                  {/* <CustomImage placeholderImg={Images.PLACEHOLDER} imgSource={userUrl + item?.profile_url} alt="profile img" /> */}
                                  <img
                                    className="mr-3 h-8 w-8 rounded-full"
                                    src={item?.logo ? mediaUrl + item?.logo : images.placeholder}
                                    alt="Profile"
                                  />
                                </div>
                                <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item?.name}</p>
                              </div>
                            </td>
                            <td className="py-3 pl-4 3xl:px-4">
                              <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item?.contact_person_name}</p>
                            </td>
                            <td className="py-3 pl-4 pr-3 3xl:px-4">
                              <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item?.employees}</p>
                            </td>

                            <td className="py-3 pl-4 pr-3 3xl:px-4">
                              <p id={`date${index + 1}`} className="text-xs font-normal text-primary-color-200 3xl:text-sm">
                                {moment.unix(item?.created_at).format("D MMM YYYY")}
                              </p>
                              <Tooltip
                                anchorSelect={`#date${index + 1}`}
                                id={`date-tooltip${index + 1}`}
                                offset={5}
                                className="border border-gray-100 !bg-white !text-primary-color-100 !opacity-100 shadow-card"
                                classNameArrow="hidden"
                                effect="solid"
                                place="bottom"
                              >
                                {/* Tooltip Content */}
                                <span>{moment.unix(item?.created_at).format("D MMM YYYY h:mm A")}</span>
                              </Tooltip>
                            </td>

                            <td className="py-3 pl-4 pr-3 3xl:px-4">
                              <Badge status={item?.is_blocked === 1 ? "Deactive" : "Active"} />
                            </td>
                          </tr>
                        ))
                      ) : (
                        // Render "No Data" message for active driver
                        <tr className="h-[500px]">
                          <td colSpan="6">
                            <Lottie options={emptyFolderAnimation} width={200} height={200} />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="absolute bottom-4">
                  <ReactPaginate
                    breakLabel="..."
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={2}
                    activeClassName="active"
                    nextClassName="item next"
                    renderOnZeroPageCount={null}
                    breakClassName="item break-me "
                    containerClassName="pagination"
                    previousClassName="item previous"
                    pageCount={totalPageAllSettings}
                    pageClassName="item pagination-page"
                    forcePage={currentPageAllSettings - 1}
                    onPageChange={handleAllSettingsPageChange}
                    nextLabel={<ChevronRightIcon className="h-5 w-5" />}
                    previousLabel={<ChevronLeftIcon className="h-5 w-5" />}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="-mx-6 mb-8 overflow-x-auto">
                  <table className="w-full text-left">
                    <thead>
                      <tr>
                        {TABLE_HEAD_PENDING.map((head) => (
                          <th
                            key={head}
                            className="border-b border-gray-100 bg-white p-4 first:pl-6"
                            onClick={() => {
                              let sortKey;
                              if (head === "Setting Name") {
                                sortKey = "name";
                              } else if (head === "Contact Person") {
                                sortKey = "contact_person_name";
                              } else if (head === "Created at") {
                                sortKey = "created_at";
                              } else {
                                sortKey = head.toLowerCase();
                              }
                              pendingSettingListSort(sortKey);
                            }}
                          >
                            <p className="cursor-pointer whitespace-nowrap font-inter text-xs font-semibold leading-5 3xl:text-sm">
                              {head}
                              {pendingSettingListSortConfig.key ===
                                (head === "Setting Name"
                                  ? "name"
                                  : head === "Contact Person"
                                    ? "contact_person_name"
                                    : head === "Created at"
                                      ? "created_at"
                                      : head.toLowerCase()) && pendingSettingListSortConfig.direction === "asc" ? (
                                <ChevronUpIcon className="inline-block h-4 w-3.5" />
                              ) : (
                                <ChevronDownIcon className="inline-block h-4 w-3.5" />
                              )}
                            </p>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan="6">
                            <Skeleton count={5} height={50} />
                          </td>
                        </tr>
                      ) : pendingSettingList.length > 0 ? (
                        pendingSettingList.map((item, index) => (
                          <tr
                            key={item?.id}
                            className={`cursor-pointer ${item?.id === activeRow ? "border-l-4 border-secondary" : "even:bg-gray-50"}`}
                            onClick={() => handleRowClick(item?.id)}
                          >
                            <td className="py-3 pl-4 3xl:px-4 3xl:first:pl-6">
                              <div className="flex items-center">
                                <div className="mr-3 h-8 w-8 rounded-full">
                                  {/* <CustomImage placeholderImg={Images.PLACEHOLDER} imgSource={userUrl + item?.profile_url} alt="profile img" /> */}
                                  <img
                                    className="mr-3 h-8 w-8 rounded-full"
                                    src={item?.logo ? mediaUrl + item?.logo : images.placeholder}
                                    alt="Profile"
                                  />
                                </div>
                                <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item?.name}</p>
                              </div>
                            </td>
                            <td className="py-3 pl-4 3xl:px-4">
                              <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item?.contact_person_name}</p>
                            </td>
                            <td className="py-3 pl-4 pr-3 3xl:px-4">
                              <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item?.employees}</p>
                            </td>

                            <td className="py-3 pl-4 pr-3 3xl:px-4">
                              <p id={`date${index + 1}`} className="text-xs font-normal text-primary-color-200 3xl:text-sm">
                                {moment.unix(item?.created_at).format("D MMM YYYY")}
                              </p>
                              <Tooltip
                                anchorSelect={`#date${index + 1}`}
                                id={`date-tooltip${index + 1}`}
                                offset={5}
                                className="border border-gray-100 !bg-white !text-primary-color-100 !opacity-100 shadow-card"
                                classNameArrow="hidden"
                                effect="solid"
                                place="bottom"
                              >
                                {/* Tooltip Content */}
                                <span>{moment.unix(item?.created_at).format("D MMM YYYY h:mm A")}</span>
                              </Tooltip>
                            </td>

                            <td className="py-3 pl-4 pr-3 3xl:px-4">
                              <div className="flex items-center gap-x-3">
                                <span
                                  onClick={() => setOpenAcceptDeclineModal({ open: true, label: "Accept" })}
                                  className="text-xs font-normal text-accent underline 3xl:text-sm"
                                >
                                  Accept
                                </span>
                                <span
                                  onClick={() => setOpenAcceptDeclineModal({ open: true, label: "Decline" })}
                                  className="text-xs font-normal text-red-500 underline 3xl:text-sm"
                                >
                                  Decline
                                </span>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        // Render "No Data" message for active driver
                        <tr className=" h-[500px]">
                          <td colSpan="6">
                            <Lottie options={emptyFolderAnimation} width={200} height={200} />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="absolute bottom-4">
                  <ReactPaginate
                    breakLabel="..."
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={2}
                    activeClassName="active"
                    nextClassName="item next"
                    renderOnZeroPageCount={null}
                    breakClassName="item break-me "
                    containerClassName="pagination"
                    previousClassName="item previous"
                    pageClassName="item pagination-page "
                    pageCount={totalPagePendingSettings}
                    forcePage={currentPagePendingSettings - 1}
                    onPageChange={handlePendingSettingsPageChange}
                    nextLabel={<ChevronRightIcon className="h-5 w-5" />}
                    previousLabel={<ChevronLeftIcon className="h-5 w-5" />}
                  />
                </div>
              </>
            )}
          </div>
        </div>

        <div className="md:col-sapn-12 col-span-12 lg:col-span-4">
          <div className="card h-[82vh] space-y-8  3xl:h-[84vh]">
            {(activeTab === 0 && allSettingsListing?.length > 0) || (activeTab === 1 && pendingSettingList?.length > 0) ? (
              <>
                <div className="flex items-baseline justify-between ">
                  <Heading heading="Detail" />
                  {activeTab === 0 && (
                    <div className="flex items-center gap-x-3">
                      <button
                        className="border-b border-secondary text-sm font-medium text-secondary"
                        type="button"
                        onClick={() => {
                          setAddNewModal(true);
                          setSettingEditData(detail);
                        }}
                      >
                        Edit
                      </button>
                      <button
                        className={`border-b text-sm  font-medium ${detail?.is_blocked === 0 ? "border-red-500 text-red-500" : "border-accent text-accent"}`}
                        type="button"
                        onClick={() => setOpenBlockModal(true)}
                      >
                        {detail?.is_blocked === 0 ? "Deactive" : "Active"}
                      </button>
                    </div>
                  )}
                </div>

                <div className="-mx-6 h-[70vh] space-y-5 overflow-y-auto overflow-x-visible">
                  <div className="space-y-5 px-6">
                    <div className="flex flex-col items-center gap-y-2">
                      <div className="mx-auto h-40 w-40 rounded-full">
                        <img src={mediaUrl + detail?.logo || images.placeholder} className="h-full w-full rounded-full object-cover" alt="icon" />
                      </div>
                      <h2 className="text-lg font-semibold text-black">{detail?.name}</h2>
                      <Badge status={detail?.is_blocked === 1 ? "Deactive" : "Active"} />
                    </div>

                    <div className="grid grid-cols-12 gap-y-4 rounded-lg bg-detailProfileColor py-8 pl-3">
                      <div className="col-span-6">
                        <TitleValue title="Contact Person" value={detail?.contact_person_name} />
                      </div>
                      <div className="col-span-6">
                        <TitleValue title="No. of Employees" value={detail?.employees} />
                      </div>
                      <div className="col-span-6">
                        <TitleValue title="Email" value={detail?.email} />
                      </div>
                      <div className="col-span-6">
                        <TitleValue title="Phone" value={detail?.phone_number} />
                      </div>

                      <div id="date" className="col-span-6">
                        <TitleValue title="Joined at " value={moment.unix(detail?.created_at).format("D MMM YYYY")} />
                      </div>
                      <Tooltip
                        anchorSelect="#date"
                        id="date-tooltip"
                        offset={5}
                        className="border border-gray-100 !bg-white !text-primary-color-100 !opacity-100 shadow-card"
                        classNameArrow="hidden"
                        effect="solid"
                        place="bottom"
                      >
                        {/* Tooltip Content */}
                        <span>{moment.unix(detail?.created_at).format("D MMM YYYY h:mm A")}</span>
                      </Tooltip>
                      <div className="col-span-6">
                        <TitleValue title="Last update by " value={detail?.updated_by || "N/A"} lastUpdated />{" "}
                      </div>

                      <div className="col-span-12">
                        <TitleValue title="Address" value={detail?.address} />
                      </div>
                    </div>
                  </div>

                  {activeTab === 1 ? (
                    <div className="space-y-3 px-6 py-10">
                      <button
                        className="w-full rounded-full bg-accent py-3 text-sm font-medium text-white hover:opacity-75"
                        type="button"
                        onClick={() => setOpenAcceptDeclineModal({ open: true, label: "Accept" })}
                      >
                        Accept
                      </button>
                      <button
                        className="w-full rounded-full border border-red-500 py-3 text-sm font-medium text-red-500 transition-colors duration-300 hover:bg-red-500 hover:text-white "
                        type="button"
                        onClick={() => setOpenAcceptDeclineModal({ open: true, label: "Decline" })}
                      >
                        Decline
                      </button>
                    </div>
                  ) : (
                    <div className="space-y-4">
                      <div className="flex items-baseline justify-between px-6">
                        <Heading heading="Transaction History" />
                        <button
                          className="border-b border-secondary text-sm font-medium text-secondary"
                          type="button"
                          onClick={() => {
                            setOpenTransactionHistoryModal({ open: true, data: detail });
                          }}
                        >
                          View All
                        </button>
                      </div>
                      <div className="mb-8 overflow-x-auto">
                        <table className="w-full text-left">
                          <thead>
                            <tr>
                              {TABLE_HEAD_TRANSACTION.map((head) => (
                                <th
                                  key={head}
                                  className="border-b border-gray-100 bg-white p-4 first:pl-6"
                                  onClick={() => {
                                    let sortKey;
                                    if (head === "Amount") {
                                      sortKey = "price";
                                    } else if (head === "Package") {
                                      sortKey = "plan_name";
                                    } else {
                                      sortKey = head.toLowerCase();
                                    }
                                    transactionHistoryListSort(sortKey);
                                  }}
                                >
                                  <p className="cursor-pointer whitespace-nowrap font-inter text-xs font-semibold leading-5 3xl:text-sm">
                                    {head}
                                    {transactionHistoryListSortConfig.key ===
                                      (head === "Amount" ? "price" : head === "Package" ? "plan_name" : head.toLowerCase()) &&
                                    transactionHistoryListSortConfig.direction === "asc" ? (
                                      <ChevronUpIcon className="inline-block h-4 w-3.5" />
                                    ) : (
                                      <ChevronDownIcon className="inline-block h-4 w-3.5" />
                                    )}
                                  </p>
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {historyLoading ? (
                              <tr>
                                <td colSpan="2">
                                  <Skeleton count={4} height={50} />
                                </td>
                              </tr>
                            ) : transactionHistoryList?.length > 0 ? (
                              transactionHistoryList.map((item, index) => (
                                <tr key={item?.id} className="even:bg-gray-50">
                                  <td className="py-3 pl-6">
                                    <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{`$${item?.price} USD`}</p>
                                  </td>
                                  <td className="py-3 pl-4 pr-3 3xl:px-4">
                                    <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{`${item?.plan_name}/${item?.plan_type}`}</p>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              // Render "No Data" message for active driver
                              <tr className="h-52">
                                <td colSpan="6">
                                  <Lottie options={emptyFolderAnimation} width={200} height={200} />
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <Heading heading="No Detail" />
                <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                  <Lottie options={emptyFolderAnimation} width={200} height={200} />
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Block Modal */}
      <ConfirmationModal isOpen={openBlockModal} setIsOpen={setOpenBlockModal} label="Deactivate" handleSubmit={blockSetting} />

      {/* Accept Decline Modal */}
      <ConfirmationModal
        isOpen={openAcceptDeclineModal.open}
        label={openAcceptDeclineModal.label}
        handleSubmit={acceptDeclineSetting}
        setIsOpen={(open) => setOpenAcceptDeclineModal((prev) => ({ ...prev, open }))}
      />

      {/* Transaction History Modal */}
      <TransactionHistoryModal
        isOpen={openTransactionHistoryModal.open}
        data={openTransactionHistoryModal.data}
        setIsOpen={(open) => setOpenTransactionHistoryModal((prev) => ({ ...prev, open }))}
      />

      {/* Add New Setting Modal */}
      <AddNewSettingModal
        isOpen={addNewModal}
        setActiveTab={setActiveTab}
        getListing={getAllSettings}
        onSuccess={getPendingSettings}
        settingEditData={settingEditData}
        setSettingEditData={setSettingEditData}
        setIsOpen={() => setAddNewModal(false)}
      />
    </>
  );
};

export default Settings;
