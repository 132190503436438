import React from "react";
import moment from "moment";
import Button from "./Button";
import Heading from "./Heading";
import { Fragment } from "react";
import { useState } from "react";
import ApiServices from "../api/services";
import { useLocation, useParams } from "react-router-dom";
import { images } from "../assets/styles/Images";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useLoggedContext } from "../context/provider";
import "react-image-gallery/styles/css/image-gallery.css";
import { Dialog, RadioGroup, Transition } from "@headlessui/react";

const reasonData = [
  {
    id: 1,
    logo: images.SleepIcon,
    title: "Poor Sleep",
  },
  {
    id: 2,
    logo: images.StressIcon,
    title: "Work Stress",
  },
  {
    id: 3,
    logo: images.BereavementIcon,
    title: "Bereavement",
  },
  {
    id: 4,
    logo: images.RelationIcon,
    title: "Relationship Problems",
  },
  {
    id: 5,
    logo: images.HomeLifeIcon,
    title: "Home Life Difficulties",
  },
  {
    id: 6,
    logo: images.UnWellIcon,
    title: "Unwell",
  },
];

const feelingData = [
  {
    id: 1,
    logo: images.MehIcon,
    title: "Meh",
  },
  {
    id: 2,
    logo: images.LowIcon,
    title: "Low",
  },
  {
    id: 3,
    logo: images.StugglingIcon,
    title: "Struggling",
  },
];

const MentalHealthAbsenceModal = ({ isOpen, setIsOpen, data, refreshData }) => {
  // react router hook
  const location = useLocation();
  const { id: paramId } = useParams();
  const { employeeDetail } = location.state || {};
  const employeeId = employeeDetail ? employeeDetail?.id : null;

  // Context
  const { btnLoading, setBtnLoading, openSuccessModal } = useLoggedContext();

  // useState
  const [selectedReason, setSelectedReason] = useState(null);
  const [reasonDetail, setReasonDetail] = useState("");
  const [message, setMessage] = useState("");

  const [selectedFeeling, setSelectedFeeling] = useState(null);

  // handle reason detail
  const handleReasonChange = (event) => {
    setReasonDetail(event.target.value);
  };

  // Close Modal
  function closeModal() {
    setIsOpen(false);
    setSelectedReason(null);
    setReasonDetail("");
    setMessage("");
    setSelectedFeeling(null);
  }

  // handle submit response
  const submitResponse = () => {
    if (!selectedReason || !selectedFeeling) {
      setMessage("Both feeling and reason are required");
      return;
    } else {
      setBtnLoading(true);
      const formData = new FormData();
      formData.append("id", employeeId ? employeeId : paramId);
      formData.append("reason", selectedReason);
      formData.append("reason_detail", reasonDetail);
      formData.append("response", selectedFeeling);
      formData.append("is_admin", 1);
      formData.append("mark_absence", 1);

      ApiServices.employees
        .markMentalHealthAbsenceByID(formData)
        .then((res) => {
           
          if (res?.status === 200) {
            setBtnLoading(false);
            setMessage("");
            refreshData();
            openSuccessModal({
              title: "Success!",
              message: "Response has been Sent!",
              onPress: (close) => {
                close();
              },
            });
            setSelectedReason(null);
            setReasonDetail("");
            setIsOpen(false);
          }
        })
        .catch(({ response }) => {
           
        });
    }
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-50"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-50"
              >
                <Dialog.Panel className="scal w-full max-w-3xl transform space-y-4 overflow-hidden rounded-2xl bg-white p-10 align-middle shadow-xl transition-all">
                  <div className="flex items-center justify-between">
                    <Dialog.Title as="h3" className="font-poppins text-lg font-semibold leading-7 text-secondary-color">
                      {data}
                    </Dialog.Title>
                    <XMarkIcon onClick={closeModal} className="h-8 w-8 cursor-pointer text-grayColor300" />
                  </div>
                  <div className="">
                    <div>
                      <Heading heading="What’s Feeling?" />
                      <RadioGroup value={selectedFeeling} onChange={setSelectedFeeling}>
                        <div className="grid grid-cols-3 gap-6">
                          {feelingData.map((reason) => (
                            <RadioGroup.Option key={reason.title} value={reason.title}>
                              {({ checked }) => (
                                <div
                                  className={`flex cursor-pointer  items-center justify-center rounded-md bg-gray-100 pt-4 pb-5 ${checked && "border border-secondary"}`}
                                >
                                  <div className="space-y-4">
                                    <img src={reason.logo} alt="logo" className="mx-auto h-8 w-8" />
                                    <p className="text-sm text-secondary-color">{reason.title}</p>
                                  </div>
                                </div>
                              )}
                            </RadioGroup.Option>
                          ))}
                        </div>
                      </RadioGroup>
                    </div>
                  </div>
                  <div className="space-y-4">
                    <div>
                      <Heading heading="What’s Reason?" />
                    </div>
                    <div>
                      <RadioGroup value={selectedReason} onChange={setSelectedReason}>
                        <div className="grid grid-cols-3 gap-6">
                          {reasonData.map((reason) => (
                            <RadioGroup.Option key={reason.title} value={reason.title}>
                              {({ checked }) => (
                                <div
                                  className={`flex cursor-pointer  items-center justify-center rounded-md bg-gray-100 pt-4 pb-5 ${checked && "border border-secondary"}`}
                                >
                                  <div className="space-y-4">
                                    <img src={reason.logo} alt="logo" className="mx-auto h-8 w-8" />
                                    <p className="text-sm text-secondary-color">{reason.title}</p>
                                  </div>
                                </div>
                              )}
                            </RadioGroup.Option>
                          ))}
                        </div>
                      </RadioGroup>
                    </div>
                    <div>
                      <textarea
                        name="reason"
                        id="reason"
                        rows="4"
                        className="border-1 w-full rounded-lg border-grayColor200 p-4 text-sm placeholder:text-primary-color-100"
                        placeholder="Write here ...."
                        value={reasonDetail}
                        onChange={handleReasonChange}
                      />
                    </div>
                  </div>

                  {/* Message */}
                  <div className="flex justify-center">
                    <p className="text-sm text-red-500">{message}</p>
                  </div>
                  <div className="flex items-center gap-x-4">
                    <Button
                      loading={btnLoading}
                      title="Mark as Mental Health Rest Day"
                      buttonColor="bg-red-500 text-white "
                      className="w-full"
                      onClick={submitResponse}
                    />
                    <Button
                      onClick={closeModal}
                      title="Cancel"
                      buttonColor="border border-red-500 hover:bg-red-500 hover:text-white text-red-500"
                      className="w-full"
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default MentalHealthAbsenceModal;
